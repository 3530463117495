import React, { FC, useState } from 'react';
import { createCn } from 'bem-react-classname';

import { Tab, Tabs } from '@alfalab/core-components/tabs';
import { Typography } from '@alfalab/core-components/typography';

import InvestmentsCardAccount from '#/src/shared/components/investments/investmens-authorization/investments-card-account';
import InvestmentsLoginPassword from '#/src/shared/components/investments/investmens-authorization/investments-login-password';
import { useAppDispatch } from '#/src/shared/hooks';
import { AcrValues } from '#/src/shared/models';
import { acrValuesSet } from '#/src/shared/store/redux/app/slice';

const cn = createCn('investments');

const InvestmentsAuthorization: FC = () => {
    const dispatch = useAppDispatch();

    const [tab, setTab] = useState('login');

    const handleOnSelectTab = (selectedId: any) => {
        setTab(selectedId);
        if (selectedId === 'card') {
            dispatch(acrValuesSet(`${AcrValues.card}:${AcrValues.sms}`));
        } else {
            dispatch(acrValuesSet(AcrValues.adUsernamePassword));
        }
    };

    return (
        <div className={cn('container')}>
            <Typography.Title
                color='primary'
                tag='h2'
                font='system'
                view='small'
                weight='medium'
                defaultMargins={true}
            >
                Как хотите войти?
            </Typography.Title>
            <Tabs
                className={cn('tabs')}
                defaultMatchMediaValue={true}
                keepMounted={false}
                mobile={true}
                selectedId={tab}
                onChange={(_, { selectedId }) => {
                    handleOnSelectTab(selectedId);
                }}
                view='primary'
            >
                <Tab className={cn('tab')} title='По логину' id='login'>
                    <InvestmentsLoginPassword />
                </Tab>
                <Tab className={cn('tab')} title='По счёту или карте' id='card'>
                    <InvestmentsCardAccount />
                </Tab>
            </Tabs>
        </div>
    );
};

export default InvestmentsAuthorization;
