import React, { useCallback, useRef, useState } from 'react';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { ModalMobile } from '@alfalab/core-components/modal/mobile';
import { Typography } from '@alfalab/core-components/typography';

import { fetchers } from '#/src/shared/lib/client-api';

import { useCamera } from './hooks/use-camera';
import { useScanner } from './hooks/use-scanner';
import { useWorker } from './hooks/use-worker';
import { activateDevSession } from './utils/activate-dev-session';
import { CameraBody } from './camera-body';
import { ScannerError } from './scanner-error';

import './card-scanner.css';

export type Props = {
    env: string;
    close: () => void;
    manualEnter: () => void;
    setCardNumber: (number: string) => void;
};

const cn = createCn('card-scanner');

const CardScanner: React.FC<Props> = ({
    env,
    close,
    manualEnter,
    setCardNumber,
}) => {
    const canvasRef = useRef(null);
    const [initError, initErrorSet] = useState(false);

    const {
        loading: cameraLoading,
        permissionDenied,
        handleCameraInit,
        getFrame,
    } = useCamera({
        canvasRef,
    });

    const activateSession = useCallback(async (activationCode: string) => {
        try {
            // activateSession можно использовать вне зависимости от того что нужно сканировать
            if (env === 'development') {
                const message = await activateDevSession(activationCode);

                return await Promise.resolve(message);
            }

            const response = await fetchers.fetchActivateScanner(activationCode);

            if  (initError) {
                initErrorSet(false);
            }

            return response?.code;
        } catch (err) {
            initErrorSet(true);

            return await Promise.reject(err);
        }
    }, [env, initErrorSet, initError]);

    const {
        result,
        loading: workerLoading,
        recognizerFrame,
    } = useWorker({
        getFrame,
        activateSession,
    });

    const { showManualEnterButton, darkBackgroundMode, ready, hint } = useScanner({
        workerLoading,
        cameraLoading,
        result,
        permissionDenied,
        close,
        getFrame,
        recognizerFrame,
        handleCameraInit,
        setCardNumber,
    });
    const darkStyles = darkBackgroundMode && !initError;
    const showFooter = showManualEnterButton || initError;

    return (
        <ModalMobile
            open={true}
            className={darkStyles ? cn('darkBackground') : cn()}
            onClose={close}
            dataTestId='scanner-widget'
        >
            <ModalMobile.Header
                className={darkStyles ? `${cn('header')} ${cn('darkBackground')}` : cn('header')}
                contentClassName={darkStyles ? cn('headerTitle') : cn()}
            >
                Сканирование карты
            </ModalMobile.Header>

            <ModalMobile.Content className={initError ? cn('content denied') : cn('content')}>
                { initError
                ? <ScannerError initError={initError} />
                : <div className={permissionDenied ? cn('modalContent denied') : cn('modalContent')}>
                    {ready && !permissionDenied && <Typography.Text className={cn('hint')}>{hint}</Typography.Text>}
                    <CameraBody
                        ref={canvasRef}
                        ready={ready}
                        result={!!result}
                        permissionDenied={permissionDenied}
                    />
                </div>
}
            </ModalMobile.Content>

            {showFooter && (
                <ModalMobile.Footer className={darkStyles ? `${cn('footer')} ${cn('darkBackground')}`: cn('footer')}>
                    <Button
                        view='primary'
                        block={true}
                        onClick={manualEnter}
                        className={cn(`cancelButton
                            ${darkStyles ? 'cancelButtonDark' : 'cancelButtonLight'}`
            )}
                    >
                        Ввести номер вручную
                    </Button>
                </ModalMobile.Footer>
            )}
        </ModalMobile>
    );
};

export default CardScanner;
