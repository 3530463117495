import { createSelector } from 'reselect';

import { ApplicationState } from '#/src/shared/store/types';

export const selectSmsState = (state: ApplicationState) => state.SmsVerification;

export const selectSmsFormStatus = createSelector([selectSmsState], (sms) => sms.formStatus);

export const selectSmsCode = createSelector([selectSmsState], (sms) => sms.code);

export const selectSmsIsPush = createSelector([selectSmsState], (sms) => sms?.isPush);

export const selectSmsServerError = createSelector(
    [selectSmsState],
    (sms) => sms.serverErrors[0]?.isOpen,
);

export const selectSmsServerErrorMsg = createSelector(
    [selectSmsState],
    (sms) => sms.serverErrors[0]?.message,
);

export const selectSmsRequestStatus = createSelector([selectSmsState], (sms) => sms.requestStatus);

export const selectSmsConfirmationScreen = createSelector([selectSmsState], (sms) => sms.confirmationScreen);
