import React, { useState } from 'react';
import { createCn } from 'bem-react-classname';

import { Typography } from '@alfalab/core-components/typography';

import { useAppSelector, useIsomorphicLayoutEffect } from '#/src/shared/hooks';
import { getUserLocalInfo } from '#/src/shared/lib/user-info';
import { selectIsMobile } from '#/src/shared/store/redux/app/selectors';
import {
    selectCustomDesignSubtitle,
    selectCustomDesignTitle,
} from '#/src/shared/store/redux/custom-design/selectors';

import './styles.css';

const cn = createCn('greeting-title');

export const GreetingTitle: React.FC = () => {
    const [xUserInfo, setXUserInfo] = useState<ReturnType<typeof getUserLocalInfo> | null>(null);

    const customDesignTitle = useAppSelector(selectCustomDesignTitle);
    const customDesignSubtitle = useAppSelector(selectCustomDesignSubtitle);
    const isMobile = useAppSelector(selectIsMobile);

    const Title = isMobile ? Typography.TitleMobile : Typography.Title;

    useIsomorphicLayoutEffect(() => {
        setXUserInfo(getUserLocalInfo());
    }, []);

    if (customDesignTitle) {
        return (
            <div className={cn()}>
                <Title
                    rowLimit={2}
                    dataTestId='merchant-title'
                    className={cn({ custom: true })}
                    view='medium'
                    font='styrene'
                    tag='h1'
                >
                    {customDesignTitle}
                </Title>
                <Typography.Text
                    dataTestId='merchant-subtitle'
                    rowLimit={2}
                    tag='div'
                    view='primary-medium'
                >
                    {customDesignSubtitle}
                </Typography.Text>
            </div>
        );
    }

    return (
        <Title dataTestId='greeting-title' className={cn()} view='medium' font='styrene' tag='h1'>
            {xUserInfo?.name ? `Привет, ${xUserInfo.name}! ` : 'Привет! '}
            Войдите в&nbsp;Альфа&#8209;Онлайн
        </Title>
    );
};
