import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
    FetchArgs,
    FetchBaseQueryArgs,
    FetchBaseQueryError,
    FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import uuid from 'uuid';

import { sendMetrics } from '#/src/shared/lib/analitycs';
import getCookie from '#/src/shared/lib/get-cookie';
import { getMetricsData } from '#/src/shared/lib/get-metrics-data';
import getQueryParams from '#/src/shared/lib/get-query-params';
import getRouteFromRedirect from '#/src/shared/lib/get-route-from-redirect';
import initializeTraceId from '#/src/shared/lib/initialize-trace-id';
import { redirectTo } from '#/src/shared/lib/redirect';
import { setUserLocalInfo } from '#/src/shared/lib/user-info';
import { ResponseWithData } from '#/src/shared/store/base-query/types';
import {
    getQueryRedirectParams,
    selectIsMobile,
    selectMetricsDebugEnabled,
} from '#/src/shared/store/redux/app/selectors';
import { ApplicationState } from '#/src/shared/store/types';
import { HEADERS } from '#/src/shared/utils';

import { setMultifactorResponse } from '../redux/app/slice';

const { [HEADERS.TRACE_ID]: traceId } = initializeTraceId();

export const customFetchBaseQuery =
    (
        params?: FetchBaseQueryArgs & { customRedirect: boolean },
    ): BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        Record<string, unknown>,
        FetchBaseQueryMeta
    > =>
    async (...baseQueryFnParams) => {
        const [, api] = baseQueryFnParams;
        const state = api.getState() as ApplicationState;
        const { contextRoot } = state.Settings;
        const isMobile = selectIsMobile(state);
        const queryRedirectParams = getQueryRedirectParams(state);
        const metricsData = getMetricsData(queryRedirectParams, isMobile);
        const isMetricsDebugEnabled = selectMetricsDebugEnabled(state);

        const baseQueryFn = fetchBaseQuery({
            ...params,
            baseUrl: contextRoot,
            prepareHeaders: (headers) => {
                headers.set(HEADERS.X_CSRF_TOKEN, getCookie('alfa-csrf'));
                headers.set(HEADERS.X_REQUEST_ID, uuid.v4());
                headers.set(HEADERS.TRACE_ID, traceId);

                return headers;
            },
        });

        const response = await baseQueryFn(...baseQueryFnParams);

        const responseData = response.data as ResponseWithData['data'];

        if (responseData?.xUserInfo) {
            setUserLocalInfo(responseData?.xUserInfo);
        }

        if (responseData?.redirectUrl && !params?.customRedirect) {
            const queryParams = getQueryParams(responseData.redirectUrl);

            if (queryParams.code) {
                metricsData.acrValues = queryRedirectParams?.acr_values;
                sendMetrics('Authorization code', 'Success', metricsData, isMetricsDebugEnabled);
            } else {
                metricsData.landingPage = getRouteFromRedirect(responseData.redirectUrl);
                sendMetrics('Redirect to page', 'Navigate', metricsData, isMetricsDebugEnabled);
            }

            api.dispatch(setMultifactorResponse({ url: responseData.redirectUrl, params: queryParams }));
            redirectTo(responseData.redirectUrl);
        }

        return response;
    };
