import React, { SVGProps } from 'react';

const ArrowBack: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='15'
        height='12'
        fill='none'
        viewBox='0 0 15 12'
        {...props}
    >
        <path fill='#00AFFF' d='M3.5 7H15V5H3.5L7 1.5 5.5 0 0 6l5.5 6L7 10.5 3.5 7z' />
    </svg>
);

export { ArrowBack };
