import { createSelector } from 'reselect';

import { PINCODE_MAX_LENGTH, PINCODE_MIN_LENGTH } from '#/src/shared/lib/form-controls-const';
import { hasPincodeCookies } from '#/src/shared/lib/has-pincode-cookies';
import { FormStatus } from '#/src/shared/models';
import {
    isClickDesign as selectIsClickDesign,
    selectIsPincodeEnabled,
} from '#/src/shared/store/redux/app/selectors';

import { ApplicationState } from '../types';

export const getPincodeState = (state: ApplicationState) => state.Pincode;

export const selectPincodeAttemptsLeft = createSelector(
    [getPincodeState],
    (pincode) => pincode.attemptsLeft,
);
export const selectServerPublicKey = createSelector(
    [getPincodeState],
    (pincode) => pincode.serverPublicKey,
);
export const selectPincodeNonce = createSelector([getPincodeState], (pincode) => pincode.nonce);

export const selectPincode = createSelector([getPincodeState], (pincode) => pincode.code);
export const selectPincodeShowError = createSelector(
    [getPincodeState],
    (pincode) => pincode.showError,
);
export const selectPincodeError = createSelector([getPincodeState], (pincode) => pincode.error);
export const selectPincodeFormStatus = createSelector(
    [getPincodeState],
    (pincode) => pincode.formStatus,
);
export const selectSignatureBase64 = createSelector(
    [getPincodeState],
    (pincode) => pincode.signatureBase64,
);
export const selectPincodeErrorMessage = createSelector(
    [getPincodeState],
    (pincode) => pincode.message,
);
export const selectIsPincodeVisited = createSelector(
    [getPincodeState],
    (pincode) => pincode.isVisited,
);
export const selectNewBrowserSecret = createSelector(
    [getPincodeState],
    (pincode) => pincode.newBrowserSecret,
);
export const selectNewBrowserSecretEnc = createSelector(
    [getPincodeState],
    (pincode) => pincode.newBrowserSecretEnc,
);

export const selectIsPincodeAvailable = createSelector(
    [selectIsClickDesign, selectIsPincodeEnabled],
    (isClickDesign, isPincodeEnabled) =>
        Boolean(isPincodeEnabled && isClickDesign && hasPincodeCookies()),
);

export const selectHasPincodeActivateData = createSelector(
    [selectPincodeAttemptsLeft, selectPincodeNonce, selectServerPublicKey],
    (attemptsLeft, nonce, serverPublicKey) => Boolean(attemptsLeft > 0 && nonce && serverPublicKey),
);

export const selectIsPincodeButtonDisabled = createSelector(
    [selectPincode, selectPincodeFormStatus],
    (code, formStatus) =>
        Boolean(
            code.length < PINCODE_MIN_LENGTH ||
                code.length > PINCODE_MAX_LENGTH ||
                formStatus === FormStatus.SubmitProcess,
        ),
);
