import React, { useEffect, useRef, useState } from 'react';

import { IconButton } from '@alfalab/core-components/icon-button';
import { Input, InputProps } from '@alfalab/core-components/input';
import { EyeMIcon } from '@alfalab/icons-glyph/EyeMIcon';
import { EyeOffMIcon } from '@alfalab/icons-glyph/EyeOffMIcon';

import './input-password-browser.css';

interface Props {
    className?: string;
    disabled?: boolean;
    error?: string | null;
    label?: string;
    maxLength: number;
    onInputChange: InputProps['onChange'];
}

const InputPassportBrowser: React.FC<Props> = ({
    onInputChange,
    error,
    maxLength,
    label,
    className,
    disabled,
}) => {
    const [isPasswordType, setIsPasswordType] = useState(true);
    const textInput: React.RefObject<HTMLInputElement> = useRef(null);

    const toggleInputType = () => {
        setIsPasswordType(!isPasswordType);
    };

    useEffect(() => {
        textInput.current?.focus();
    }, [textInput]);

    return (
        <Input
            clear={false}
            ref={textInput}
            disabled={disabled}
            className={className}
            label={label || 'Пароль'}
            error={error}
            maxLength={maxLength}
            size='xl'
            block={true}
            type={isPasswordType ? 'password' : 'text'}
            onChange={onInputChange}
            rightAddons={
                <IconButton
                    onClick={toggleInputType}
                    className='input-password-browser__icon'
                    view='primary'
                    size='xs'
                    icon={isPasswordType ? EyeMIcon : EyeOffMIcon}
                    disabled={disabled}
                />
            }
        />
    );
};

export default InputPassportBrowser;
