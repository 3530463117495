import React, { FC, useState } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { Update } from 'history';

import DesktopRoutes from '#/src/desktop/routes/routes';
import MobileRoutes from '#/src/mobile/routes/routes';
import { history } from '#/src/shared/history';
import { useIsomorphicLayoutEffect } from '#/src/shared/hooks';
import { checkContextRoot } from '#/src/shared/lib/check-context-root';
import { StoreType } from '#/src/shared/store';

interface RootType {
    store: StoreType;
}

const BrowserRoot: FC<RootType> = ({ store }) => {
    const [state, setState] = useState({ location: history.location, action: history.action });
    const initialStore = store.getState();
    const { contextRoot } = initialStore.Settings;
    const { isMobile } = initialStore.App;
    const routerLocation = checkContextRoot(state.location, contextRoot);
    const unListen = history.listen((update: Update) => {
        setState({ location: update.location, action: update.action });
    });

    useIsomorphicLayoutEffect(() => unListen, []);

    return (
        <Provider store={store}>
            <Router
                basename={contextRoot}
                location={routerLocation}
                navigator={history}
                navigationType={state.action}
            >
                {isMobile ? <MobileRoutes /> : <DesktopRoutes />}
            </Router>
        </Provider>
    );
};

export default BrowserRoot;
