import React, { FC, useEffect, useState } from 'react';
import { createCn } from 'bem-react-classname';

import Button from 'arui-feather/button';
import Form from 'arui-feather/form';
import FormField from 'arui-feather/form-field';
import Spin from 'arui-feather/spin';

import InputCase from '#/src/shared/components/ui/input-case';
import PhoneInput from '#/src/shared/components/ui/phone-input';
import { useAppDispatch, useAppSelector } from '#/src/shared/hooks';
import { trackUserEvent } from '#/src/shared/lib/analitycs';
import {
    FORM_BUTTON_SIZE,
    FORM_FIELD_SIZE,
    FORM_INPUT_SIZE,
    PHONE_INPUT_MAX_LENGTH,
    PHONE_INPUT_MIN_LENGTH,
    PHONE_INPUT_MIN_LENGTH_RU,
} from '#/src/shared/lib/form-controls-const';
import { clearFormatting, formatPhoneNumber } from '#/src/shared/lib/formatters';
import { enterDemoOnIOS } from '#/src/shared/lib/passport-mobile-bridge';
import { phoneNumberValidate } from '#/src/shared/lib/validators';
import { ButtonNames, DEMO_PHONE_NUMBER, FormStatus, RegistrationType } from '#/src/shared/models';
import { useRequestPhoneRegistrationMutation } from '#/src/shared/store/api/registration-api';
import { getQueryRedirectParams, isNewUi } from '#/src/shared/store/redux/app/selectors';
import {
    getRegistrationFormStatus,
    getRegistrationPhone,
    getRegistrationServerErrors,
    phoneAuthErrorMessage,
} from '#/src/shared/store/redux/registration/selectors';
import {
    phoneRegistrationSubmit,
    registrationFormUpdated,
    registrationServerErrorsCleared,
    registrationTypeUpdated,
} from '#/src/shared/store/redux/registration/slice';

import errorDictionary from '../../../error-dictionary';

const cn = createCn('form-basic');

const PhoneAuth: FC = () => {
    const dispatch = useAppDispatch();
    const [requestPhoneRegistration] = useRequestPhoneRegistrationMutation();

    const phone = useAppSelector(getRegistrationPhone);
    const formStatus = useAppSelector(getRegistrationFormStatus);
    const serverErrors = useAppSelector(getRegistrationServerErrors);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const errorMessage = useAppSelector(phoneAuthErrorMessage);
    const newUi = useAppSelector(isNewUi);

    const [firstKeyPush, setFirstKeyPush] = useState(true);

    const handleSubmit = (event?: React.FormEvent<any>) => {
        event?.preventDefault();

        trackUserEvent(
            'Auth Page',
            'Click',
            'Phone Send',
            queryRedirectParams.client_id,
            'Sending',
        );

        if (phone === DEMO_PHONE_NUMBER) {
            enterDemoOnIOS();
        } else {
            dispatch(
                phoneRegistrationSubmit({
                    type: RegistrationType.Phone,
                    phone,
                }),
            );
        }
        if (formStatus === FormStatus.ValidationSuccess) {
            requestPhoneRegistration();
        }
    };

    const handlePhoneChange = (phone?: string) => {
        if (!firstKeyPush) {
            trackUserEvent(
                'Auth Page',
                'Field Change',
                'Enter Phone Number',
                queryRedirectParams.client_id,
            );
            setFirstKeyPush(true);
        }
        dispatch(registrationFormUpdated({ phone }));
        dispatch(registrationServerErrorsCleared());
    };

    const isSubmitButtonDisabled = () => {
        const phoneNumber = clearFormatting(phone);
        const PHONE_INPUT_MIN_LENGTH_RU_CHECKED =
            phoneNumber && phoneNumber.charAt(0) === '7'
                ? PHONE_INPUT_MIN_LENGTH_RU
                : PHONE_INPUT_MIN_LENGTH;

        return (
            (phoneNumber && phoneNumber.length < PHONE_INPUT_MIN_LENGTH_RU_CHECKED) ||
            !!errorMessage
        );
    };

    const isGetCardButtonVisible = () =>
        Array.isArray(serverErrors) &&
        serverErrors.length &&
        serverErrors[0].message === errorDictionary.PHONE_NOT_FOUND;

    useEffect(() => {
        const isPhoneValid = phoneNumberValidate(queryRedirectParams.phone, { withLength: true });

        dispatch(registrationTypeUpdated(RegistrationType.Phone));
        if (isPhoneValid) {
            dispatch(
                registrationFormUpdated({
                    phone: formatPhoneNumber(queryRedirectParams.phone),
                }),
            );
            requestPhoneRegistration();
            trackUserEvent(
                'Auth Page',
                'Impression',
                'Viewing Page',
                queryRedirectParams.client_id,
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (formStatus === FormStatus.ValidationSuccess) {
            requestPhoneRegistration();
        }
    }, [requestPhoneRegistration, formStatus]);

    useEffect(() => {
        if (errorMessage) {
            trackUserEvent(
                'Auth Page',
                'Click',
                'Phone Send',
                queryRedirectParams.client_id,
                `Error: ${errorMessage}`,
            );
        }
    }, [errorMessage, queryRedirectParams.client_id]);

    return (
        <Form className={cn({ flex: 'space-between' })} onSubmit={handleSubmit}>
            <FormField size={FORM_FIELD_SIZE}>
                <InputCase>
                    <PhoneInput
                        isNewUi={newUi}
                        size={FORM_INPUT_SIZE}
                        width='available'
                        phone={phone}
                        maxLength={PHONE_INPUT_MAX_LENGTH}
                        onChange={handlePhoneChange}
                        error={errorMessage}
                        resetError={false}
                        label='Номер телефона'
                    />
                </InputCase>
            </FormField>
            {isGetCardButtonVisible() ? (
                <Button
                    size={FORM_BUTTON_SIZE}
                    width='available'
                    type='button'
                    view='extra'
                    // TODO: replace url
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    url='https://anketa.alfabank.ru/navigator/click-unauth'
                >
                    {ButtonNames.getCard}
                </Button>
            ) : (
                <Button
                    size={FORM_BUTTON_SIZE}
                    text={ButtonNames.continue}
                    width='available'
                    type='submit'
                    view='extra'
                    disabled={isSubmitButtonDisabled()}
                    icon={
                        <Spin
                            size={FORM_BUTTON_SIZE}
                            visible={formStatus === FormStatus.SubmitProcess}
                        />
                    }
                />
            )}
        </Form>
    );
};

export default PhoneAuth;
