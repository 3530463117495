import React from 'react';

import DesignCheck from '#/src/shared/components/ui/design-check';

import MessengerPhoneAuth from '../../components/phone-auth/messenger-phone-auth';
import PhoneAuth from '../../components/phone-auth/phone-auth';
import PhoneAuthBrowser from '../../components/phone-auth/phone-auth-browser';
import PhoneAuthNew from '../../components/phone-auth/phone-auth-new';
import X5WebPhoneAuth from '../../components/phone-auth/x5-web-phone-auth';

export default (props: any) => (
    <DesignCheck
        defaultComponent={<PhoneAuthBrowser {...props} />}
        defaultMobileComponent={<PhoneAuthBrowser {...props} />}
        mobileComponent={<PhoneAuthNew {...props} />}
        corpComponent={<PhoneAuth {...props} />}
        bankMessengerComponent={<MessengerPhoneAuth {...props} />}
        X5WebComponent={<X5WebPhoneAuth {...props} />}
    />
);
