import { createSlice } from '@reduxjs/toolkit';

import { getClientError, getServerError } from '#/src/shared/lib/validators';
import { FormStatus } from '#/src/shared/models';
import { AuthorizationInitialState } from '#/src/shared/store/redux/authorization/constants';
import { AuthorizationError } from '#/src/shared/store/redux/authorization/types';

export const authorizationSlice = createSlice({
    name: 'authorization',
    initialState: AuthorizationInitialState,
    reducers: {
        authorizationFormUpdated(state, action) {
            return { ...state, ...action.payload };
        },
        authorizationErrorUpdated(state, action) {
            state.formError = { ...state.formError, ...action.payload };
        },
        authorizationFormReset(state) {
            return {
                ...state,
                formError: {},
                formStatus: '',
            };
        },
        authServerErrorNotificationClosed(state) {
            state.serverErrors = [];
        },
        authByLoginRequested(state) {
            state.formStatus = FormStatus.SubmitProcess;
        },
        authByLoginResolved(state) {
            state.formStatus = FormStatus.SubmitSuccess;
        },
        authByLoginRejected(state, action) {
            const error = action.payload && action.payload[0];

            if (Object.prototype.hasOwnProperty.call(error, 'status')) {
                if (error.status < 500) {
                    return {
                        ...state,
                        formError: {
                            password: getClientError(error).message,
                        },
                        formStatus: '',
                    };
                }

                return {
                    ...state,
                    serverErrors: [getServerError(error)],
                    formStatus: '',
                };
            }

            return state;
        },
        authorizationValidated(state, action) {
            if (action.payload.validationStatus) {
                state.formStatus = FormStatus.SubmitError;
                state.formError[action.payload.validationType as keyof AuthorizationError] =
                    action.payload.validationStatus;
            } else {
                state.formStatus = FormStatus.ValidationSuccess;
            }
        },
        authorizationPasswordReset(state) {
            state.password = '';
        },
        authorizationSmsLimitResponse(state, action) {
            state.isLimitsClick = action.payload;
        },
        authorizationServerErrorReset(state) {
            state.serverErrors = [];
        },
    },
});

export const {
    authorizationFormUpdated,
    authorizationErrorUpdated,
    authorizationFormReset,
    authServerErrorNotificationClosed,
    authByLoginRequested,
    authByLoginResolved,
    authByLoginRejected,
    authorizationValidated,
    authorizationPasswordReset,
    authorizationSmsLimitResponse,
    authorizationServerErrorReset,
} = authorizationSlice.actions;

export default authorizationSlice.reducer;
