import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { MaskedInput } from '@alfalab/core-components/masked-input';
import Form from 'arui-feather/form';

import { useAppDispatch, useAppSelector } from '#/src/shared/hooks';
import {
    validateAccountNumber,
    validateCardNumber,
} from '#/src/shared/lib/client-validation/registration';
import {
    CARD_INPUT_MAX_LENGTH,
    LOGIN_INPUT_MAX_LENGTH,
} from '#/src/shared/lib/form-controls-const';
import { submitNativeEvent } from '#/src/shared/lib/submit-native-event';
import { ButtonNames, nativeEventStatus, RegistrationType } from '#/src/shared/models';
import { useRequestRegistrationMutation } from '#/src/shared/store/api/registration-api';
import {
    getRegistrationAccount,
    getRegistrationCard,
    getRegistrationFormError,
    getRegistrationServerErrors,
    getRegistrationType,
} from '#/src/shared/store/redux/registration/selectors';
import {
    registrationErrorUpdated,
    registrationFormReset,
    registrationFormUpdated,
    registrationServerErrorsCleared,
    registrationTypeChanged,
} from '#/src/shared/store/redux/registration/slice';

const cn = createCn('investments');

const InvestmentsCardAccount: FC = () => {
    const dispatch = useAppDispatch();
    const [requestRegistration] = useRequestRegistrationMutation();

    const regError = useAppSelector(getRegistrationFormError);
    const regServerErrors = useAppSelector(getRegistrationServerErrors);
    const card = useAppSelector(getRegistrationCard);
    const account = useAppSelector(getRegistrationAccount);
    const regType = useAppSelector(getRegistrationType);

    const [value, setValue] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const isError = regServerErrors?.[0]?.message || regError?.card || regError?.account;

    useEffect(() => {
        if (card || account) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [card, account]);

    useEffect(
        () => () => {
            dispatch(registrationFormReset());
        },
        [dispatch],
    );

    useEffect(() => {
        if (isError) {
            submitNativeEvent({ status: nativeEventStatus.loginFailed });
        }
    }, [isError]);

    const maskFn = React.useCallback((newValue: string) => {
        // prettier-ignore
        const cardMask = [
            /\d/, /\d/, /\d/, /\d/, ' ',
            /\d/, /\d/, /\d/, /\d/, ' ',
            /\d/, /\d/, /\d/, /\d/, ' ',
            /\d/, /\d/, /\d/, /\d/,
        ];
        // prettier-ignore
        const accountNumberMask = [
            /\d/, /\d/, /\d/, /\d/, ' ',
            /\d/, /\d/, /\d/, /\d/, ' ',
            /\d/, /\d/, /\d/, /\d/, ' ',
            /\d/, /\d/, /\d/, /\d/, ' ',
            /\d/, /\d/, /\d/, /\d/,
        ];

        return newValue.length <= cardMask.length ? cardMask : accountNumberMask;
    }, []);

    const clearErrors = () => {
        if (regServerErrors.length) {
            dispatch(registrationServerErrorsCleared());
        }
        if (regError.card || regError.account) {
            dispatch(
                registrationErrorUpdated({
                    card: '',
                    account: '',
                }),
            );
        }
    };

    const clearInput = () => {
        clearErrors();
        setValue('');
        dispatch(
            registrationFormUpdated({
                card: '',
                account: '',
            }),
        );
    };

    const handleChangeCardAccount = (newValue: string) => {
        clearErrors();
        setValue(newValue);
        const trimmedValue = newValue.trim();

        if (trimmedValue.length <= CARD_INPUT_MAX_LENGTH) {
            dispatch(registrationFormUpdated({ card: trimmedValue }));
            if (regType !== RegistrationType.Card) {
                dispatch(registrationTypeChanged(RegistrationType.Card));
            }
        } else {
            dispatch(registrationFormUpdated({ account: trimmedValue }));
            if (regType !== RegistrationType.Account) {
                dispatch(registrationTypeChanged(RegistrationType.Account));
            }
        }
    };

    const handleCardValidate = (cardValue: string) => {
        const cardValidation = validateCardNumber(cardValue);

        if (cardValidation === null) {
            requestRegistration();
        } else {
            dispatch(registrationErrorUpdated({ card: cardValidation }));
        }
    };

    const handleAccountValidate = (accountValue: string) => {
        const accountValidation = validateAccountNumber(accountValue);

        if (accountValidation === null) {
            requestRegistration();
        } else {
            dispatch(registrationErrorUpdated({ account: accountValidation }));
        }
    };

    const handleCardAccountSubmit = () => {
        submitNativeEvent({ status: nativeEventStatus.buttonPressed });

        switch (regType) {
            case RegistrationType.Card:
                handleCardValidate(card);
                break;

            case RegistrationType.Account:
                handleAccountValidate(account);
        }
    };

    return (
        <Form onSubmit={handleCardAccountSubmit} className={cn('form')} noValidate={true}>
            <div className={cn('row')}>
                <MaskedInput
                    mask={maskFn}
                    value={value}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleChangeCardAccount(e.target.value)
                    }
                    onClear={clearInput}
                    autoFocus={true}
                    maxLength={LOGIN_INPUT_MAX_LENGTH}
                    label='Номер карты или счёта'
                    clear={true}
                    size='m'
                    block={true}
                />
                {isError && (
                    <div className={cn('error-text-wrapper')}>
                        <div className={cn('error-text')}>{isError}</div>
                    </div>
                )}
            </div>
            <div className={cn('button-wrapper')}>
                <Button
                    block={true}
                    type='submit'
                    view='primary'
                    className={cn('button')}
                    disabled={isDisabled}
                >
                    {ButtonNames.continue}
                </Button>
            </div>
        </Form>
    );
};

export default InvestmentsCardAccount;
