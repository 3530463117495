import type { ReactElement } from 'react';
import React from 'react';

import { List } from '@alfalab/core-components/list';
import { Typography } from '@alfalab/core-components/typography';

type Props = {
    apps: ReactElement;
    allow: ReactElement;
    camera: ReactElement;
    permissions: ReactElement;
};

export const AndroidText: React.FC<Props> = ({ apps, allow, camera, permissions}) => (
    <List tag='ol'>
        <Typography.Text>Перейдите в&nbsp;настройки телефона.</Typography.Text>
        <Typography.Text>Выберите {apps}.</Typography.Text>
        <Typography.Text>Выберите название вашего браузера, например, Chrome.</Typography.Text>
        <Typography.Text>
            В&nbsp;разделе {permissions} выберите пункт меню {camera}.
        </Typography.Text>
        <Typography.Text>Нажмите {allow}.</Typography.Text>
    </List>
);
