import { createApi } from '@reduxjs/toolkit/query/react';
import uuid from 'uuid';

import { checkClientId } from '#/src/shared/lib/check-client-id';
import { checkPincodeParams } from '#/src/shared/lib/check-pincode-pararms';
import { clientErrorLog, clientInfoLog } from '#/src/shared/lib/client-logger';
import getCookie from '#/src/shared/lib/get-cookie';
import { setBrowserSecret } from '#/src/shared/lib/set-browser-secret';
import { initFP } from '#/src/shared/lib/sinc-fingerprint';
import { setCookie } from '#/src/shared/lib/update-cookie';
import webSocketCreator from '#/src/shared/lib/web-socket-creator';
import { CookiesName, LocalStorageName, QRStatus } from '#/src/shared/models';
import { customFetchBaseQuery } from '#/src/shared/store/base-query';
import { getQueryRedirectParams, selectClientId } from '#/src/shared/store/redux/app/selectors';
import { authorizationErrorUpdated } from '#/src/shared/store/redux/authorization/slice';
import { selectFingerPrintCredentials } from '#/src/shared/store/redux/fingerprint/selectors';
import {
    authorizationByAssertion,
    authorizationByAssertionRejected,
    authorizationByAssertionResolved,
    getQRCode,
    qrCleared,
    qrCodeResolved,
    qrWebSocketConnectionStatusSet,
} from '#/src/shared/store/redux/qr-authorization/slice';
import { ApplicationState } from '#/src/shared/store/types';
import { Endpoint, HttpMethod } from '#/src/shared/utils';

export const qrAuthorizationApi = createApi({
    reducerPath: 'qrAuthorizationApi',
    baseQuery: customFetchBaseQuery(),
    endpoints: (build) => ({
        authorizationByAssertionRequest: build.mutation<any, string>({
            queryFn: async (assertion, queryApi, _extraOptions, fetchWithBQ) => {
                const { dispatch } = queryApi;

                await initFP(queryApi);
                const state = queryApi.getState() as ApplicationState;

                const browserId = getCookie(CookiesName.browserId);
                const fingerprint = selectFingerPrintCredentials(state);
                const queryRedirectParams = getQueryRedirectParams(state);
                const newBrowserSecret = state?.Pincode?.newBrowserSecret;
                const newBrowserSecretEnc = state?.Pincode?.newBrowserSecretEnc;
                const browserSecret = window?.localStorage?.getItem(LocalStorageName.browserSecret);
                const browserSecretDate = window?.localStorage?.getItem(
                    LocalStorageName.browserSecretDate,
                );
                const clientId = selectClientId(state);

                const logMessage = checkPincodeParams(
                    browserId,
                    browserSecret,
                    browserSecretDate,
                    newBrowserSecretEnc,
                    clientId,
                );

                await clientInfoLog(logMessage);

                dispatch(authorizationByAssertion());

                const body = {
                    new_browser_secret_enc: newBrowserSecretEnc,
                    browser_secret_date: browserSecretDate,
                    qr_assertion: assertion,
                    queryRedirectParams,
                    ...fingerprint,
                    browser_id: browserId,
                };

                const result = await fetchWithBQ({
                    url: Endpoint.QR_AUTH,
                    method: HttpMethod.POST,
                    body,
                });

                if (result.error) {
                    const errorResult = result.error.data as any;

                    dispatch(authorizationByAssertionRejected(errorResult.errors));
                    dispatch(authorizationErrorUpdated(errorResult.errors[0]));

                    return { error: result.error };
                }

                setCookie(CookiesName.passportSessionId, uuid.v4(), {
                    domain: '.alfabank.ru',
                    path: '/',
                    secure: true,
                    sameSite: 'Lax',
                });

                if (newBrowserSecretEnc) {
                    setBrowserSecret(newBrowserSecret);
                }

                dispatch(authorizationByAssertionResolved());

                return { data: result.data };
            },
        }),
        QRCodeRequest: build.mutation<any, void>({
            queryFn: async (_payload, queryApi) => {
                const { dispatch } = queryApi;
                const state = queryApi.getState() as ApplicationState;

                let QRTimeout: ReturnType<typeof setTimeout>;
                const { queryRedirectParams = {}, isRedirectEnabled } = state.App;
                const { QRLink } = state.QRAuthorization;
                const clientId = queryRedirectParams.client_id as string;
                const qrTimeout = queryRedirectParams.qr_timeout;

                dispatch(qrWebSocketConnectionStatusSet(QRStatus.Pending));
                dispatch(getQRCode());

                webSocketCreator({
                    url: `${QRLink}?client_id=${checkClientId(isRedirectEnabled, clientId)}${
                        qrTimeout ? `&qr_timeout=${qrTimeout}` : ''
                    }`,
                    onOpen: () => {
                        dispatch(qrWebSocketConnectionStatusSet(QRStatus.Open));
                    },
                    onClose: (event: any) => {
                        dispatch(qrCleared());
                        if (event.code === 1002) {
                            dispatch(qrWebSocketConnectionStatusSet(QRStatus.Closed));
                        } else if (event.code === 1000) {
                            dispatch(qrWebSocketConnectionStatusSet(QRStatus.Timeout));
                        } else {
                            dispatch(qrWebSocketConnectionStatusSet(QRStatus.NotConnected));
                        }
                    },
                    onMessage: (event: any) => {
                        const { data, type } = JSON.parse(event.data);

                        if (type === 'CODE') {
                            clearTimeout(QRTimeout);
                            QRTimeout = setTimeout(() => {
                                dispatch(qrCleared());
                            }, data.ttlSec * 1000);
                            dispatch(qrCodeResolved(data.content));
                        }
                        if (type === 'ASSERTION') {
                            dispatch(
                                qrAuthorizationApi.endpoints.authorizationByAssertionRequest.initiate(
                                    data.assertion,
                                ),
                            );
                        }
                    },
                    onError: (error: any) => {
                        const err = new Error(`Websocket error: ${error}`);

                        clientErrorLog(err);

                        dispatch(qrWebSocketConnectionStatusSet(QRStatus.Closed));
                    },
                });

                return { data: 'ok' };
            },
        }),
    }),
});

export const { useQRCodeRequestMutation } = qrAuthorizationApi;
