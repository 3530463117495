import type { ClientLog as ClientLogLib } from 'corporate-logger';

import { fetchers } from '#/src/shared/lib/client-api';

// Поле level внутри corporate-logger является enum-ом, который лежит в internal-части пакета,
// и она отличается от версии к версии. Поэтому переопределяем тип локально.
export type ClientLog = Omit<ClientLogLib, 'level'> & {
    level: 'info' | 'warn' | 'error';
};

export function clientInfoLog(message: string) {
    return fetchers.sendClientLog({
        level: 'info',
        message,
    });
}

export function clientWarnLog(message: string) {
    return fetchers.sendClientLog({
        level: 'warn',
        message,
    });
}

export function clientErrorLog(error: Error) {
    return fetchers.sendClientLog({
        level: 'error',
        message: error.message,
        stackTrace: error.stack,
    });
}
export function onBrowserError(message: string, stackTrace?: string) {
    return fetchers.sendClientLog({
        level: 'error',
        message,
        stackTrace,
    });
}
