import React, { FC } from 'react';
import { createCn } from 'bem-react-classname';

import { Plate } from '@alfalab/core-components/plate';
import { StatusBadge } from '@alfalab/core-components/status-badge';

import { useAppDispatch, useAppSelector } from '#/src/shared/hooks';
import { selectNotificationsData } from '#/src/shared/store/redux/app/selectors';
import { notificationClosed } from '#/src/shared/store/redux/app/slice';

import './plate-notification.css';

const cn = createCn('plate-notification');

const PlateNotification: FC = () => {
    const dispatch = useAppDispatch();
    const notification = useAppSelector(selectNotificationsData);

    if (notification !== null) {
        const { title, message, notificationStatus } = notification;

        const handleOnClose = () => dispatch(notificationClosed());

        const leftAddons = () => {
            switch (notificationStatus) {
                case 'warnings':
                    return <StatusBadge view='negative-alert' />;
                case 'advices':
                    return <StatusBadge view='attention-alert' />;
                default:
                    return <StatusBadge view='neutral-information' />;
            }
        };

        const view = () => {
            switch (notificationStatus) {
                case 'warnings':
                    return 'negative';
                case 'advices':
                    return 'attention';
                default:
                    return 'common';
            }
        };

        return (
            <Plate
                className={cn()}
                title={title}
                titleView='bold'
                view={view()}
                border={false}
                rounded={false}
                hasCloser={true}
                limitContentWidth={false}
                rowLimit={3}
                onClose={handleOnClose}
                leftAddons={leftAddons()}
                dataTestId="plate-notification"
            >
                {message}
            </Plate>
        );
    }

    return null;
};

export default PlateNotification;
