import React, { useEffect } from 'react';
import { createCn } from 'bem-react-classname';

import { Spinner } from '@alfalab/core-components/spinner';
import { Typography } from '@alfalab/core-components/typography';
import Form from 'arui-feather/form';

import { useAppDispatch, useAppSelector } from '#/src/shared/hooks';
import { trackUserEvent } from '#/src/shared/lib/analitycs';
import { FormStatus } from '#/src/shared/models';
import { selectClientId } from '#/src/shared/store/redux/app/selectors';
import {
    selectBiometryFormStatus,
    selectBiometryServerErrorId,
} from '#/src/shared/store/redux/biometry';
import { fetchAuhorizeBiometry } from '#/src/shared/store/redux/biometry/fetchAuhorizeBiometry';
import { biometryServerErrorIdSet } from '#/src/shared/store/redux/biometry/slice';

import { ErrorScreenBiometry } from './components/error-screen';

import './biometry.css';

export const cn = createCn('biometry');

const Biometry = () => {
    const dispatch = useAppDispatch();

    const clientId = useAppSelector(selectClientId);
    const serverErrorId = useAppSelector(selectBiometryServerErrorId);
    const formStatus = useAppSelector(selectBiometryFormStatus);

    useEffect(() => {
        trackUserEvent('Auth Page', 'Impression', 'Viewing Page', clientId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (formStatus === FormStatus.SubmitProcess) {
            dispatch(biometryServerErrorIdSet(''));
            dispatch(fetchAuhorizeBiometry());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formStatus]);

    if (serverErrorId) {
        return <ErrorScreenBiometry serverErrorId={serverErrorId} />;
    }

    return (
        <Form className={cn('container')} noValidate={true} autocomplete={false}>
            <Spinner visible={true} size={48} lineWidth={2} className={cn('spinner')} />
            <Typography.Title view='xsmall' tag='div' className={cn('title')}>
                Распознаём фотографию
            </Typography.Title>
            <Typography.Text view='component-primary'>
                Нужно немного времени. Не закрывайте приложение
            </Typography.Text>
        </Form>
    );
};

export default Biometry;
