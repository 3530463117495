import React, { FC } from 'react';

import { useAppSelector } from '#/src/shared/hooks';
import {
    isInvestmentsNewDesign,
    isNewUi,
    selectIsBankMessenger,
    selectIsCorpDesign,
    selectIsMobile,
    selectIsMobileApp,
    selectIsX5Web,
} from '#/src/shared/store/redux/app/selectors';

interface Props {
    defaultComponent: React.ReactElement;
    defaultMobileComponent?: React.ReactElement;
    corpComponent?: React.ReactElement;
    mobileComponent?: React.ReactElement;
    X5WebComponent?: React.ReactElement;
    bankMessengerComponent?: React.ReactElement;
}

const DesignCheck: FC<Props> = ({
    defaultComponent,
    defaultMobileComponent,
    corpComponent,
    mobileComponent,
    X5WebComponent,
    bankMessengerComponent,
}) => {
    const isBankMessenger= useAppSelector(selectIsBankMessenger);
    const isX5Web= useAppSelector(selectIsX5Web);
    const isMobile= useAppSelector(selectIsMobile);
    const isMobileApp= useAppSelector(selectIsMobileApp);
    const newUi= useAppSelector(isNewUi);
    const investmentsNewDesign= useAppSelector(isInvestmentsNewDesign);
    const isCorpDesign= useAppSelector(selectIsCorpDesign);
    const isMobileDesign = (isMobile && isMobileApp && newUi) || investmentsNewDesign;
    const isMobileOldDesign = (isMobile && isMobileApp && !newUi);

    if (isBankMessenger && bankMessengerComponent) {
        return bankMessengerComponent;
    }

    if (isX5Web && X5WebComponent) {
        return X5WebComponent;
    }

    if (isMobileDesign && mobileComponent) {
        return mobileComponent;
    }

    if (isMobileOldDesign && corpComponent) {
        return corpComponent;
    }

    if (isCorpDesign && corpComponent) {
        return corpComponent;
    }

    if (isMobile && defaultMobileComponent) {
        return defaultMobileComponent;
    }

    return defaultComponent;
};

export default DesignCheck;
