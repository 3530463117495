import React, { FC, useEffect, useRef, useState } from 'react';
import { createCn } from 'bem-react-classname';

import { Skeleton } from '@alfalab/core-components/skeleton';

import { TCustomPinIndicatorProps } from './types';

import './pin-indicator.css';

export const CustomPinIndicator: FC<TCustomPinIndicatorProps> = ({
    digitCounts,
    onChange,
    isValidationError,
    onBlur,
    onFocus,
    isLoading,
    isClearOnError,
}) => {
    const array = Array.from(Array(digitCounts).keys());
    const [code, setCode] = useState<string>('');
    const inputRef = useRef<HTMLInputElement>(null);

    const handleInputTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const blurInputTImeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    const handleInput = onChange ?? (() => {});
    const cn = createCn('pin-indicator');

    useEffect(
        () => () => {
            if (handleInputTimeoutRef.current) {
                clearTimeout(handleInputTimeoutRef.current);
            }
            if (blurInputTImeoutRef.current) {
                clearTimeout(blurInputTImeoutRef.current);
            }
        },
        [],
    );

    const handleFocus = () => {
        if (onFocus) {
            clearTimeout(blurInputTImeoutRef.current!);
            onFocus();
        }
    };

    const handleBlur = () => {
        if (onBlur) {
            blurInputTImeoutRef.current = setTimeout(() => {
                onBlur();
            }, 300);
        }
    };

    useEffect(() => {
        if (isValidationError) {
            if (isClearOnError) {
                setCode('');
            }
        }
    }, [isClearOnError, isValidationError]);

    const handleClick = () => {
        inputRef?.current?.focus();
    };

    const handleChange = (code: string) => {
        if (code.length < 5 && !isLoading) {
            setCode(code);

            if (isValidationError) {
                handleInput(code);
            } else {
                clearTimeout(handleInputTimeoutRef.current!);
                handleInputTimeoutRef.current = setTimeout(() => {
                    handleInput(code);
                }, 500);
            }
        }
    };

    return (
        <div className={cn()}>
            {array.map((value, index) => (
                <div
                    role='button'
                    tabIndex={index}
                    onKeyDown={() => {
                        handleClick();
                    }}
                    key={`number-${value}`}
                    id={`number-${value}`}
                    className={cn('inputWrapper', { error: !!isValidationError })}
                    onClick={() => handleClick()}
                >
                    {code != null && code[index] ? <div>•</div> : <div />}
                    {isLoading && (
                        <Skeleton visible={isLoading} animate={true} className={cn('skeleton')} />
                    )}
                </div>
            ))}
            <input
                ref={inputRef}
                onFocus={() => handleFocus()}
                onBlur={() => handleBlur()}
                key='input'
                value={code}
                type='number'
                onChange={(e) => handleChange(e.target.value)}
            />
        </div>
    );
};
