import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { createApi } from '@reduxjs/toolkit/query/react';

import errorDictionary from '#/src/shared/error-dictionary';
import { checkPincodeParams } from '#/src/shared/lib/check-pincode-pararms';
import { clientInfoLog } from '#/src/shared/lib/client-logger';
import getCookie from '#/src/shared/lib/get-cookie';
import parseLocationHash from '#/src/shared/lib/parse-location-hash';
import { errorHandlerBridge } from '#/src/shared/lib/passport-mobile-bridge';
import { setBrowserSecret } from '#/src/shared/lib/set-browser-secret';
import { initFP } from '#/src/shared/lib/sinc-fingerprint';
import { CookiesName, LocalStorageName } from '#/src/shared/models';
import { customFetchBaseQuery } from '#/src/shared/store/base-query';
import {
    selectNewBrowserSecret,
    selectNewBrowserSecretEnc,
} from '#/src/shared/store/pincode/selectors';
import { getQueryRedirectParams, selectClientId } from '#/src/shared/store/redux/app/selectors';
import { selectFingerPrintCredentials } from '#/src/shared/store/redux/fingerprint/selectors';
import { ApplicationState } from '#/src/shared/store/types';
import { JwtAuthorizationCredentials } from '#/src/shared/types/interfaces';
import { Endpoint, HttpMethod } from '#/src/shared/utils';

export const jwtApi = createApi({
    reducerPath: 'jwtApi',
    baseQuery: customFetchBaseQuery(),
    endpoints: (build) => ({
        requestJwtAuthorization: build.mutation<any, void>({
            queryFn: async (_payload, queryApi, _extraOptions, fetchWithBQ) => {
                await initFP(queryApi);

                const state = queryApi.getState() as ApplicationState;

                const queryRedirectParams = getQueryRedirectParams(state);
                const fingerprint = selectFingerPrintCredentials(state);
                const browserId = getCookie(CookiesName.browserId);
                const newBrowserSecret = selectNewBrowserSecret(state);
                const newBrowserSecretEnc = selectNewBrowserSecretEnc(state);
                const browserSecret = window?.localStorage?.getItem(LocalStorageName.browserSecret);
                const browserSecretDate = window?.localStorage?.getItem(
                    LocalStorageName.browserSecretDate,
                );
                const clientId = selectClientId(state);

                const logMessage = checkPincodeParams(
                    browserId,
                    browserSecret,
                    browserSecretDate,
                    newBrowserSecretEnc,
                    clientId,
                );

                await clientInfoLog(logMessage);

                const body: JwtAuthorizationCredentials = {
                    queryRedirectParams,
                    mobile_jwt: parseLocationHash().mobile_jwt,
                    ...fingerprint,
                    browser_id: browserId,
                    new_browser_secret_enc: newBrowserSecretEnc,
                    browser_secret_date: browserSecretDate,
                };

                const result = await fetchWithBQ({
                    url: Endpoint.OID_REGISTRATION_JWT_AUTH,
                    method: HttpMethod.POST,
                    body,
                });

                if (result.error) {
                    const errorResult = result.error.data as any;
                    const { status, id } = errorResult.errors[0];

                    errorHandlerBridge(status, id, errorDictionary.DEFAULT);

                    return { error: result.error as FetchBaseQueryError };
                }

                if (newBrowserSecretEnc) {
                    setBrowserSecret(newBrowserSecret);
                }

                return { data: result.data };
            },
        }),
    }),
});
export const { useRequestJwtAuthorizationMutation } = jwtApi;
