import { FormStatus } from '#/src/shared/models';
import { TempPasswordAuthorizationState } from '#/src/shared/store/redux/temp-password/types';

export const TempPasswordAuthorizationInitialState: TempPasswordAuthorizationState = {
    tempPassword: '',
    error: '',
    attemptsLeft: 1,
    formStatus: FormStatus.Initial,
    serverErrorId: '',
};
