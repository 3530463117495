import React, { FC, LegacyRef, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { createCn } from 'bem-react-classname';

import { GenericConfirmationModal } from 'arui-private/core/generic-confirmation-modal/generic-confirmation-modal';

import { useAppDispatch, useAppSelector } from '#/src/shared/hooks';
import hasCloseSms from '#/src/shared/lib/acr-actions-for-sms';
import { trackUserEvent } from '#/src/shared/lib/analitycs';
import { PHONE_INPUT_MIN_LENGTH, SMS_INPUT_MAX_LENGTH } from '#/src/shared/lib/form-controls-const';
import { getLeftTime } from '#/src/shared/lib/get-left-time';
import { isBrowserEnv } from '#/src/shared/lib/is-browser-env';
import { FormStatus, Themes } from '#/src/shared/models';
import {
    useRequestOIDSmsVerificationMutation,
    useRequestReferenceBySmsMutation,
} from '#/src/shared/store/api/sms-verification-api';
import {
    formatMaskedPhoneNumber,
    getQueryRedirectParams,
    selectIsCorporateClientId,
    selectMultiFactorResponseParams,
} from '#/src/shared/store/redux/app/selectors';
import { getRegistrationPhone } from '#/src/shared/store/redux/registration/selectors';
import {
    selectSmsCode,
    selectSmsFormStatus,
    selectSmsRequestStatus,
} from '#/src/shared/store/redux/sms-verification/selectors';
import {
    smsServerErrorNotificationClosed,
    smsVerificationFormUpdated,
    smsVerificationServerErrorsReset,
    smsVerificationStatusRequested,
    smsVerificationSubmit,
} from '#/src/shared/store/redux/sms-verification/slice';
import { ApplicationState } from '#/src/shared/store/types';

import './sms.css';

const cn = createCn('corp-sms');

const Sms: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [requestOIDSmsVerification] = useRequestOIDSmsVerificationMutation();
    const [requestReferenceBySms] = useRequestReferenceBySmsMutation();

    const formStatus = useAppSelector(selectSmsFormStatus);
    const code = useAppSelector(selectSmsCode);
    const requestStatus = useAppSelector(selectSmsRequestStatus);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const serverErrorMessage = useAppSelector(
        (state: ApplicationState) => (state.SmsVerification.serverErrors[0] || {}).message,
    );
    const previousMultiFactorResponseParams = useAppSelector(selectMultiFactorResponseParams);
    const formatedMaskedPhoneNumber = useAppSelector(formatMaskedPhoneNumber);
    const phone = useAppSelector(getRegistrationPhone);
    const isCorporateClient = useAppSelector(selectIsCorporateClientId);

    const { clientId, acrValues } = queryRedirectParams;
    const nbsp = String.fromCharCode(160);
    const title = `Введите${nbsp}одноразовый пароль${nbsp}из${nbsp}SMS`;
    const pushString = `${nbsp}или${nbsp}Push`;
    const pushCondition = clientId === 'mobile-app' && acrValues === 'card_account:sms';
    const phoneNumber = formatedMaskedPhoneNumber || phone;
    const [isProcessing, setIsProcessing] = useState(true);
    const [firstKeyPush, setFirstKeyPush] = useState(false);
    const smsInput: LegacyRef<GenericConfirmationModal> = useRef(null);

    useEffect(() => {
        requestReferenceBySms();
        setIsProcessing(false);
        if (isBrowserEnv) {
            setShowModal(true);
        }
        trackUserEvent('Auth Page', 'Impression', 'Viewing Page', queryRedirectParams.client_id);
        const timeoutID = setTimeout(() => smsInput?.current?.focus(), 0);

        return () => {
            dispatch(smsVerificationStatusRequested(true));
            dispatch(smsServerErrorNotificationClosed());
            clearTimeout(timeoutID);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            formStatus === FormStatus.ValidationSuccess &&
            code.length === (previousMultiFactorResponseParams.code_length ?? 4)
        ) {
            requestOIDSmsVerification({ code });
        }

        if (formStatus === FormStatus.SubmitError) {
            trackUserEvent(
                'Auth Page',
                'Click',
                'SMS OTP Send',
                queryRedirectParams.client_id,
                `Error ${serverErrorMessage}`,
            );

            setIsProcessing(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formStatus, code]);

    const handleInputChange = (value?: string) => {
        if (!firstKeyPush) {
            trackUserEvent(
                'Auth Page',
                'Field Change',
                'Enter SMS OTP',
                queryRedirectParams.client_id,
            );
            setFirstKeyPush(true);
        }
        dispatch(smsVerificationFormUpdated({ code: value }));
    };

    const handleInputFinished = (value?: string) => {
        if (requestStatus) {
            trackUserEvent(
                'Auth Page',
                'Click',
                'SMS OTP Send',
                queryRedirectParams.client_id,
                'Send',
            );

            setIsProcessing(true);

            dispatch(
                smsVerificationSubmit({
                    code: value,
                }),
            );
        }
    };

    const handleRepeatSms = () => {
        setIsProcessing(true);
        dispatch(smsVerificationFormUpdated({ code: '' }));
        dispatch(smsVerificationStatusRequested(true));
        dispatch(smsVerificationServerErrorsReset());
        requestReferenceBySms();
        setIsProcessing(false);
        smsInput?.current?.focus();
        trackUserEvent('Auth Page', 'Click', 'Resend OTP', queryRedirectParams.client_id);
    };

    const handleClose = () => {
        if (hasCloseSms(queryRedirectParams.acr_values)) {
            dispatch(smsVerificationFormUpdated({ code: '' }));
            navigate(-1);
        }
    };

    const showCountDowns = () =>
        isCorporateClient
            ? (getLeftTime(+previousMultiFactorResponseParams.backoff_to) > 0 ||
                  !!serverErrorMessage) &&
              !Number.isNaN(getLeftTime(+previousMultiFactorResponseParams.backoff_to))
            : true;

    const getLeftTimeOrZero = () =>
        (getLeftTime(+previousMultiFactorResponseParams.backoff_to) > 0
            ? getLeftTime(+previousMultiFactorResponseParams.backoff_to)
            : 0) * 1000;

    if (showModal) {
        return (
            <GenericConfirmationModal
                className={cn()}
                ref={smsInput}
                isProcessing={code.length < SMS_INPUT_MAX_LENGTH ? false : isProcessing}
                isVisible={true}
                phone={
                    phoneNumber && phoneNumber.length >= PHONE_INPUT_MIN_LENGTH ? phoneNumber : ''
                }
                signTitle={pushCondition ? title : title + pushString}
                onInputChange={handleInputChange}
                onInputFinished={handleInputFinished}
                requiredCharAmount={4}
                onSmsRetryClick={handleRepeatSms}
                hasSmsCountdown={showCountDowns()}
                countdownDuration={isCorporateClient ? getLeftTimeOrZero() : 60 * 1000}
                error={serverErrorMessage}
                // resetError={false}
                hasCloser={hasCloseSms(acrValues)}
                onClose={handleClose}
                theme={Themes.whiteTheme}
            />
        );
    }

    return null;
};

export default Sms;
