import React from 'react';

import DesignCheck from '#/src/shared/components/ui/design-check';

import PasscodeBrowser from '../../components/passcode/passcode-browser';
import PasscodeMobile from '../../components/passcode/passcode-mobile';

const Passcode = () => (
    <DesignCheck
        defaultComponent={<PasscodeBrowser />}
        defaultMobileComponent={<PasscodeBrowser />}
        mobileComponent={<PasscodeMobile />}
    />
);

export default Passcode;
