import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { SystemMessage } from '@alfalab/core-components/system-message';

import { useAppSelector } from '#/src/shared/hooks';
import { Routes } from '#/src/shared/models';
import { isNewUi, selectIsMobile } from '#/src/shared/store/redux/app/selectors';

import { statusDataDesktop, statusDataMobile } from './status-data';

import './status-message.css';

const cn = createCn('status-message');

const StatusMessage = () => {
    const navigate = useNavigate();
    const { search } = useLocation();

    const isMobile = useAppSelector(selectIsMobile);
    const newUi = useAppSelector(isNewUi);

    const statusData = isMobile ? statusDataMobile : statusDataDesktop;
    const { title, subtitle, icon } = statusData.online;

    return (
        <SystemMessage className={cn('error-screen')} fullHeight={isMobile}>
            <div className={cn('error-screen-main')}>
                <SystemMessage.Graphic>
                    <SuperEllipse size={80}>{icon}</SuperEllipse>
                </SystemMessage.Graphic>
                <SystemMessage.Title>{title}</SystemMessage.Title>
                <SystemMessage.Subtitle>{subtitle}</SystemMessage.Subtitle>
            </div>
            {isMobile ? (
                <SystemMessage.Controls direction='column'>
                    <Button
                        block={true}
                        view='secondary'
                        onClick={() =>
                            navigate({
                                pathname: Routes.CARD_ACCOUNT,
                                search,
                            })
                        }
                    >
                        Попробовать ещё раз
                    </Button>
                    {!newUi && <Gap size='m' direction='horizontal' />}
                </SystemMessage.Controls>
            ) : (
                <React.Fragment>
                    <Gap size='2xl' direction='vertical' />
                    <Button
                        size='s'
                        view='secondary'
                        onClick={() =>
                            navigate({
                                pathname: Routes.CARD_ACCOUNT,
                                search,
                            })
                        }
                    >
                        Обновить
                    </Button>
                </React.Fragment>
            )}
        </SystemMessage>
    );
};

export default StatusMessage;
