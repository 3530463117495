import { createApi } from '@reduxjs/toolkit/query/react';
import uuid from 'uuid';

import errorDictionary from '#/src/shared/error-dictionary';
import { trackUserEvent } from '#/src/shared/lib/analitycs';
import { redirectTo } from '#/src/shared/lib/redirect';
import { setCookie, updateCookie } from '#/src/shared/lib/update-cookie';
import { CookiesName, Routes } from '#/src/shared/models';
import { customFetchBaseQuery } from '#/src/shared/store/base-query';
import {
    authByLoginRejected,
    authByLoginRequested,
    authByLoginResolved,
    authorizationErrorUpdated,
} from '#/src/shared/store/redux/authorization/slice';
import {
    AuthorizeCustomerByOIDCCredentials,
    GetTempPassworedAlboCredentials,
} from '#/src/shared/types/interfaces';
import { Endpoint, HttpMethod } from '#/src/shared/utils';

export const authorizationApi = createApi({
    reducerPath: 'authorizationApi',
    baseQuery: customFetchBaseQuery(),
    endpoints: (build) => ({
        requestGetTempPasswordAlbo: build.mutation<any, GetTempPassworedAlboCredentials>({
            query: (credentials) => ({
                url: Endpoint.OID_AUTH_GET_TEMP_PASSWORD_ALBO,
                method: HttpMethod.POST,
                body: credentials,
            }),
            async onQueryStarted(_credentials, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    redirectTo(Routes.TEMP_PASSWORD_BY_SMS);
                } catch (error) {
                    dispatch(authorizationErrorUpdated(error));
                }
            },
        }),
        requestOIDAuthByLogin: build.mutation<any, AuthorizeCustomerByOIDCCredentials>({
            query: (credentials) => ({
                url: Endpoint.OID_AUTH,
                method: HttpMethod.POST,
                body: credentials,
            }),
            async onQueryStarted(credentials, { dispatch, queryFulfilled }) {
                dispatch(authByLoginRequested());
                try {
                    const { data } = await queryFulfilled;

                    if (Array.isArray(data.errors)) {
                        let errorMessage = '';
                        const error = data.errors[0];

                        if (error?.base_id) {
                            errorMessage = error?.message?.en;
                        } else {
                            errorMessage = error?.detail || error?.title || error?.message || '';
                        }
                        trackUserEvent(
                            'Auth Page',
                            'Click',
                            'Username Password Send',
                            credentials.queryRedirectParams.client_id,
                            `Error: ${errorMessage}`,
                        );
                        dispatch(authByLoginRejected(data.errors));
                    }
                    trackUserEvent(
                        'Auth Page',
                        'Click',
                        'Username Password Send',
                        credentials.queryRedirectParams.client_id,
                        'Send',
                    );
                    dispatch(authByLoginResolved());
                    updateCookie(CookiesName.acl, credentials.username);
                    updateCookie(CookiesName.changedFlow, 'true');
                    setCookie(CookiesName.passportSessionId, uuid.v4(), {
                        domain: '.alfabank.ru',
                        path: '/',
                        secure: true,
                        sameSite: 'Lax',
                    });
                } catch (error) {
                    const errorResult = error as any;
                    const errors = errorResult?.error?.data?.errors;

                    if (errors[0]?.id === errorDictionary.CLIENT_IS_DEMO) {
                        trackUserEvent(
                            'Auth Page',
                            'Submit',
                            'Username Password Send',
                            credentials.queryRedirectParams.client_id,
                            'Send',
                        );
                        redirectTo(Routes.DIRECT_DEMO_USER);
                    } else {
                        let errorMessage = '';

                        if (errors[0]?.base_id) {
                            errorMessage = errors[0]?.message?.en;
                        } else {
                            errorMessage =
                                errors[0]?.detail || errors[0]?.title || errors[0]?.message || '';
                        }
                        trackUserEvent(
                            'Auth Page',
                            'Submit',
                            'Username Password Send',
                            credentials.queryRedirectParams.client_id,
                            `Error: ${errorMessage}`,
                        );

                        dispatch(authByLoginRejected(errors));
                    }
                }
            },
        }),
    }),
});
export const { useRequestGetTempPasswordAlboMutation, useRequestOIDAuthByLoginMutation } =
    authorizationApi;
