import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { createApi } from '@reduxjs/toolkit/query/react';
import uuid from 'uuid';

import errorDictionary from '#/src/shared/error-dictionary';
import { sendMetrics } from '#/src/shared/lib/analitycs';
import { checkBrowserSecret } from '#/src/shared/lib/check-browser-secret';
import { checkPincodeParams } from '#/src/shared/lib/check-pincode-pararms';
import { clientErrorLog, clientInfoLog } from '#/src/shared/lib/client-logger';
import { declination } from '#/src/shared/lib/declination';
import { encryptBrowserSecret } from '#/src/shared/lib/encrypt-browser-secret';
import { expiresDate } from '#/src/shared/lib/expires-date';
import getCookie from '#/src/shared/lib/get-cookie';
import { getMetricsData } from '#/src/shared/lib/get-metrics-data';
import getQueryParams from '#/src/shared/lib/get-query-params';
import { redirectTo } from '#/src/shared/lib/redirect';
import { rsaEncrypt } from '#/src/shared/lib/rsa-encrypt';
import { setBrowserSecret } from '#/src/shared/lib/set-browser-secret';
import { initFP } from '#/src/shared/lib/sinc-fingerprint';
import { removeCookie, setCookie } from '#/src/shared/lib/update-cookie';
import { CookiesName, FormStatus, LocalStorageName, Routes } from '#/src/shared/models';
import { customFetchBaseQuery } from '#/src/shared/store/base-query';
import { selectPincode, selectPincodeNonce, selectServerPublicKey } from '#/src/shared/store/pincode/selectors';
import { pincodeErrorSet, pincodeUpdated } from '#/src/shared/store/pincode/slice';
import {
    getQueryRedirectParams,
    selectClientId,
    selectIsMobile,
    selectMetricsDebugEnabled,
    selectMfaToken,
    selectNonce,
    selectRedirectURI,
    selectScope,
    selectState,
} from '#/src/shared/store/redux/app/selectors';
import { selectFingerPrintCredentials } from '#/src/shared/store/redux/fingerprint/selectors';
import { ApplicationState } from '#/src/shared/store/types';
import {
    PincodeActivateCredentials,
    PincodeActivateResponse,
    PincodeAuthorizationCredentials,
    PincodeFinishAuthorizationCredentials,
    PincodeFinishAuthorizationResponse,
    RetryOptions,
} from '#/src/shared/types/interfaces';
import { Endpoint, HttpMethod, SUB_ALFABANK_DOMAIN } from '#/src/shared/utils';

import { preloaderHidden, preloaderShown, setMultifactorResponse } from '../redux/app/slice';

/** Функция инкапсулирующая логику формирования параметров запроса. */
const getActivateCredentials = (state: ApplicationState): PincodeActivateCredentials => {
    const deviceAppId = getCookie(CookiesName.deviceAppId) || '';
    const browserId = getCookie(CookiesName.browserId) || '';
    const clientId = selectClientId(state);
    const scope = selectScope(state);

    return {
        acrValues: 'pincode',
        browserId,
        clientId,
        deviceAppId,
        enroll: 'pincode',
        scope,
    };
};

/** Функция шифрования пинкода */
const encryptPasscode = async (state: ApplicationState): Promise<string> => {
    const code = selectPincode(state);
    const serverPublicKey = selectServerPublicKey(state);
    const nonce = selectPincodeNonce(state);

    return rsaEncrypt(code, serverPublicKey, nonce);
};

/** Функция инкапсулирующая логику формирования параметров запроса. */
const getAuthorizationCredentials = async (
    state: ApplicationState,
): Promise<PincodeAuthorizationCredentials> => {
    const deviceAppId = getCookie(CookiesName.deviceAppId) || '';
    const browserId = getCookie(CookiesName.browserId) || '';
    const clientId = selectClientId(state);
    const redirectURI = selectRedirectURI(state);
    const queryState = selectState(state);
    const nonce = selectNonce(state);
    const passcodeEnc = await encryptPasscode(state);
    const scope = selectScope(state);

    return {
        acrValues: 'pincode',
        browserId,
        clientId,
        deviceAppId,
        enroll: 'pincode',
        passcodeEnc,
        scope,
        redirectURI,
        nonce,
        state: queryState,
    };
};

const NOT_FOUND_MESSAGE = '404 Not Found';

export const pincodeApi = createApi({
    reducerPath: 'pincodeApi',
    baseQuery: customFetchBaseQuery({ customRedirect: true }),
    endpoints: (build) => ({
        pincodeActivate: build.mutation<PincodeActivateResponse, void>({
            extraOptions: { maxRetries: 2 },
            queryFn: async (_payload, queryApi, _extraOptions, baseQuery) => {
                const state = queryApi.getState() as ApplicationState;
                const { dispatch } = queryApi;

                const body = getActivateCredentials(state);

                const queryRedirectParams = getQueryRedirectParams(state);
                const isMobile =  selectIsMobile(state);
                const metricsData = getMetricsData(queryRedirectParams, isMobile);
                const isMetricsDebugEnabled = selectMetricsDebugEnabled(state);

                sendMetrics('Pincode activate', 'Request', metricsData, isMetricsDebugEnabled)

                const response = (await baseQuery({
                    url: Endpoint.PINCODE_ACTIVATE,
                    method: HttpMethod.POST,
                    body,
                })) as QueryReturnValue<{ data: PincodeActivateResponse }, FetchBaseQueryError>;

                if (response.error) {
                    const errorData = response.error.data as any;
                    const [error] = errorData.errors;

                    metricsData.status = error?.status;
                    metricsData.error = error?.message;

                    sendMetrics('Pincode activate', 'Response', metricsData, isMetricsDebugEnabled)

                    if (error?.status >= 400 && error?.status <= 599) {
                        if (
                            error.status === 500 &&
                            error?.message.toLowerCase().includes(NOT_FOUND_MESSAGE.toLowerCase())
                        ) {
                            removeCookie(CookiesName.authTypePincode, {
                                domain: SUB_ALFABANK_DOMAIN,
                                path: '/',
                            });
                        }

                        const id = error?.id;

                        switch (id) {
                            case 'NO_ATTEMPTS_LEFT':
                                setCookie(CookiesName.forgottenPasscode, 'true', {
                                    domain: SUB_ALFABANK_DOMAIN,
                                    path: '/',
                                    expires: expiresDate(7),
                                });
                                clientInfoLog('forgottenPasscode set');
                                break;

                            case 'PINCODE_ERROR_ACTIVATE':
                                window.localStorage.removeItem(LocalStorageName.browserSecret);
                                window.localStorage.removeItem(LocalStorageName.browserSecretDate);
                                clientInfoLog(
                                    `PincodeActivate error ${error?.status} ${error?.id} - remove browserSecret and browserSecretDate`,
                                );
                                break;
                        }

                        dispatch(pincodeErrorSet(errorDictionary.PINCODE_ACTIVATION_IS_FAILED));

                        const { browserSecretPublicKey } = state.App;

                        const newBrowserSecretData = await checkBrowserSecret(
                            browserSecretPublicKey,
                        );

                        if (newBrowserSecretData) {
                            dispatch(pincodeUpdated(newBrowserSecretData));
                        }
                    }

                    return { error: response.error };
                }

                metricsData.status = '200';

                sendMetrics('Pincode activate', 'Response', metricsData, isMetricsDebugEnabled)

                dispatch(pincodeUpdated(response.data.data));

                return { data: response.data.data };
            },
        }),

        pincodeAuthorization: build.mutation<
            PincodeFinishAuthorizationResponse,
            RetryOptions | void
        >({
            queryFn: async (args, queryApi, _extraOptions, baseQuery) => {
                const state = queryApi.getState() as ApplicationState;
                const { dispatch } = queryApi;

                const body = await getAuthorizationCredentials(state);

                const queryRedirectParams = getQueryRedirectParams(state);
                const isMobile =  selectIsMobile(state);
                const metricsData = getMetricsData(queryRedirectParams, isMobile);
                const isMetricsDebugEnabled = selectMetricsDebugEnabled(state);

                sendMetrics('Pincode authorization', 'Request', metricsData, isMetricsDebugEnabled)

                dispatch(pincodeUpdated({ formStatus: FormStatus.SubmitProcess }));

                const response = (await baseQuery({
                    url: Endpoint.PINCODE_AUTHORIZATION,
                    method: HttpMethod.POST,
                    body,
                })) as QueryReturnValue<PincodeFinishAuthorizationResponse, FetchBaseQueryError>;

                if (response.data?.redirectUrl) {
                    const params = getQueryParams(response.data.redirectUrl);

                    dispatch(preloaderShown());
                    dispatch(setMultifactorResponse({ url: response.data.redirectUrl, params }));
                    dispatch(pincodeApi.endpoints.pincodeFinishAuthorization.initiate());
                }

                if (response.error) {
                    const errorResult = response.error.data as any;
                    const error = errorResult.errors[0];
                    const errorData = error?.data;
                    const retryCount = args ? args.retryCount : 1;
                    const errorStatus = Number(response.error.status);
                    const attemptsLeft = errorData?.attemptsLeft;
                    const message = errorData?.message;

                    metricsData.status = `${errorStatus}`;
                    metricsData.error = message;

                    sendMetrics('Pincode authorization', 'Response', metricsData, isMetricsDebugEnabled)

                    if (error?.id === 'PINCODE_ERROR_VERIFY') {
                        window.localStorage.removeItem(LocalStorageName.browserSecret);
                        window.localStorage.removeItem(LocalStorageName.browserSecretDate);
                        clientInfoLog(
                            `PincodeFinishAuthorization error ${response.error?.status} ${error?.id} - remove browserSecret and browserSecretDate`,
                        );
                    }

                    if (retryCount && errorStatus >= 500 && errorStatus <= 599) {
                        await dispatch(pincodeApi.endpoints.pincodeActivate.initiate());
                        await dispatch(
                            pincodeApi.endpoints.pincodeAuthorization.initiate({
                                retryCount: retryCount - 1,
                            }),
                        );

                        return { error: response.error };
                    }

                    dispatch(
                        pincodeUpdated({
                            formStatus: FormStatus.SubmitError,
                            code: '',
                            nonce: errorData?.nonce ? errorData.nonce : '',
                        }),
                    );

                    if (attemptsLeft) {
                        dispatch(
                            pincodeErrorSet(
                                `Неверный код. Осталось ${attemptsLeft} ${declination(
                                    attemptsLeft,
                                    ['попытка', 'попытки', 'попыток'],
                                )}`,
                            ),
                        );
                        dispatch(pincodeUpdated({ attemptsLeft, showError: true }));
                    } else if (attemptsLeft === 0) {
                        setCookie(CookiesName.forgottenPasscode, 'true', {
                            domain: SUB_ALFABANK_DOMAIN,
                            path: '/',
                            expires: expiresDate(7),
                        });
                        await clientInfoLog('forgottenPasscode set');
                        dispatch(
                            pincodeUpdated({
                                attemptsLeft: 0,
                                message,
                            }),
                        );
                        dispatch(pincodeErrorSet(errorResult.errors[0].message));
                    } else {
                        dispatch(pincodeErrorSet(errorDictionary.DEFAULT));
                    }

                    return { error: response.error };
                }

                metricsData.status = '200';

                sendMetrics('Pincode authorization', 'Response', metricsData, isMetricsDebugEnabled)

                dispatch(pincodeUpdated({ formStatus: FormStatus.SubmitSuccess, nonce: '' }));

                return { data: response.data };
            },
        }),

        pincodeFinishAuthorization: build.mutation<
            PincodeFinishAuthorizationResponse,
            RetryOptions | void
        >({
            queryFn: async (args, queryApi, _extraOptions, baseQuery) => {
                const { dispatch } = queryApi;

                await initFP(queryApi);

                const state = queryApi.getState() as ApplicationState;

                const queryRedirectParams = getQueryRedirectParams(state);
                const isMobile =  selectIsMobile(state);
                const metricsData = getMetricsData(queryRedirectParams, isMobile);
                const isMetricsDebugEnabled = selectMetricsDebugEnabled(state);

                sendMetrics('Pincode finish authorization', 'Request', metricsData, isMetricsDebugEnabled)

                const fingerprint = selectFingerPrintCredentials(state);
                const browserId = getCookie(CookiesName.browserId) || '';

                // TODO START Добавить новые шифрованные параметры. - PASSPORT-8000
                const publicKey = state?.App.browserSecretPublicKey;

                const currBrowserSecret = localStorage.getItem(LocalStorageName.browserSecret);
                const currBrowserSecretDate = localStorage.getItem(
                    LocalStorageName.browserSecretDate,
                );
                const newBrowserSecret = `${uuid.v4()}${uuid.v4()}`;

                const newBrowserSecretEncrypted = await encryptBrowserSecret(
                    `${newBrowserSecret}#${uuid.v4()}`,
                    publicKey,
                );
                const currBrowserSecretEncrypted = await encryptBrowserSecret(
                    `${currBrowserSecret}#${uuid.v4()}`,
                    publicKey,
                );
                /** TODO END */

                const clientId = selectClientId(state);

                const logMessage = checkPincodeParams(
                    browserId,
                    currBrowserSecret,
                    currBrowserSecretDate,
                    newBrowserSecretEncrypted,
                    clientId,
                );

                await clientInfoLog(logMessage);

                const body: PincodeFinishAuthorizationCredentials = {
                    new_browser_secret_enc: newBrowserSecretEncrypted,
                    current_browser_secret_enc: currBrowserSecretEncrypted,
                    browser_secret_date: currBrowserSecretDate,
                    enroll: 'signature',
                    mfaToken: selectMfaToken(state),
                    browserId,
                    clientId: selectClientId(state),
                    redirectURI: selectRedirectURI(state),
                    state: selectState(state),
                    nonce: selectNonce(state),
                    acrValues: 'pincode',
                    scope: selectScope(state),
                    ...fingerprint,
                };
                const response = (await baseQuery({
                    url: Endpoint.PINCODE_FINISH_AUTHORIZATION,
                    method: HttpMethod.POST,
                    body,
                })) as QueryReturnValue<PincodeFinishAuthorizationResponse, FetchBaseQueryError>;

                if (response.error) {
                    const errorResult = response.error.data as any;
                    const error = errorResult.errors[0];

                    await clientErrorLog(error);

                    const retryCount = args ? args.retryCount : 1;
                    const errorStatus = Number(response.error.status);

                    metricsData.status = `${errorStatus}`;
                    metricsData.error = error?.message;

                    sendMetrics('Pincode finish authorization', 'Response', metricsData, isMetricsDebugEnabled)

                    if (retryCount && errorStatus >= 500 && errorStatus <= 599) {
                        await dispatch(
                            pincodeApi.endpoints.pincodeFinishAuthorization.initiate({
                                retryCount: retryCount - 1,
                            }),
                        );
                        dispatch(preloaderHidden());

                        return { error: response.error };
                    }

                    if (error?.id === 'PINCODE_ERROR_SIGNATURE') {
                        window.localStorage.removeItem(LocalStorageName.browserSecret);
                        window.localStorage.removeItem(LocalStorageName.browserSecretDate);
                        clientInfoLog(
                            `PincodeFinishAuthorization error ${error?.status} ${error?.id} - remove browserSecret and browserSecretDate`,
                        );
                    }

                    dispatch(pincodeUpdated({ attemptsLeft: 0 }));
                    redirectTo(Routes.PINCODE);

                    dispatch(preloaderHidden());

                    return { error: response.error };
                }

                metricsData.status = '200';

                sendMetrics('Pincode finish authorization', 'Response', metricsData, isMetricsDebugEnabled)


                if (response.data.redirectUrl) {
                    if (/\/sms/.test(response.data.redirectUrl)) {
                        dispatch(
                            pincodeUpdated({
                                newBrowserSecret,
                                newBrowserSecretEnc: newBrowserSecretEncrypted,
                            }),
                        );
                    } else if (newBrowserSecretEncrypted) {
                        setBrowserSecret(newBrowserSecret);
                    }

                    removeCookie(CookiesName.forgottenPasscode, {
                        domain: SUB_ALFABANK_DOMAIN,
                        path: '/',
                    });

                    const params = getQueryParams(response.data.redirectUrl);

                    dispatch(setMultifactorResponse({ url: response.data.redirectUrl, params }));
                    redirectTo(response.data.redirectUrl);
                }

                dispatch(preloaderHidden());

                return { data: response.data };
            },
        }),
    }),
});

export const {
    usePincodeActivateMutation,
    usePincodeAuthorizationMutation,
    usePincodeFinishAuthorizationMutation,
} = pincodeApi;
