import React from 'react';
import { openSidePanel } from '@alfa-bank/newclick-core/window-utils';
import { OpenSidePanelsParams } from '@alfa-bank/newclick-types/open-side-panel-params';

import { IconButton } from '@alfalab/core-components/icon-button';
import { InformationCircleLineMIcon } from '@alfalab/icons-glyph/InformationCircleLineMIcon';

export const AlternativeLoginButton = () => (
    <IconButton
        dataTestId='inform-button'
        icon={InformationCircleLineMIcon}
        onClick={() =>
            openSidePanel({
                sidePanelId: 'Inform',
            } as unknown as OpenSidePanelsParams)
        }
    />
);
