import React from 'react';

import PasswordBrowser from '#/src/shared/components/password/password-browser/password-browser';
import DesignCheck from '#/src/shared/components/ui/design-check';

import Password from '../../components/password/password-old';

export default () => (
    <DesignCheck
        defaultComponent={<PasswordBrowser />}
        defaultMobileComponent={<PasswordBrowser />}
        mobileComponent={<Password />}
        corpComponent={<Password />}
    />
);
