import { FC, useEffect } from 'react';

import { useRequestJwtAuthorizationMutation } from '#/src/shared/store/api/jwt-api';

const JWTAuth: FC = () => {
    const [requestJwtAuthorization] = useRequestJwtAuthorizationMutation();

    useEffect(() => {
        if (typeof window !== 'undefined') {
            requestJwtAuthorization();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default JWTAuth;
