import { createSelector } from 'reselect';

import getCookie from '#/src/shared/lib/get-cookie';
import { isBrowserEnv } from '#/src/shared/lib/is-browser-env';
import { removeCookie } from '#/src/shared/lib/update-cookie';
import { AuthType, CookiesName } from '#/src/shared/models';
import {
    isClickDesign as selectIsClickDesign,
    selectIsAKeyEnabled,
    selectIsAlfaId,
    selectIsMobile,
} from '#/src/shared/store/redux/app/selectors';
import { ApplicationState } from '#/src/shared/store/types';

import {
    selectAuthorizationAuthType,
    selectAuthorizationPcUserId,
} from '../authorization/selectors';

export const getAkeyState = (state: ApplicationState) => state.AKeyAuthorization;

export const selectAkeyDeeplink = createSelector(
    [getAkeyState],
    (AKeyAuthorization) => AKeyAuthorization.deeplink,
);
export const selectAkeyError = createSelector(
    [getAkeyState],
    (AKeyAuthorization) => AKeyAuthorization.error,
);
/** Получить значение куки authType. */
export const getAuthType = (): string => {
    // Скрываем на время SSR
    if (typeof document === 'undefined') return '';
    const authType = getCookie(CookiesName.authType);

    return authType || '';
};

/** Получить значение куки pcUserID. */
export const getPcUserId = (): string => {
    // Скрываем на время SSR
    if (typeof document === 'undefined') return '';
    const pcUserID = getCookie(CookiesName.pcUserID);

    return pcUserID || '';
};

/** Функция получения флага доступности AKey для пользователя. */
export const selectIsAKeyAvailable = createSelector(
    [
        selectIsClickDesign,
        selectAuthorizationAuthType,
        selectAuthorizationPcUserId,
        selectIsMobile,
        selectIsAKeyEnabled,
        selectIsAlfaId,
    ],
    (isClickDesign, authType, pcUserId, isMobile, isAKeyEnabled, isAlfaId) => {
        const isPWA = isBrowserEnv && window.matchMedia('(display-mode: standalone)').matches;

        if (isPWA) {
            removeCookie(CookiesName.authType);
            removeCookie(CookiesName.pcUserID);

            return false;
        }
        const isAKeyAuthType =
            authType === AuthType.AKey ||
            authType === AuthType.Capsule ||
            authType === AuthType.Voskhod ||
            authType === AuthType.VoskhodApp;

        return Boolean(
            isAKeyEnabled && isMobile && pcUserId && isAKeyAuthType && isClickDesign && !isAlfaId,
        );
    },
);
