import { clientErrorLog } from '#/src/shared/lib/client-logger';

let animationId: number;

const animate = (canvas: HTMLCanvasElement, video: HTMLVideoElement) => {
    canvas
        .getContext('2d', { alpha: false, willReadFrequently: true })
        ?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

    animationId = requestAnimationFrame(() => animate(canvas, video));
};

export const cameraInit = async (
    stream: MediaStream,
    canvasRef: React.RefObject<HTMLCanvasElement>,
    videoRef: React.RefObject<HTMLVideoElement>,
) => {
    const video = videoRef.current;

    if (video) {
        video.srcObject = stream;
        try {
            await video.play();
            const { videoWidth, videoHeight } = video;

            const canvas = canvasRef.current;

            if (canvas) {
                canvas.width = videoWidth;
                canvas.height = videoHeight;

                animate(canvas, video);
            }
        } catch (error) {
            const err = new Error(`Camera init error: ${error}`)

            clientErrorLog(err)
        }
    }
};

export const cancelAnimation = () => {
    cancelAnimationFrame(animationId);
};
