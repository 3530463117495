import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { useAppDispatch, useAppSelector } from '#/src/shared/hooks';
import { trackUserEvent } from '#/src/shared/lib/analitycs';
import { selectClientId } from '#/src/shared/store/redux/app/selectors';
import { isAltAuthModalOpenSet } from '#/src/shared/store/redux/passcode/slice';

import { AUTH_TYPE_PROPS, AuthCellBase, AuthType } from './auth-cell-base';

type Props = {
    authType: AuthType;
    onSpecialClick?: () => void;
};

export const AuthCellMobile: FC<Props> = ({ authType, onSpecialClick }) => {
    const { route, typeEvent } = AUTH_TYPE_PROPS[authType];
    const navigate = useNavigate();
    const { search } = useLocation();
    const dispatch = useAppDispatch();

    const clientId = useAppSelector(selectClientId);

    const onClick = () => {
        if (onSpecialClick) {
            onSpecialClick();
        } else {
            navigate({
                pathname: route,
                search,
            });
        }
        trackUserEvent('Auth Page', 'Click', typeEvent, clientId);
        dispatch(isAltAuthModalOpenSet(false));
    };

    return <AuthCellBase authType={authType} onClick={onClick} />;
};
