import React from 'react';

type Props = {
    children: React.ReactElement;
};

class ErrorBoundary extends React.PureComponent<Props> {
    state = { error: false };

    componentDidCatch() {
        this.setState({
            error: true,
        });
    }

    render() {
        if (this.state.error) {
            return null;
        }

        return this.props.children;
    }
}

export { ErrorBoundary };
