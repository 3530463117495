import { createApi } from '@reduxjs/toolkit/query/react';

import errorDictionary from '#/src/shared/error-dictionary';
import { checkPincodeParams } from '#/src/shared/lib/check-pincode-pararms';
import { clientInfoLog } from '#/src/shared/lib/client-logger';
import getCookie from '#/src/shared/lib/get-cookie';
import { redirectTo } from '#/src/shared/lib/redirect';
import { setBrowserSecret } from '#/src/shared/lib/set-browser-secret';
import { initFP } from '#/src/shared/lib/sinc-fingerprint';
import { AcrValues, AuthType, CookiesName, LocalStorageName, Routes } from '#/src/shared/models';
import { customFetchBaseQuery } from '#/src/shared/store/base-query';
import { getAuthType } from '#/src/shared/store/redux/akey/selectors';
import { akeyDeeplinkSet, akeyErrorSet } from '#/src/shared/store/redux/akey/slice';
import { akeyStatusSet } from '#/src/shared/store/redux/app/slice';
import { selectFingerPrintCredentials } from '#/src/shared/store/redux/fingerprint/selectors';
import { ApplicationState } from '#/src/shared/store/types';
import { AKeyAuthCredentials } from '#/src/shared/types/interfaces';
import { Endpoint, HttpMethod } from '#/src/shared/utils';
import { getInitializeParams } from '#/src/shared/utils/get-initialize-params';

import {
    getQueryRedirectParams,
    selectAKeyTimeout,
    selectClientId,
    selectNonce,
    selectRedirectURI,
    selectState,
} from '../../redux/app/selectors';

export type DeeplinkResponse = {
    app_start_deeplink: string;
};

export const akeyApi = createApi({
    reducerPath: 'akeyApi',
    baseQuery: customFetchBaseQuery(),
    endpoints: (build) => ({
        getAkeyDeeplink: build.mutation<DeeplinkResponse, string | void>({
            queryFn: async (timeout, queryApi, _extraOptions, fetchWithBQ) => {
                const { dispatch } = queryApi;

                await initFP(queryApi);

                const state = queryApi.getState() as ApplicationState;

                const queryRedirectParams = getQueryRedirectParams(state);
                const akeyTimeout = selectAKeyTimeout(state);

                const params = getInitializeParams(queryRedirectParams, AcrValues.akey);
                const fingerprint = selectFingerPrintCredentials(state);
                const browserId = getCookie(CookiesName.browserId);

                const body = {
                    ...params,
                    ...fingerprint,
                    browser_id: browserId,
                };

                const result = await fetchWithBQ({
                    url: Endpoint.OID_INITIALIZE,
                    method: HttpMethod.POST,
                    body,
                    timeout: (timeout ? +timeout : akeyTimeout) - 1_000,
                });

                if (result.error) {
                    return { error: result.error };
                }

                const resultData = result.data as DeeplinkResponse;

                dispatch(akeyDeeplinkSet(resultData.app_start_deeplink));

                return { data: resultData };
            },
        }),
        requestAkeyDeeplink: build.mutation<DeeplinkResponse, string | void>({
            queryFn: async (timeout, queryApi) => {
                const { dispatch } = queryApi;
                const authType = getAuthType();

                const result: any = await dispatch(
                    akeyApi.endpoints.getAkeyDeeplink.initiate(timeout),
                );

                if (result.error) {
                    dispatch(akeyErrorSet(errorDictionary.AKEY_AUTH_ERROR));
                    dispatch(akeyStatusSet(false));
                    redirectTo(Routes.PHONE_AUTH);

                    return { error: result.error };
                }

                if (authType === AuthType.Capsule || authType === AuthType.VoskhodApp) {
                    window.location.replace(result.data.app_start_deeplink);
                }

                return { data: result.data };
            },
        }),
        akeyAuthorization: build.mutation<any, AKeyAuthCredentials>({
            queryFn: async (payload, queryApi, _extraOptions, fetchWithBQ) => {
                const { dispatch } = queryApi;

                await initFP(queryApi);

                const state = queryApi.getState() as ApplicationState;
                const authType = (() => {
                    const authTypeCookie = getAuthType();

                    if (
                        authTypeCookie === AuthType.Voskhod ||
                        authTypeCookie === AuthType.VoskhodApp
                    ) {
                        return AuthType.Voskhod;
                    }

                    return authTypeCookie;
                })();
                const fingerprint = selectFingerPrintCredentials(state);

                const newBrowserSecret = state?.Pincode?.newBrowserSecret;
                const newBrowserSecretEnc = state?.Pincode?.newBrowserSecretEnc;
                const browserId = getCookie(CookiesName.browserId);
                const browserSecret = window?.localStorage?.getItem(LocalStorageName.browserSecret);
                const browserSecretDate = window?.localStorage?.getItem(
                    LocalStorageName.browserSecretDate,
                );
                const clientId = selectClientId(state);
                const redirectURI = selectRedirectURI(state);
                const queryState = selectState(state);
                const nonce = selectNonce(state);

                const logMessage = checkPincodeParams(
                    browserId,
                    browserSecret,
                    browserSecretDate,
                    newBrowserSecretEnc,
                    clientId,
                );

                await clientInfoLog(logMessage);

                const body: AKeyAuthCredentials = {
                    ...payload,
                    ...fingerprint,
                    auth_type: authType,
                    new_browser_secret_enc: newBrowserSecretEnc,
                    browser_secret_date: browserSecretDate,
                    redirectURI,
                    nonce,
                    state: queryState,
                };

                const result = await fetchWithBQ({
                    url: Endpoint.OID_AKEY_AUTH,
                    method: HttpMethod.POST,
                    body,
                });

                if (result.error) {
                    dispatch(akeyErrorSet(errorDictionary.AKEY_AUTH_ERROR));
                    redirectTo(Routes.PHONE_AUTH);

                    return { error: result.error };
                }

                if (newBrowserSecretEnc) {
                    setBrowserSecret(newBrowserSecret);
                }

                return { data: result.data };
            },
        }),
    }),
});
export const {
    useGetAkeyDeeplinkMutation,
    useRequestAkeyDeeplinkMutation,
    useAkeyAuthorizationMutation,
} = akeyApi;
