import React, { ChangeEvent, useEffect, useState } from 'react';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { Link } from '@alfalab/core-components/link';
import { MaskedInput } from '@alfalab/core-components/masked-input';
import { Typography } from '@alfalab/core-components/typography';
import Form from 'arui-feather/form';
import FormField from 'arui-feather/form-field';

import { useAppDispatch, useAppSelector } from '#/src/shared/hooks';
import { trackUserEvent } from '#/src/shared/lib/analitycs';
import { PHONE_INPUT_MIN_LENGTH_RU } from '#/src/shared/lib/form-controls-const';
import { clearFormatting, formatPhoneNumber } from '#/src/shared/lib/formatters';
import { phoneNumberValidate } from '#/src/shared/lib/validators';
import { FormStatus, RegistrationType } from '#/src/shared/models';
import { useRequestPhoneRegistrationMutation } from '#/src/shared/store/api/registration-api';
import { getQueryRedirectParams, selectIsX5Web } from '#/src/shared/store/redux/app/selectors';
import {
    getRegistrationFormStatus,
    getRegistrationPhone,
    getRegistrationServerErrors,
    phoneAuthErrorMessage,
} from '#/src/shared/store/redux/registration/selectors';
import {
    phoneRegistrationSubmit,
    registrationFormUpdated,
    registrationServerErrorsCleared,
    registrationTypeUpdated,
} from '#/src/shared/store/redux/registration/slice';

import './x5-web-phone-auth.css';

const cn = createCn('x5-web-form-basic');

// prettier-ignore
const maskPhoneNumber = [
    /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/,
];

const X5WebPhoneAuth: React.FC = () => {
    const dispatch = useAppDispatch();
    const [requestPhoneRegistration] = useRequestPhoneRegistrationMutation();

    const phone = useAppSelector(getRegistrationPhone);
    const formStatus = useAppSelector(getRegistrationFormStatus);
    const serverErrors = useAppSelector(getRegistrationServerErrors);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const errorMessage = useAppSelector(phoneAuthErrorMessage);
    const isX5Web = useAppSelector(selectIsX5Web);

    const [firstKeyPush, setFirstKeyPush] = useState(true);

    useEffect(
        () => {
            const isPhoneValid = phoneNumberValidate(queryRedirectParams.phone, {
                withLength: true,
            });

            dispatch(registrationTypeUpdated(RegistrationType.Phone));
            if (isPhoneValid) {
                dispatch(
                    registrationFormUpdated({
                        phone: formatPhoneNumber(queryRedirectParams.phone),
                    }),
                );
                requestPhoneRegistration();
                trackUserEvent(
                    'Auth Page',
                    'Impression',
                    'Viewing Page',
                    queryRedirectParams.client_id,
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    useEffect(() => {
        if (formStatus === FormStatus.ValidationSuccess) {
            requestPhoneRegistration();
        }

        if (errorMessage) {
            trackUserEvent(
                'Auth Page',
                'Click',
                'Phone Send',
                queryRedirectParams.client_id,
                `Error: ${errorMessage}`,
            );
        }
    }, [
        requestPhoneRegistration,
        errorMessage,
        formStatus,
        queryRedirectParams.client_id,
    ]);

    const handleSubmit = (event: React.FormEvent<any> | undefined) => {
        event?.preventDefault();

        trackUserEvent(
            'Auth Page',
            'Click',
            'Phone Send',
            queryRedirectParams.client_id,
            'Sending',
        );
        dispatch(
            phoneRegistrationSubmit({
                type: RegistrationType.Phone,
                phone,
            }),
        );

        if (formStatus === FormStatus.ValidationSuccess) {
            dispatch(requestPhoneRegistration());
        }
    };

    const handlePhoneChange = (receivedPhone: string) => {
        if (!firstKeyPush) {
            trackUserEvent(
                'Auth Page',
                'Field Change',
                'Enter Phone Number',
                queryRedirectParams.client_id,
            );
            setFirstKeyPush(true);
        }
        dispatch(registrationFormUpdated({ phone: `+7 ${receivedPhone.trimEnd()}` }));
        dispatch(registrationServerErrorsCleared());
    };

    const isSubmitButtonDisabled = () => {
        const phoneNumber = clearFormatting(phone);

        if (!phoneNumber) return true;

        return phoneNumber.length < PHONE_INPUT_MIN_LENGTH_RU || !!errorMessage;
    };

    const supportPhone = (
        <Link underline={false} href='tel:88001003100' view='primary' rel='noopener' target='_self'>
            8 800 100 31 00
        </Link>
    );

    return (
        <Form className={cn({ flex: 'space-between', cutScreen: isX5Web })} onSubmit={handleSubmit}>
            <div className={cn('content')}>
                <Typography.Text tag='p' view='primary-medium' weight='medium'>
                    Личный кабинет Х5 Карты
                </Typography.Text>
                <Gap size='l' />
                <Typography.Title
                    className={cn('title')}
                    tag='h2'
                    view='xsmall'
                    font='styrene'
                    weight='bold'
                >
                    Введите номер телефона
                </Typography.Title>
                <Gap size='m' />

                <FormField className={cn('phone-field-wrapper')}>
                    <div className={cn('custom-phone-input')}>
                        <MaskedInput
                            className={cn('phone-field')}
                            mask={maskPhoneNumber}
                            value={phone.replace('+7', '')}
                            type='tel'
                            placeholder='900 123 45 67'
                            block={true}
                            clear={true}
                            inputClassName={cn('phone-field')}
                            onClear={() => dispatch(registrationFormUpdated({ phone: '' }))}
                            onChange={(
                                event: ChangeEvent<HTMLInputElement>,
                                { value }: { value: string },
                            ) => handlePhoneChange(value)}
                            leftAddons={
                                <Typography.Text tag='span' view='primary-medium' weight='bold'>
                                    +7
                                </Typography.Text>
                            }
                            addonsClassName={cn('phone-addons')}
                        />
                    </div>
                    {serverErrors.length > 0 && (
                        <div className={cn('error-block')}>{serverErrors[0].message}</div>
                    )}
                </FormField>

                <Gap size='l' />
                <Button
                    block={true}
                    className={cn('button')}
                    type='submit'
                    view='primary'
                    disabled={isSubmitButtonDisabled()}
                    loading={formStatus === FormStatus.SubmitProcess}
                >
                    Продолжить
                </Button>
                <Gap size='m' />
                <Typography.Text view='primary-small'>
                    При возникновении вопросов по входу <br />
                    в личный кабинет или X5 Карте: <br />
                    {supportPhone}
                </Typography.Text>
            </div>
        </Form>
    );
};

export default X5WebPhoneAuth;
