// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { clientErrorLog } from '#/src/shared/lib/client-logger';
import { isBrowserEnv } from '#/src/shared/lib/is-browser-env';

/* eslint-disable import/prefer-default-export */

export function init(): void {
    if (isBrowserEnv) {
        return;
    }

    window.onerror = async function onErrorLog(_event, _source, _lineno, _colno, error) {
        if (error) {
            await clientErrorLog(error);
        }
    };
}

/* eslint-enable import/prefer-default-export */
