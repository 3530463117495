import React from 'react';

import DesignCheck from '#/src/shared/components/ui/design-check';

import CardAccount from '../../components/card-account/card-account';
import CardAccountBrowser from '../../components/card-account/card-account-browser';
import NewCardAccount from '../../components/card-account/card-account-new';
import MessengerCardAccount from '../../components/card-account/messenger-card-account';
import X5WebCardAccount from '../../components/card-account/x5-web-card-account';

export default () => (
    <DesignCheck
        defaultComponent={<CardAccountBrowser />}
        defaultMobileComponent={<CardAccountBrowser />}
        mobileComponent={<NewCardAccount />}
        corpComponent={<CardAccount />}
        bankMessengerComponent={<MessengerCardAccount />}
        X5WebComponent={<X5WebCardAccount />}
    />
);
