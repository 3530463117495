import { createSelector } from 'reselect';

import { validatePhoneNumber } from '#/src/shared/lib/client-validation/registration';
import { TEMP_PASS_MAX_LENGTH } from '#/src/shared/lib/form-controls-const';
import { clearFormatting } from '#/src/shared/lib/formatters';
import { ServerErrors } from '#/src/shared/models';
import { getQueryRedirectParams } from '#/src/shared/store/redux/app/selectors';
import { ApplicationState } from '#/src/shared/store/types';

import { getRegistrationPhone } from '../registration/selectors';

export const selectTempPasswordState = (state: ApplicationState) => state.TempPasswordAuthorization;

export const selectTempPassword = createSelector(
    [selectTempPasswordState],
    (password) => password.tempPassword,
);

export const selectTempPasswordAttemptsLeft = createSelector(
    [selectTempPasswordState],
    (password) => password.attemptsLeft,
);

export const selectTempPasswordFormStatus = createSelector(
    [selectTempPasswordState],
    (tempPassword) => tempPassword.formStatus,
);
export const selectIsTempPasswordError = createSelector(
    [selectTempPasswordState],
    (password) => password.error,
);
export const isTempPasswordButtonDisabled = createSelector(
    [selectTempPassword, selectIsTempPasswordError],
    (password, error) => Boolean(password.length < TEMP_PASS_MAX_LENGTH || error),
);

export const selectTempPasswordServerErrorId = createSelector(
    [selectTempPasswordState],
    (password) => password.serverErrorId,
);

export const selectTempPasswordErrorPage = createSelector(
    [selectTempPasswordAttemptsLeft, selectTempPasswordServerErrorId],
    (attemptsLeft, serverErrorId) =>
        (attemptsLeft === 0 &&
            serverErrorId === ServerErrors.TEMP_PASSWORD_VERIFICATION_IS_FAILED) ||
        serverErrorId === ServerErrors.TEMP_PASSWORD_IS_NOT_SET ||
        serverErrorId === ServerErrors.CHANNEL_BLOCKED ||
        serverErrorId === ServerErrors.CHANNEL_STOPPED,
);

export const selectTempPassChanelBlocked = createSelector(
    [selectTempPasswordServerErrorId],
    (serverErrorId) =>
        serverErrorId === ServerErrors.CHANNEL_BLOCKED ||
        serverErrorId === ServerErrors.CHANNEL_STOPPED,
);

export const selectFormattedPhoneOrFromUrl = createSelector(
    [getRegistrationPhone, getQueryRedirectParams],
    (phone, queryRedirectParams) => {
        if (validatePhoneNumber(phone) === null) {
            return clearFormatting(phone) ?? null;
        }

        return queryRedirectParams.phone;
    },
);
