import React from 'react';

import DesignCheck from '#/src/shared/components/ui/design-check';

import MessengerBankSms from '../../components/sms/messenger-bank-sms';
import Sms from '../../components/sms/sms';
import SmsV2 from '../../components/sms/sms-v2';
import SmsV2Mobile from '../../components/sms/sms-v2-mobile';
import X5WebSms from '../../components/sms/x5-web-sms';

export default (props: any) => (
    <DesignCheck
        defaultComponent={<SmsV2 {...props} />}
        defaultMobileComponent={<SmsV2Mobile {...props} />}
        mobileComponent={<SmsV2Mobile {...props} />}
        corpComponent={<Sms {...props} />}
        bankMessengerComponent={<MessengerBankSms {...props} />}
        X5WebComponent={<X5WebSms {...props} />}
    />
);
