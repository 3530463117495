import { decode } from 'js-base64';

import { HEADERS } from '#/src/shared/utils';

import { isBrowserEnv } from './is-browser-env';

type UserLocalInfo = {
    name: string;
};

export const getUserLocalInfo = (): UserLocalInfo => {
    if (isBrowserEnv) {
        const base64Data = window.localStorage.getItem(HEADERS.USER_INFO);

        if (base64Data) {
            return JSON.parse(decode(base64Data));
        }
    }

    return { name: '' };
};

export const setUserLocalInfo = (base64Data: string) => {
    window.localStorage.setItem(HEADERS.USER_INFO, base64Data);
};
