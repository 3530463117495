import React from 'react';

import { AlfaBankSignBoxMColorIcon } from '@alfalab/icons-logo/AlfaBankSignBoxMColorIcon';
import { AlfaIdEnMColorIcon } from '@alfalab/icons-logo/AlfaIdEnMColorIcon';
import { AlfaPay2024MColorIcon } from '@alfalab/icons-logo/AlfaPay2024MColorIcon';

import { useAppSelector } from '#/src/shared/hooks';
import { sendMetrics } from '#/src/shared/lib/analitycs';
import { getMetricsData } from '#/src/shared/lib/get-metrics-data';
import {
    getQueryRedirectParams,
    selectIsMobile,
    selectMetricsDebugEnabled,
} from '#/src/shared/store/redux/app/selectors';
import {
    selectCustomDesignClient,
    selectCustomDesignTitle,
} from '#/src/shared/store/redux/custom-design/selectors';
import { BANK_URL } from '#/src/shared/utils/constants';

export const Logo: React.FC = () => {
    const isMobile = useAppSelector(selectIsMobile);
    const customDesignTitle = useAppSelector(selectCustomDesignTitle);
    const customDesignClient = useAppSelector(selectCustomDesignClient);
    const isAlfaId = customDesignClient === 'alfaId';
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const metricsData = getMetricsData(queryRedirectParams, isMobile);
    const isMetricsDebugEnabled = useAppSelector(selectMetricsDebugEnabled);

    if (customDesignTitle) {
        if (isAlfaId) {
            return (
                <AlfaIdEnMColorIcon
                    data-test-id='alfa-id-logo'
                    {...(isMobile ? { width: '90', height: '36' } : { width: '75', height: '40' })}
                    style={{ marginRight: isMobile ? '12px' : '0' }}
                />
            );
        }

        return (
            <AlfaPay2024MColorIcon
                data-test-id='header-logo'
                {...(isMobile ? { width: '125', height: '30' } : { width: '150', height: '36' })}
                style={{ marginRight: isMobile ? '12px' : '0' }}
            />
        );
    }

    return (
        <a
            href={BANK_URL}
            data-test-id='header-logo'
            style={{ display: 'contents' }}
            onClick={() =>
                sendMetrics('Button alfa bank', 'Click', metricsData, isMetricsDebugEnabled)
            }
        >
            <AlfaBankSignBoxMColorIcon
                {...(isMobile ? { width: '40', height: '40' } : { width: '48', height: '48' })}
            />
        </a>
    );
};
