import { createSlice } from '@reduxjs/toolkit';

import { PincodeInitialState } from '#/src/shared/store/pincode/constants';

export const pincodeSlice = createSlice({
    name: 'pincode',
    initialState: PincodeInitialState,
    reducers: {
        pincodeErrorSet(state, action) {
            state.error = action.payload;
        },
        pincodeSet(state, action) {
            state.code = action.payload;
        },
        pincodeVisitedSet(state, action) {
            state.isVisited = action.payload;
        },
        pincodeUpdated(state, action) {
            return { ...state, ...action.payload };
        },
    },
});

export const {
    pincodeErrorSet,
    pincodeSet,
    pincodeVisitedSet,
    pincodeUpdated,
} = pincodeSlice.actions;

export default pincodeSlice.reducer;
