import { createSlice } from '@reduxjs/toolkit';

import { AKeyAuthorizationInitialState } from '#/src/shared/store/redux/akey/constants';

export const akeyAuthorizationSlice = createSlice({
    name: 'akey',
    initialState: AKeyAuthorizationInitialState,
    reducers: {
        akeyDeeplinkSet(state, action) {
            state.deeplink = action.payload;
        },
        akeyErrorSet(state, action) {
            state.error = action.payload;
        },
        akeyErrorCleared(state) {
            state.error = '';
        },
    },
});

export const {
    akeyDeeplinkSet,
    akeyErrorSet,
    akeyErrorCleared,
} = akeyAuthorizationSlice.actions;

export default akeyAuthorizationSlice.reducer
