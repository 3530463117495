import { QueryRedirectParams } from '#/src/shared/types/interfaces';

const getQueryParams = (location: string): QueryRedirectParams => {
    try {
        return location
            .substring(location.indexOf('?') + 1)
            .split('&')
            .reduce((obj, elem) => {
                if (location.indexOf('?') + 1) {
                    const queryParam = elem.split('=');

                    return {
                        ...obj,
                        [decodeURIComponent(queryParam[0])]: decodeURIComponent(queryParam[1]),
                    };
                }

                return {};
            }, {});
    } catch (error) {
        throw new Error(`Argument of getQueryParams is not a string: ${error}`);
    }
};

export default getQueryParams;
