import React, { useEffect, useState } from 'react';
import { isIOS } from 'react-device-detect';
import { createCn } from 'bem-react-classname';
import { conformToMask } from 'text-mask-core';

import { Button } from '@alfalab/core-components/button';
import { MaskedInput } from '@alfalab/core-components/masked-input';
import { Typography } from '@alfalab/core-components/typography';
import { BankAlfaXxlIcon } from '@alfalab/icons-classic/BankAlfaXxlIcon';
import Form from 'arui-feather/form';

import ServerErrorNotificationsNew from '#/src/shared/components/ui/server-errors-notification/server-errors-notification-new';
import ErrorDictionary from '#/src/shared/error-dictionary';
import { useAppDispatch, useAppSelector } from '#/src/shared/hooks';
import { trackUserEvent } from '#/src/shared/lib/analitycs';
import {
    validateAccountNumber,
    validateCardNumber,
} from '#/src/shared/lib/client-validation/registration';
import {
    ACCOUNT_INPUT_MAX_LENGTH,
    CARD_INPUT_MAX_LENGTH,
} from '#/src/shared/lib/form-controls-const';
import { FormStatus, RegistrationType } from '#/src/shared/models';
import { useRequestRegistrationMutation } from '#/src/shared/store/api/registration-api';
import {
    getQueryRedirectParams,
    selectIsVkMessenger,
} from '#/src/shared/store/redux/app/selectors';
import {
    getRegistrationAccount,
    getRegistrationCard,
    getRegistrationFormError,
    getRegistrationFormStatus,
    getRegistrationServerErrors,
    getRegistrationType,
    selectIsSubmitButtonDisabled,
    selectRegistrationErrorUpdate,
} from '#/src/shared/store/redux/registration/selectors';
import {
    errorUpdateMessageDeleted,
    registrationErrorUpdated,
    registrationFormUpdated,
    registrationServerErrorsCleared,
    registrationSubmit,
    registrationTypeChanged,
    registrationTypeUpdated,
    serverErrorNotificationClosed,
} from '#/src/shared/store/redux/registration/slice';

import './messenger-card-account.css';

const cn = createCn('messenger-card-account-browser');

const maskFn = (newValue: string) => {
    // prettier-ignore
    const cardMask = [
        /\d/, /\d/, /\d/, /\d/, ' ',
        /\d/, /\d/, /\d/, /\d/, ' ',
        /\d/, /\d/, /\d/, /\d/, ' ',
        /\d/, /\d/, /\d/, /\d/,
    ];
    // prettier-ignore
    const accountNumberMask = [
        /\d/, /\d/, /\d/, /\d/, ' ',
        /\d/, /\d/, /\d/, /\d/, ' ',
        /\d/, /\d/, /\d/, /\d/, ' ',
        /\d/, /\d/, /\d/, /\d/, ' ',
        /\d/, /\d/, /\d/, /\d/,
    ];

    return newValue.length <= cardMask.length ? cardMask : accountNumberMask;
};

const Index = () => {
    const dispatch = useAppDispatch();
    const [requestRegistration] = useRequestRegistrationMutation();
    const type = useAppSelector(getRegistrationType);
    const serverErrors = useAppSelector(getRegistrationServerErrors);
    const formStatus = useAppSelector(getRegistrationFormStatus);
    const card = useAppSelector(getRegistrationCard);
    const account = useAppSelector(getRegistrationAccount);
    const formError = useAppSelector(getRegistrationFormError);
    const isSubmitButtonDisabled = useAppSelector(selectIsSubmitButtonDisabled);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const isVkMessenger = useAppSelector(selectIsVkMessenger);
    const errorUpdate = useAppSelector(selectRegistrationErrorUpdate);

    const [firstKeyPush, setFirstKeyPush] = useState(false);
    const [isOpenKeyboard, setIsOpenKEyboard] = useState(false);

    useEffect(() => {
        dispatch(registrationTypeUpdated(type));
        trackUserEvent('Auth Page', 'Impression', 'Viewing Page', queryRedirectParams.client_id);
        if (errorUpdate) {
            dispatch(
                registrationErrorUpdated({
                    card: ErrorDictionary.GO_THROUGH_AGAIN,
                }),
            );
            dispatch(errorUpdateMessageDeleted());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (formStatus === FormStatus.ValidationSuccess) {
            requestRegistration();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formStatus]);

    useEffect(() => {
        if (formError.account) {
            trackUserEvent(
                'Auth Page',
                'Click',
                'Card Account Send',
                queryRedirectParams.client_id,
                `Account error: ${formError.account}`,
            );
        }
        if (formError.card) {
            trackUserEvent(
                'Auth Page',
                'Click',
                'Card Account Send',
                queryRedirectParams.client_id,
                `Card error: ${formError.card}`,
            );
        }
    }, [formError, queryRedirectParams]);

    useEffect(() => {
        if (serverErrors.length) {
            serverErrors.forEach((error) => {
                trackUserEvent(
                    'Auth Page',
                    'Click',
                    'Card Account Send',
                    queryRedirectParams.client_id,
                    `Server error: ${error.message}`,
                );
            });
        }
    }, [serverErrors, queryRedirectParams]);

    useEffect(() => {
        const isCard = type === RegistrationType.Card;
        const clientNumber = isCard ? card : account;
        const trackMessage = isCard ? 'Card number filled' : 'Account filled';
        const maxClientNumberLength = isCard ? CARD_INPUT_MAX_LENGTH : ACCOUNT_INPUT_MAX_LENGTH;
        const validation = isCard ? validateCardNumber : validateAccountNumber;
        const validationError = {
            card: '',
            account: '',
        };

        if (clientNumber.length === maxClientNumberLength) {
            const validationResult = validation(clientNumber);

            if (validationResult === null) {
                trackUserEvent(
                    'Auth Page',
                    'Field Change',
                    'Fill Card Account',
                    queryRedirectParams.client_id,
                    trackMessage,
                );
            } else {
                validationError[isCard ? 'card' : 'account'] = validationResult;
                dispatch(registrationErrorUpdated(validationError));
            }
        }

        return () => {
            dispatch(registrationServerErrorsCleared());
        };
    }, [account, card, type, queryRedirectParams, dispatch]);

    useEffect(() => {
        if (queryRedirectParams.account_number) {
            const conformedPhoneNumber = conformToMask(
                queryRedirectParams.account_number,
                maskFn(queryRedirectParams.account_number),
                { guide: false },
            );
            const value = conformedPhoneNumber.conformedValue;

            dispatch(registrationTypeChanged(RegistrationType.Account));
            dispatch(
                registrationFormUpdated({
                    card: '',
                    account: value,
                }),
            );
            dispatch(
                registrationSubmit({
                    type: RegistrationType.Account,
                    account: value,
                    card: '',
                }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryRedirectParams]);

    const handleOnClick = (e: React.FormEvent<any> | undefined) => {
        e?.preventDefault();
        if (!isSubmitButtonDisabled) {
            trackUserEvent(
                'Auth Page',
                'Click',
                'Card Account Send',
                queryRedirectParams.client_id,
                'Send',
            );
            dispatch(
                registrationSubmit({
                    type,
                    account,
                    card,
                }),
            );
        }
    };

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        { value }: { value: string },
    ) => {
        dispatch(registrationServerErrorsCleared());
        const trimmedValue = value.trim();

        if (!firstKeyPush) {
            trackUserEvent(
                'Auth Page',
                'Field Change',
                'Enter Card Account',
                queryRedirectParams.client_id,
            );
            setFirstKeyPush(true);
        }

        if (trimmedValue.length <= CARD_INPUT_MAX_LENGTH) {
            dispatch(
                registrationFormUpdated({
                    card: trimmedValue,
                    account: '',
                }),
            );
            if (type !== RegistrationType.Card) {
                trackUserEvent(
                    'Auth Page',
                    'Switch',
                    'Authentication Type',
                    queryRedirectParams.client_id,
                    'From Account To Card',
                );
                dispatch(registrationTypeChanged(RegistrationType.Card));
            }
        } else if (trimmedValue.length <= ACCOUNT_INPUT_MAX_LENGTH) {
            dispatch(
                registrationFormUpdated({
                    card: '',
                    account: trimmedValue,
                }),
            );
            if (type !== RegistrationType.Account) {
                trackUserEvent(
                    'Auth Page',
                    'Switch',
                    'Authentication Type',
                    queryRedirectParams.client_id,
                    'From Card To Account',
                );
                dispatch(registrationTypeChanged(RegistrationType.Account));
            }
        }
        dispatch(registrationErrorUpdated({ card: undefined, account: undefined }));
    };

    const handleFocus = () => isIOS && setIsOpenKEyboard(true);

    const handleBlur = () => isIOS && setIsOpenKEyboard(false);

    return (
        <div
            className={cn('container', {
                cutScreen: isVkMessenger,
                open: isOpenKeyboard && !isVkMessenger,
            })}
        >
            <Form onSubmit={handleOnClick} noValidate={true} className={cn()}>
                <div>
                    <ServerErrorNotificationsNew
                        errorMessage={serverErrors[0]?.message}
                        onClose={() => {
                            dispatch(serverErrorNotificationClosed(0));
                        }}
                    />
                    <Typography.Title tag='h1' view='xsmall' className={cn('title')}>
                        Введите номер карты — хочу <br /> убедиться, что это правда вы
                    </Typography.Title>
                    <div className={cn('bank-card')}>
                        <div>
                            <BankAlfaXxlIcon width={32} />
                        </div>
                        <div>
                            <MaskedInput
                                placeholder='Номер карты или счёта'
                                size='s'
                                block={true}
                                className={cn('input')}
                                inputMode='numeric'
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                mask={maskFn}
                                onChange={handleInputChange}
                                value={type === RegistrationType.Card ? card : account}
                            />
                        </div>
                    </div>
                </div>
                <Button
                    className={cn('button')}
                    view='primary'
                    block={true}
                    disabled={isSubmitButtonDisabled}
                    onClick={handleOnClick}
                    loading={formStatus === FormStatus.SubmitProcess}
                >
                    Далее
                </Button>
            </Form>
        </div>
    );
};

export default Index;
