import React, { FC, useEffect, useState } from 'react';
import { createCn } from 'bem-react-classname';

import { StatusScreenTechnicalError } from 'arui-private/core/status-screen';

import { useAppSelector } from '#/src/shared/hooks';
import { getQueryRedirectParams, selectOldBrowser } from '#/src/shared/store/redux/app/selectors';
import { selectAuthorizationIsLimitsClick } from '#/src/shared/store/redux/authorization/selectors';

import { ErrorCode } from '../error-code';

import './error.css';

const cn = createCn('page-error');

const Error: FC = () => {
    const [showError, setShowError] = useState(false);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const oldBrowser = useAppSelector(selectOldBrowser);
    const isLimitsClick = useAppSelector(selectAuthorizationIsLimitsClick);

    let title: string | undefined;
    let text: string | undefined;

    useEffect(() => {
        setShowError(true);
    }, []);

    if (queryRedirectParams?.limit || isLimitsClick) {
        title = 'Попробуйте войти позднее';
        text =
            'К сожалению, сейчас система перегружена. Мы уже работаем над исправлением ситуации.' +
            ' Приносим извинения за временные неудобства!';
    } else if (oldBrowser) {
        title = 'Версия вашего браузера устарела';
        text = 'Воспользуйтесь другим браузером или обновите текущий';
    } else {
        title = 'Страница не найдена';
    }

    if (showError) {
        return (
            <div className={cn()}>
                <StatusScreenTechnicalError
                    title={title}
                    subtitle={text}
                    type='tech'
                    fullHeight={true}
                    shouldShowBankContacts={true}
                    useBackgroundPlate={true}
                />
                <ErrorCode />
            </div>
        );
    }

    return null;
};

export default Error;
