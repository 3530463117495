import { createSlice } from '@reduxjs/toolkit';

import { getServerError } from '#/src/shared/lib/validators';
import { QRAuthorizationInitialState } from '#/src/shared/store/redux/qr-authorization/constants';
import { PreparedServerError } from '#/src/shared/store/types';

export const qrAuthorizationSlice = createSlice({
    name: 'qr-authorization',
    initialState: QRAuthorizationInitialState,
    reducers: {
        qrCodeResolved(state, action) {
            state.QRCode = action.payload;
        },
        qrCleared(state) {
            state.QRCode = '';
        },
        qrWebSocketConnectionStatusSet(state, action) {
            state.QRWebSocketConnectionStatus = action.payload;
        },
        authorizationByAssertionRejected(state, action) {
            const errors = action.payload;
            const serverErrors: PreparedServerError[] = [];

            if (Array.isArray(errors)) {
                errors.forEach((error) => {
                    serverErrors.push(getServerError(error));
                });
            }

            return {
                ...state,
                serverErrors,
            };
        },
        authorizationByAssertion() {},
        authorizationByAssertionResolved() {},
        getQRCode() {},
    },
});

export const {
    authorizationByAssertion,
    authorizationByAssertionRejected,
    authorizationByAssertionResolved,
    getQRCode,
    qrCleared,
    qrCodeResolved,
    qrWebSocketConnectionStatusSet,
} = qrAuthorizationSlice.actions

export default qrAuthorizationSlice.reducer
