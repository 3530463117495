import { history } from '#/src/shared/history';
import getQueryParams from '#/src/shared/lib/get-query-params';
import getRouteFromRedirect from '#/src/shared/lib/get-route-from-redirect';

export const redirectTo = (route: string) => {
    const path = getRouteFromRedirect(route);
    const getSearchParams = `?${new URLSearchParams(getQueryParams(route))}`;
    const search = getSearchParams === '?' ? history.location.search : getSearchParams;

    history.push(path + search);
};
