import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { closeSidePanel } from '@alfa-bank/newclick-core/window-utils';

import { useAppSelector } from '#/src/shared/hooks';
import { trackUserEvent } from '#/src/shared/lib/analitycs';
import { selectClientId } from '#/src/shared/store/redux/app/selectors';

import { AUTH_TYPE_PROPS, AuthCellBase, AuthType } from './auth-cell-base';

export const AuthCellDesktop: FC<{ authType: AuthType }> = ({ authType }) => {
    const { route, typeEvent } = AUTH_TYPE_PROPS[authType];
    const navigate = useNavigate();
    const { search } = useLocation();

    const clientId = useAppSelector(selectClientId);

    const onClick = () => {
        trackUserEvent('Auth Page', 'Click', typeEvent, clientId);
        navigate({ pathname: route, search });
        closeSidePanel();
    };

    return <AuthCellBase authType={authType} onClick={onClick} />;
};
