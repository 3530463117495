import { RoutePath, RoutePathKey, Routes } from '#/src/shared/models';

import getPathnameEndpoint from './get-pathname-endpoint';

export default function getRouteFromRedirect(redirectUrl?: string): string {
    const redirectEndpoint = getPathnameEndpoint(redirectUrl);
    const path: RoutePath[] = !redirectUrl
        ? []
        : (Object.keys(Routes) as RoutePathKey[])
              .map((key: RoutePathKey) => Routes[key])
              .filter((route) => redirectEndpoint === route);
    const finalRedirect =
        redirectUrl &&
        redirectUrl.indexOf('code=') !== -1 &&
        redirectUrl.indexOf('update_code=') === -1;

    return path[0] && !finalRedirect ? path[0] : Routes.FINISH_SIGNIN;
}
