import React, { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createCn } from 'bem-react-classname';

import Form from 'arui-feather/form';
import FormField from 'arui-feather/form-field';
import IconSubmit from 'arui-feather/icon/ui/submit';
import IconButton from 'arui-feather/icon-button';
import Input from 'arui-feather/input';
import Label from 'arui-feather/label';
import Link from 'arui-feather/link';

import InputCase from '#/src/shared/components/ui/input-case';
import ServerErrorNotifications from '#/src/shared/components/ui/server-errors-notification/server-errors-notification';
import { useAppDispatch, useAppSelector } from '#/src/shared/hooks';
import {
    validateBirthDate,
    validateFullName,
} from '#/src/shared/lib/client-validation/registration';
import {
    BIRTH_DATE_LENGTH,
    FORM_BUTTON_SIZE,
    FORM_FIELD_SIZE,
    FORM_INPUT_SIZE,
    FORM_LINK_SIZE,
} from '#/src/shared/lib/form-controls-const';
import getLinkCardIssue from '#/src/shared/lib/get-link-card-issue';
import getLinkTermsOfUse from '#/src/shared/lib/get-link-terms-of-use';
import { FormStatus, Routes } from '#/src/shared/models';
import { selectMultiFactorResponseParams } from '#/src/shared/store/redux/app/selectors';
import { setMultifactorResponse } from '#/src/shared/store/redux/app/slice';
import {
    getRegistrationBirthDate,
    getRegistrationFormError,
    getRegistrationFormStatus,
    getRegistrationFullName,
    getRegistrationServerErrors,
} from '#/src/shared/store/redux/registration/selectors';
import {
    fullnameFormSubmit,
    registrationErrorUpdated,
    registrationFormUpdated,
    registrationRequested,
    serverErrorNotificationClosed,
} from '#/src/shared/store/redux/registration/slice';

const cn = createCn('form-basic');

function handleTermsOfUseClick(event: React.MouseEvent<any, MouseEvent> | undefined) {
    event?.preventDefault();
    getLinkTermsOfUse();
}
function handleCardIssueClick(event: React.MouseEvent<any, MouseEvent> | undefined) {
    event?.preventDefault();
    getLinkCardIssue();
}

const FullName: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { search } = useLocation();
    const fullName = useAppSelector(getRegistrationFullName);
    const birthDate = useAppSelector(getRegistrationBirthDate);
    const formStatus = useAppSelector(getRegistrationFormStatus);
    const formError = useAppSelector(getRegistrationFormError);
    const serverErrors = useAppSelector(getRegistrationServerErrors);
    const multiFactorResponseParams = useAppSelector(selectMultiFactorResponseParams);

    useEffect(() => {
        if (formStatus === FormStatus.ValidationSuccess) {
            dispatch(registrationRequested());
            dispatch(
                setMultifactorResponse({
                    url: '',
                    params: { token: multiFactorResponseParams.token },
                }),
            );
            navigate({
                pathname: Routes.SMS,
                search,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formStatus]);

    useEffect(() => {
        const handleBirthDateValidation = (prevBirthDate: string) => {
            if (prevBirthDate !== birthDate && birthDate.length === 10) {
                const birthDateValidation = validateBirthDate(birthDate);

                if (birthDateValidation !== null) {
                    dispatch(registrationErrorUpdated({ birthDate: birthDateValidation }));
                }
            } else if (prevBirthDate !== birthDate && formError.birthDate) {
                dispatch(registrationErrorUpdated({ birthDate: null }));
            }
        };

        handleBirthDateValidation(birthDate);
    }, [birthDate, dispatch, formError.birthDate]);

    useEffect(() => {
        const handleFullNameValidate = (prevFullName: string) => {
            if (prevFullName !== fullName && fullName.split(' ').length > 2) {
                const fullNameValidation = validateFullName(fullName);

                if (fullNameValidation !== null) {
                    dispatch(registrationErrorUpdated({ fullName: fullNameValidation }));
                }
            } else if (prevFullName !== fullName && formError.fullName) {
                dispatch(registrationErrorUpdated({ fullName: null }));
            }
        };

        handleFullNameValidate(fullName);
    }, [dispatch, formError.fullName, fullName]);

    const handleFullNameBlur = () => {
        if (fullName.length > 0) {
            dispatch(registrationErrorUpdated({ fullName: validateFullName(fullName) }));
        }
    };

    const handleFullNameChange = (fullName?: string) => {
        dispatch(registrationFormUpdated({ fullName }));
    };

    const handleBirthDateBlur = () => {
        if (birthDate.length > 0 && birthDate.length <= BIRTH_DATE_LENGTH) {
            dispatch(registrationErrorUpdated({ birthDate: validateBirthDate(birthDate) }));
        }
    };

    const handlenotificationClosed = (index: number) => {
        dispatch(serverErrorNotificationClosed(index));
    };

    const handleSubmit = () => {
        dispatch(
            fullnameFormSubmit({
                birthDate,
                fullName,
            }),
        );
    };

    const handleBirthDateChange = (date?: string) => {
        dispatch(registrationFormUpdated({ birthDate: date }));
    };

    const isSubmitButtonDisabled = () => {
        let isDisabled = false;

        isDisabled = Boolean(
            fullName.split(' ').length <= 1 ||
                fullName.split(' ')[1] === '' ||
                birthDate.length < 10 ||
                formError.fullName ||
                formError.birthDate,
        );

        return isDisabled;
    };

    const renderFullName = () => (
        <div className={cn('full-name-input')} id='fullNameForm'>
            <FormField size={FORM_FIELD_SIZE} id='fullNameFormField'>
                <InputCase>
                    <Input
                        size={FORM_INPUT_SIZE}
                        value={fullName}
                        autocomplete={true}
                        id='fullNameInput'
                        error={formError.fullName}
                        resetError={false}
                        onBlur={handleFullNameBlur}
                        onChange={handleFullNameChange}
                        width='available'
                        placeholder='Иванов Алексей Петрович'
                        label='Фамилия, имя и отчество'
                    />
                </InputCase>
            </FormField>
            <FormField size={FORM_FIELD_SIZE} id='birth-date-form-field'>
                <InputCase>
                    <Input
                        id='birthDateInput'
                        autocomplete={true}
                        value={birthDate}
                        type='tel'
                        size={FORM_INPUT_SIZE}
                        width='available'
                        mask='11/11/1111'
                        placeholder='00/00/0000'
                        label='Дата рождения'
                        error={formError.birthDate}
                        resetError={false}
                        onBlur={handleBirthDateBlur}
                        onChange={handleBirthDateChange}
                        rightAddons={
                            <IconButton
                                id='submitButton'
                                disabled={isSubmitButtonDisabled()}
                                icon={<IconSubmit size={FORM_BUTTON_SIZE} />}
                                type='submit'
                                onClick={handleSubmit}
                            />
                        }
                    />
                </InputCase>
            </FormField>
            <br />
            <Label size={FORM_LINK_SIZE}>
                Нажимая кнопку &#171;Продолжить&#187;,
                <br />
                вы присоединяетесь к<br />
                <Link
                    size={FORM_LINK_SIZE}
                    text='Пользовательскому Cоглашению'
                    onClick={handleTermsOfUseClick}
                    target='_blank'
                />
                ,<br />
                <Link
                    size={FORM_LINK_SIZE}
                    text='Договору на выпуск Виртуальной предоплаченной карты «NEXT»'
                    onClick={handleCardIssueClick}
                    target='_blank'
                />
                ,<br />
                <Link
                    size={FORM_LINK_SIZE}
                    text='Правилам Программы CashBack «NEXT»,'
                    onClick={handleCardIssueClick}
                    target='_blank'
                />
                <br />
                даёте распоряжение на выпуск
                <br />
                Виртуальной предоплаченной карты &#171;NEXT&#187;
                <br />и согласие на обработку персональных данных
            </Label>
        </div>
    );

    return (
        <Form onSubmit={handleSubmit} noValidate={true} className={cn()}>
            <ServerErrorNotifications
                serverErrors={serverErrors}
                onnotificationClosed={handlenotificationClosed}
            />
            {renderFullName()}
        </Form>
    );
};

export default FullName;
