import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { PasswordInput } from '@alfalab/core-components/password-input';
import { Typography } from '@alfalab/core-components/typography';
import Form from 'arui-feather/form';

import ErrorDictionary from '#/src/shared/error-dictionary';
import { useAppDispatch, useAppSelector } from '#/src/shared/hooks';
import {
    investmentsValidateLogin,
    investmentsValidatePassword,
} from '#/src/shared/lib/client-validation/authorization';
import { PASSWORD_INPUT_MAX_LENGTH } from '#/src/shared/lib/form-controls-const';
import { submitNativeEvent } from '#/src/shared/lib/submit-native-event';
import { ButtonNames, nativeEventStatus } from '#/src/shared/models';
import { useRequestOIDAuthByLoginMutation } from '#/src/shared/store/api/authorization-api';
import {
    getQueryRedirectParams,
    selectInvestmentsUrl,
} from '#/src/shared/store/redux/app/selectors';
import {
    getAuthorizationFormError,
    getAuthorizationPassword,
    getAuthorizationServerErrors,
} from '#/src/shared/store/redux/authorization/selectors';
import {
    authorizationErrorUpdated,
    authorizationFormReset,
    authorizationFormUpdated,
    authorizationPasswordReset,
    authorizationServerErrorReset,
} from '#/src/shared/store/redux/authorization/slice';
import { QueryRedirectParams } from '#/src/shared/types/interfaces';

const cn = createCn('investments');

const InvestmentsPassword: FC = () => {
    const dispatch = useAppDispatch();
    const [requestOIDAuthByLogin] = useRequestOIDAuthByLoginMutation();

    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const authError = useAppSelector(getAuthorizationFormError);
    const password = useAppSelector(getAuthorizationPassword);
    const authServerErrors = useAppSelector(getAuthorizationServerErrors);
    const investmentsUrl = useAppSelector(selectInvestmentsUrl);

    const [isDisabled, setIsDisabled] = useState(true);
    const login = queryRedirectParams?.login as string;

    useEffect(() => {
        dispatch(
            authorizationFormUpdated({
                login,
            }),
        );
    }, [dispatch, login]);

    useEffect(() => {
        if (password) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [password]);

    useEffect(
        () =>
            function resetForm() {
                dispatch(authorizationFormReset());
                dispatch(authorizationPasswordReset());
            },
        [dispatch],
    );

    useEffect(() => {
        if (authError.password || authServerErrors[0]?.message) {
            submitNativeEvent({ status: nativeEventStatus.loginFailed });
        }
    }, [authError.password, authServerErrors]);

    const authResponseError = (error: string) => {
        if (error === ErrorDictionary.DEFAULT) {
            return ErrorDictionary.GO_INVEST_AUTH_ERROR;
        }

        return error;
    };

    const handleChangePassword = (newPassword: string) => {
        if (authServerErrors.length) {
            dispatch(authorizationServerErrorReset());
        }
        if (authError.password) {
            dispatch(
                authorizationErrorUpdated({
                    password: '',
                }),
            );
        }
        dispatch(
            authorizationFormUpdated({
                password: newPassword,
            }),
        );
    };

    const handleValidate = (
        loginValue: string,
        passwordValue: string,
        queryParams: QueryRedirectParams,
    ) => {
        const loginValidation = investmentsValidateLogin(loginValue);
        const passwordValidation = investmentsValidatePassword(passwordValue);

        if (loginValidation === null && passwordValidation === null) {
            requestOIDAuthByLogin({
                username: loginValue,
                password: passwordValue,
                queryRedirectParams: queryParams,
            });
        }

        if (loginValidation) {
            dispatch(authorizationErrorUpdated({ login: loginValidation }));
        }

        if (passwordValidation) {
            dispatch(authorizationErrorUpdated({ password: passwordValidation }));
        }
    };

    const handleLoginSubmit = () => {
        submitNativeEvent({ status: nativeEventStatus.buttonPressed });
        handleValidate(login, password, queryRedirectParams);
    };

    return (
        <div className={cn('container')}>
            <Typography.Title
                className={cn('title')}
                color='primary'
                tag='h2'
                font='system'
                view='small'
                weight='medium'
                defaultMargins={false}
            >
                Спасибо! А&nbsp;теперь введите&nbsp;пароль
            </Typography.Title>
            <Form onSubmit={handleLoginSubmit} className={cn('form')} noValidate={true}>
                <div className={cn('row')}>
                    <PasswordInput
                        value={password}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleChangePassword(e.target.value)
                        }
                        maxLength={PASSWORD_INPUT_MAX_LENGTH}
                        label='Введите пароль'
                        size='m'
                        block={true}
                        autoCapitalize='none'
                    />
                    {(authError?.password || authServerErrors[0]) && (
                        <div className={cn('error-text-wrapper')}>
                            <div className={cn('error-text')}>
                                {authError.password
                                    ? authResponseError(authError.password)
                                    : authServerErrors[0].message}
                            </div>
                        </div>
                    )}
                </div>
                <div className={cn('button-wrapper')}>
                    <Button
                        block={true}
                        type='submit'
                        view='primary'
                        className={cn('button')}
                        disabled={isDisabled}
                    >
                        {ButtonNames.continue}
                    </Button>
                    <Button
                        block={true}
                        view='transparent'
                        className={cn('link')}
                        href={investmentsUrl}
                    >
                        {ButtonNames.restorePassword}
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default InvestmentsPassword;
