import getCookie from '#/src/shared/lib/get-cookie';
import { CookiesName } from '#/src/shared/models';

export const hasPincodeCookies = (): boolean => {
    // Скрываем на время SSR
    if (typeof window === 'undefined') return false;

    const authTypePincode = getCookie(CookiesName.authTypePincode);
    const browserId = getCookie(CookiesName.browserId);
    const deviceAppId = getCookie(CookiesName.deviceAppId);

    return Boolean(authTypePincode === 'true' && browserId && deviceAppId);
};
