import React, { FC, useEffect } from 'react';
import { createCn } from 'bem-react-classname';

import { Confirmation, useConfirmation } from '@alfalab/core-components/confirmation';
import { Typography } from '@alfalab/core-components/typography';

import { useAppDispatch, useAppSelector } from '#/src/shared/hooks';
import { trackUserEvent } from '#/src/shared/lib/analitycs';
import { SMS_INPUT_MAX_LENGTH } from '#/src/shared/lib/form-controls-const';
import { maskPhone } from '#/src/shared/lib/mask-phone';
import { FormStatus } from '#/src/shared/models';
import {
    useRequestOIDSmsVerificationMutation,
    useRequestReferenceBySmsMutation,
} from '#/src/shared/store/api/sms-verification-api';
import {
    formatMaskedPhoneNumber,
    getQueryRedirectParams,
} from '#/src/shared/store/redux/app/selectors';
import { getRegistrationPhone } from '#/src/shared/store/redux/registration/selectors';
import {
    selectSmsCode,
    selectSmsFormStatus,
    selectSmsServerError,
    selectSmsServerErrorMsg,
} from '#/src/shared/store/redux/sms-verification/selectors';
import {
    smsServerErrorNotificationClosed,
    smsVerificationFormUpdated,
    smsVerificationServerErrorsReset,
    smsVerificationStatusRequested,
    smsVerificationSubmit,
} from '#/src/shared/store/redux/sms-verification/slice';

import './x5-web-sms.css';

const cn = createCn('x5-web-sms');

const X5WebSms: FC = () => {
    const dispatch = useAppDispatch();
    const [requestOIDSmsVerification] = useRequestOIDSmsVerificationMutation();
    const [requestReferenceBySms] = useRequestReferenceBySmsMutation();

    const formStatus = useAppSelector(selectSmsFormStatus);
    const code = useAppSelector(selectSmsCode);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const serverError = useAppSelector(selectSmsServerError);
    const serverErrorMessage = useAppSelector(selectSmsServerErrorMsg);
    const formatedMaskedPhoneNumber = useAppSelector(formatMaskedPhoneNumber);
    const phone = useAppSelector(getRegistrationPhone);

    const {
        confirmationState,
        confirmationScreen,
        setConfirmationState,
        setConfirmationScreen,
        confirmationBlockSmsRetry,
    } = useConfirmation();

    const phoneNumber = formatedMaskedPhoneNumber
        ? `${formatedMaskedPhoneNumber.replace(/000 000/gi, '••• •••')}`
        : maskPhone(phone);

    useEffect(() => {
        requestReferenceBySms(code);

        trackUserEvent('Auth Page', 'Impression', 'Viewing Page', queryRedirectParams.client_id);

        return () => {
            dispatch(smsVerificationStatusRequested(true));
            dispatch(smsServerErrorNotificationClosed());
            dispatch(smsVerificationFormUpdated({ code: '' }));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (serverError) setConfirmationState('CODE_ERROR');
        else setConfirmationState('INITIAL');
    }, [setConfirmationState, serverError]);

    useEffect(() => {
        if (formStatus === FormStatus.ValidationSuccess && code.length === 4) {
            requestOIDSmsVerification({
                code,
                options: {
                    mobile: true,
                },
            });
        }

        if (serverError) {
            trackUserEvent(
                'Auth Page',
                'Click',
                'SMS OTP Send',
                queryRedirectParams.client_id,
                `Error ${serverErrorMessage}`,
            );
        }
    }, [
        code,
        requestOIDSmsVerification,
        formStatus,
        queryRedirectParams.client_id,
        serverError,
        serverErrorMessage,
    ]);

    const handleInputFinished = (value: string) => {
        if (value.length === SMS_INPUT_MAX_LENGTH) {
            trackUserEvent(
                'Auth Page',
                'Click',
                'SMS OTP Send',
                queryRedirectParams.client_id,
                'Send',
            );

            if (serverError && !serverErrorMessage) {
                dispatch(smsVerificationServerErrorsReset());
            }

            dispatch(smsVerificationFormUpdated({ code: value }));
            dispatch(
                smsVerificationSubmit({
                    code: value,
                }),
            );
        }
    };

    const handleRepeatSms = () => {
        if (serverError) {
            dispatch(smsVerificationServerErrorsReset());
        }
        if (!formStatus) {
            dispatch(smsVerificationStatusRequested(true));
        }
        if (code) {
            dispatch(smsVerificationFormUpdated({ code: '' }));
        }
        trackUserEvent('Auth Page', 'Click', 'Resend OTP', queryRedirectParams.client_id);
        requestReferenceBySms(code);

        // Чтобы компонента блокировала повторную отправку смс после его нажатия
        setTimeout(() => {
            setConfirmationState('INITIAL');
        }, 1000);
    };

    return (
        <Confirmation
            alignContent='center'
            phone={phoneNumber}
            screen={confirmationScreen}
            state={confirmationState}
            onChangeState={setConfirmationState}
            onChangeScreen={setConfirmationScreen}
            onInputFinished={handleInputFinished}
            onSmsRetryClick={handleRepeatSms}
            blockSmsRetry={confirmationBlockSmsRetry}
            countdownDuration={60000}
            requiredCharAmount={SMS_INPUT_MAX_LENGTH}
            texts={{
                title: (
                    <Typography.Title className={cn('title')} tag='h4' view='xsmall' weight='bold'>
                        Введите код из СМС
                    </Typography.Title>
                ),
            }}
        />
    );
};

export default X5WebSms;
