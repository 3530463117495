import getCookie from '#/src/shared/lib/get-cookie';
import getPathnameEndpoint from '#/src/shared/lib/get-pathname-endpoint';
import initializeTraceId from '#/src/shared/lib/initialize-trace-id';
import { AuthType, CookiesName } from '#/src/shared/models';
import { QueryRedirectParams } from '#/src/shared/types/interfaces';
import { HEADERS } from '#/src/shared/utils';

const checkPlatform = (isMobile?: boolean) => {
    const authType = getCookie(CookiesName.authType);
    const isPWA = window.matchMedia('(display-mode: standalone)').matches;

    if (authType === AuthType.Capsule || authType === AuthType.VoskhodApp) {
        return 'voskhod';
    }

    if (isPWA) {
        return 'pwa';
    }

    if (isMobile) {
        return 'mobile';
    }

    return 'desktop';
};

export const getMetricsData = (
    queryRedirectParams: QueryRedirectParams,
    isMobile?: boolean,
): Record<string, string | null> => {
    if (typeof window !== 'undefined') {
        const deviceType = navigator.userAgent;
        const path = getPathnameEndpoint(window.location.pathname);
        const clientId = queryRedirectParams?.client_id;
        const browserId = getCookie(CookiesName.browserId);
        const platform = checkPlatform(isMobile);
        const traceId = initializeTraceId()[HEADERS.TRACE_ID];

        return {
            deviceType,
            path,
            clientId,
            browserId: browserId ?? null,
            platform,
            traceId,
        };
    }

    return {};
};
