import { createSelector } from 'reselect';

import { PASSWORD_BUTTON_MIN_LENGTH_DISABLED } from '#/src/shared/lib/form-controls-const';
import { FormStatus } from '#/src/shared/models';
import {
    getAuthorizationFormError,
    getAuthorizationFormStatus,
    getAuthorizationPassword,
} from '#/src/shared/store/redux/authorization/selectors';

export const isSubmitButtonDisabled = createSelector(
    [getAuthorizationPassword, getAuthorizationFormError, getAuthorizationFormStatus],
    (password, formError, formStatus) =>
        password.length < PASSWORD_BUTTON_MIN_LENGTH_DISABLED ||
        formStatus === FormStatus.SubmitProcess ||
        !!formError.password,
);
