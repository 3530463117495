import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { Typography } from '@alfalab/core-components/typography';
import { SfFaceIdMIcon } from '@alfalab/icons-glyph/SfFaceIdMIcon';

import ServerErrorsNotificationNew from '#/src/shared/components/ui/server-errors-notification/server-errors-notification-new';
import { useAppDispatch, useAppSelector, useIsomorphicLayoutEffect } from '#/src/shared/hooks';
import getCookie from '#/src/shared/lib/get-cookie';
import { getIsPincodeReady } from '#/src/shared/lib/get-is-pincode-available';
import { isBrowserEnv } from '#/src/shared/lib/is-browser-env';
import { getUserLocalInfo } from '#/src/shared/lib/user-info';
import { CookiesName, Routes } from '#/src/shared/models';
import { useRequestWebAuthnChallengeMutation } from '#/src/shared/store/api/webauthn-api';
import { selectPincodeAttemptsLeft } from '#/src/shared/store/pincode/selectors';
import { isGlobalPreloaderVisible } from '#/src/shared/store/redux/app/selectors';
import { webAuthnStatusSet } from '#/src/shared/store/redux/app/slice';
import {
    selectCustomDesignSubtitle,
    selectCustomDesignTitle,
} from '#/src/shared/store/redux/custom-design/selectors';
import { selectWebAuthnError } from '#/src/shared/store/redux/webauthn/selectors';
import { webAuthnErrorCleared } from '#/src/shared/store/redux/webauthn/slice';

import { LogoAnimated } from '../../components/logo-animated';

import './webauthn.css';

const cn = createCn('webauthn');

const WebAuthn: React.FC = () => {
    const dispatch = useAppDispatch();
    const { search } = useLocation();
    const navigate = useNavigate();
    const [requestWebAuthnChallenge] = useRequestWebAuthnChallengeMutation();
    const customDesignTitle = useAppSelector(selectCustomDesignTitle);
    const customDesignSubtitle = useAppSelector(selectCustomDesignSubtitle);
    const error = useAppSelector(selectWebAuthnError);
    const isPincodeReady = useAppSelector(getIsPincodeReady);
    const attemptsLeft = useAppSelector(selectPincodeAttemptsLeft);
    const globalPreloaderVisible = useAppSelector(isGlobalPreloaderVisible);
    const [xUserInfo, setXUserInfo] = useState<ReturnType<typeof getUserLocalInfo> | null>(null);
    const [disabled, setDisabled] = useState<boolean>(false);

    const requestAuthorization = async () => {
        setDisabled(true);
        const webAuthnUserId = (() => {
            if (isBrowserEnv) {
                return getCookie(CookiesName.webAuthnUserId);
            }

            return undefined;
        })();

        if (webAuthnUserId) {
            await requestWebAuthnChallenge({ webAuthnUserId });
        }

        setDisabled(false);
    };

    useIsomorphicLayoutEffect(() => {
        setXUserInfo(getUserLocalInfo());
    }, []);

    useEffect(() => {
        const isPWA = window.matchMedia('(display-mode: standalone)').matches;
        const listener = () => {
            if (!document.hidden) {
                requestAuthorization();
            }
        };

        if (isPWA) {
            requestAuthorization();
            document.addEventListener('visibilitychange', listener);
        }

        return () => {
            document.removeEventListener('visibilitychange', listener);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleButtonClick = (route: string) => {
        dispatch(webAuthnStatusSet(false));

        navigate(
            {
                pathname: route,
                search,
            },
            { state: { asSecondaryPage: true } },
        );
    };

    const title = () => {
        if (customDesignTitle) {
            return (
                <React.Fragment>
                    <Typography.TitleMobile
                        rowLimit={2}
                        className={cn('header')}
                        view='medium'
                        font='styrene'
                        tag='h1'
                        dataTestId='merchant-title'
                    >
                        {customDesignTitle}
                    </Typography.TitleMobile>
                    <Typography.Text
                        rowLimit={2}
                        tag='div'
                        dataTestId='merchant-subtitle'
                        view='primary-medium'
                    >
                        {customDesignSubtitle}
                    </Typography.Text>
                </React.Fragment>
            );
        }

        if (xUserInfo?.name) {
            return (
                <React.Fragment>
                    <Typography.TitleMobile view='medium' font='styrene' tag='h1'>
                        Привет, {xUserInfo.name}!
                    </Typography.TitleMobile>
                    <Typography.Text
                        view='primary-medium'
                        color='secondary'
                        className={cn('text')}
                        tag='div'
                    >
                        Вам доступен быстрый вход
                        <br /> в&nbsp;Альфа-Онлайн
                    </Typography.Text>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <Typography.TitleMobile view='medium' font='styrene' tag='h1'>
                    Добро пожаловать
                    <br />
                    в&nbsp;Альфа-Онлайн
                </Typography.TitleMobile>
                <Typography.Text
                    view='primary-medium'
                    color='secondary'
                    className={cn('text')}
                    tag='div'
                >
                    Вам доступен быстрый вход.
                </Typography.Text>
            </React.Fragment>
        );
    };

    return (
        <div className={cn('container')}>
            <div className={cn('content-top')}>
                <div className={cn('logo')}>
                    <LogoAnimated isVisible={!globalPreloaderVisible} />
                </div>

                {error && (
                    <ServerErrorsNotificationNew
                        errorMessage={error}
                        onClose={() => {
                            dispatch(webAuthnErrorCleared());
                        }}
                    />
                )}
            </div>

            <div className={cn('divider')} />

            <div className={cn('content-bottom')}>
                {title()}

                <div className={cn('buttons')}>
                    <Button
                        view='primary'
                        block={true}
                        target='_blank'
                        onClick={requestAuthorization}
                        leftAddons={<SfFaceIdMIcon />}
                        disabled={disabled}
                    >
                        Войти
                    </Button>

                    {isPincodeReady && attemptsLeft ? (
                        <Button
                            className={cn('button')}
                            view='transparent'
                            block={true}
                            onClick={() => handleButtonClick(Routes.PINCODE)}
                        >
                            Ввести секретный код
                        </Button>
                    ) : (
                        <Button
                            dataTestId='phone-auth-button'
                            className={cn('button')}
                            view='transparent'
                            block={true}
                            onClick={() => handleButtonClick(Routes.PHONE_AUTH)}
                        >
                            Войти по номеру телефона
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default WebAuthn;
