import { createSlice } from '@reduxjs/toolkit';

import { WebAuthnAuthorizationInitialState } from '#/src/shared/store/redux/webauthn/constants';

export const webAuthnAuthorizationSlice = createSlice({
    name: 'webAuthn',
    initialState: WebAuthnAuthorizationInitialState,
    reducers: {
        webAuthnErrorSet(state, action) {
            state.error = action.payload;
        },
        webAuthnErrorCleared(state) {
            state.error = '';
        },
    },
});

export const {
    webAuthnErrorCleared,
    webAuthnErrorSet,
} = webAuthnAuthorizationSlice.actions

export default webAuthnAuthorizationSlice.reducer;
