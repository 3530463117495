import React from 'react';

import Error from '#/src/shared/components/error/error/error';
import DesignCheck from '#/src/shared/components/ui/design-check';

import { ErrorAlfaOnline } from '../../components/error/error-alfa-online';

export default () => (
    <DesignCheck
        defaultComponent={<ErrorAlfaOnline />}
        defaultMobileComponent={<ErrorAlfaOnline />}
        mobileComponent={<ErrorAlfaOnline />}
        corpComponent={<Error />}
    />
);
