import React, { useState } from 'react';
import { createCn } from 'bem-react-classname';

import { PassCode } from '@alfalab/core-components/pass-code';

import { useAppDispatch, useAppSelector } from '#/src/shared/hooks';
import { trackUserEvent } from '#/src/shared/lib/analitycs';
import { PINCODE_MAX_LENGTH } from '#/src/shared/lib/form-controls-const';
import { FormStatus } from '#/src/shared/models';
import { selectClientId } from '#/src/shared/store/redux/app/selectors';
import {
    selectPasscode,
    selectPasscodeError,
    selectPasscodeFormStatus,
    selectPasscodeShowToastError,
} from '#/src/shared/store/redux/passcode';
import { passcodeSet, passcodeUpdated } from '#/src/shared/store/redux/passcode/slice';

import './numeric-passcode.css';

const cn = createCn('passcode-numeric');

const NumericPasscode = () => {
    const dispatch = useAppDispatch();

    const error = useAppSelector(selectPasscodeError);
    const formStatus = useAppSelector(selectPasscodeFormStatus);
    const passcode = useAppSelector(selectPasscode);
    const clientId = useAppSelector(selectClientId);
    const showToastError = useAppSelector(selectPasscodeShowToastError);

    const [firstKeyPush, setFirstKeyPush] = useState(false);

    const onChange = (value: string) => {
        dispatch(passcodeSet(value));
        if (error !== '') {
            dispatch(passcodeUpdated({ error: '' }));
        }
        if (!firstKeyPush) {
            trackUserEvent('Auth Page', 'Field Change', 'Enter Passcode', clientId, 'Numeric');
            setFirstKeyPush(true);
        }
    };

    return (
        <div className={cn('container')}>
            <PassCode
                className={cn('keyboard')}
                key='passcode'
                disabled={formStatus === FormStatus.SubmitProcess}
                value={passcode}
                error={showToastError}
                maxCodeLength={PINCODE_MAX_LENGTH}
                onChange={onChange}
            />
        </div>
    );
};

export default NumericPasscode;
