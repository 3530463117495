import React from 'react';

import DesignCheck from '#/src/shared/components/ui/design-check';

import LoginBrowser from '../../components/login/login-browser';
import Login from '../../components/login/login-old';

export default () => (
    <DesignCheck
        defaultComponent={<LoginBrowser />} // !isCorporateClientId
        defaultMobileComponent={<LoginBrowser />}
        mobileComponent={<Login />}
        corpComponent={<Login />}
    />
);
