import { DEFAULT_PHONE_COUNTRY_CODE } from '#/src/shared/utils';

export const maskPhone = (phone: string) => {
    if (phone && phone !== DEFAULT_PHONE_COUNTRY_CODE) {
        const phoneTrim = phone.replaceAll(' ', '');
        const phoneLength = phoneTrim.length;

        return `${phoneTrim.slice(0, 2)} ••• ••• ${phoneTrim.slice(
            phoneLength - 4,
            phoneLength - 2,
        )} ${phoneTrim.slice(phoneLength - 2, phoneLength)}`;
    }

    return '';
};
