import { createSlice } from '@reduxjs/toolkit';

import { FingerprintInitialState } from '#/src/shared/store/redux/fingerprint/constants';

export const fingerprintSlice = createSlice({
    name: 'fingerprint',
    initialState: FingerprintInitialState,
    reducers: {
        fingerprintSet(state, action) {
            return { ...state, ...action.payload };
        },
    },
});

export const {
    fingerprintSet,
} = fingerprintSlice.actions

export default fingerprintSlice.reducer
