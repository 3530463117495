import React, { useEffect, useRef, useState } from 'react';

import { PatternLock, THEME_STATE } from '@alfalab/core-components/pattern-lock';

import { useAppDispatch, useAppSelector } from '#/src/shared/hooks';
import { trackUserEvent } from '#/src/shared/lib/analitycs';
import { validationPasscodeAO } from '#/src/shared/lib/client-validation/authorization';
import { PasscodeType } from '#/src/shared/models';
import { selectClientId } from '#/src/shared/store/redux/app/selectors';
import { selectPasscodeShowToastError } from '#/src/shared/store/redux/passcode';
import {
    passcodeErrorSet,
    passcodeSet,
    showToastErrorSet,
} from '#/src/shared/store/redux/passcode/slice';

const GraphicPatternPasscode = () => {
    const dispatch = useAppDispatch();

    const clientId = useAppSelector(selectClientId);
    const showToastError = useAppSelector(selectPasscodeShowToastError);

    const [currentState, setCurrentState] = useState(THEME_STATE.INITIAL);
    const patternLockInstance = useRef<any>();
    const [firstKeyPush, setFirstKeyPush] = useState(false);

    const handleComplete = (code: number[]) => {
        const passcode = code.join('');

        dispatch(passcodeErrorSet(''));

        if (!firstKeyPush) {
            trackUserEvent(
                'Auth Page',
                'Field Change',
                'Enter Passcode',
                clientId,
                'Graphic Pattern',
            );
            setFirstKeyPush(true);
        }

        dispatch(passcodeSet(passcode));
        const validation = validationPasscodeAO(passcode, PasscodeType.GraphicPattern);

        if (validation) {
            dispatch(passcodeErrorSet(validation));
            dispatch(showToastErrorSet(true));
        }
    };

    useEffect(
        () => {
            if (showToastError) {
                setCurrentState(THEME_STATE.FAILURE);
            } else {
                setCurrentState(THEME_STATE.INITIAL);
                patternLockInstance?.current?.setInitialState();
            }
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [showToastError],
    );

    return (
        <PatternLock
            themeState={currentState}
            ref={patternLockInstance}
            onComplete={handleComplete}
            showForgotCodeBtn={false}
            observeTokens={true}
            onDragStart={() => {
                setCurrentState(THEME_STATE.INITIAL);
            }}
        />
    );
};

export default GraphicPatternPasscode;
