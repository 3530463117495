import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createCn } from 'bem-react-classname';

import Heading from 'arui-feather/heading';
import Label from 'arui-feather/label';
import ThemeProvider from 'arui-feather/theme-provider';
import Content from 'arui-private/core/content';
import Footer from 'arui-private/core/footer';
import Page from 'arui-private/core/page';

import AppHeader from '#/src/shared/components/app/app/app-header';
import { AlfaIdBannerDesktop } from '#/src/shared/components/ui/alfaid-banner';
import AppBackground from '#/src/shared/components/ui/app-background';
import Phones from '#/src/shared/components/ui/phones';
import { useAppSelector } from '#/src/shared/hooks';
import { HEADING_SIZE } from '#/src/shared/lib/form-controls-const';
import getPathnameEndpoint from '#/src/shared/lib/get-pathname-endpoint';
import { reason, RegistrationType, Routes, Themes } from '#/src/shared/models';
import {
    selectIsAlfaIdBannerEnabled,
    selectIsCorpAlboClientId,
    selectIsMobile,
    selectIsWebView,
    selectMultiFactorResponseParams,
    selectOldBrowser,
} from '#/src/shared/store/redux/app/selectors';
import { getAuthorizationLogin } from '#/src/shared/store/redux/authorization/selectors';
import { getRegistrationType } from '#/src/shared/store/redux/registration/selectors';

import './app.css';

const cn = createCn('app');

const renderFooter = (currentRoute: string) => (
    <div>
        <Phones currentRoute={currentRoute} />

        <Footer
            showYears={true}
            theme={
                currentRoute === Routes.FORGOT_LOGIN || currentRoute === Routes.FORGOT_PASSWORD
                    ? Themes.whiteTheme
                    : Themes.defaultTheme
            }
        />
    </div>
);

const renderExplanation = (currentRoute: string, children: React.ReactNode) => (
    <ThemeProvider theme={Themes.whiteTheme} data-testid='theme-provider'>
        <div>
            <Page header={<AppHeader />} data-test-id='page'>
                <Content theme={Themes.whiteTheme} className='content--left'>
                    {children}
                </Content>
            </Page>
            {renderFooter(currentRoute)}
        </div>
    </ThemeProvider>
);

interface AppProps {
    children?: React.ReactNode;
}

const App: FC<AppProps> = ({ children }) => {
    const navigate = useNavigate();
    const [showComponent, setShowComponent] = useState(false);
    const { pathname, search } = useLocation();
    const currentRoute = getPathnameEndpoint(pathname);
    const login = useAppSelector(getAuthorizationLogin);
    const isWebview = useAppSelector(selectIsWebView);
    const isMobile = useAppSelector(selectIsMobile);
    const isAlfaIdBannerEnabled = useAppSelector(selectIsAlfaIdBannerEnabled);
    const isCorpAlboClientId = useAppSelector(selectIsCorpAlboClientId);
    const registrationType = useAppSelector(getRegistrationType);
    const previousMultiFactorResponseParams = useAppSelector(selectMultiFactorResponseParams);
    const oldBrowser = useAppSelector(selectOldBrowser);

    const isShowAlfaIdBanner =
        isAlfaIdBannerEnabled &&
        isCorpAlboClientId &&
        !isMobile &&
        (currentRoute === Routes.CORP_USERNAME || currentRoute === Routes.PASSWORD);

    useEffect(() => {
        setShowComponent(true);

        if (!isWebview && oldBrowser) {
            navigate({
                pathname: Routes.ERROR,
                search,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderHeadingText = () => {
        switch (currentRoute) {
            case Routes.CARD_ACCOUNT:
                return registrationType === RegistrationType.Card
                    ? [<div key={0}>Войти</div>, <div key={1}>по номеру карты</div>]
                    : [<div key={0}>Войти</div>, <div key={1}>по номеру счёта</div>];
            case Routes.AD_USERNAME_PASSWORD:
            case Routes.PHONE_LOGIN:
            case Routes.CORP_USERNAME:
            case Routes.LOGIN:
            case Routes.PASSWORD:
                return [<div key={0}>Вход</div>, <div key={1}>в Альфа-Бизнес</div>];
            case Routes.UPDATE:
                return [
                    <div key={0}>
                        Придумайте пароль для
                        <br />
                        {login}
                    </div>,
                    (previousMultiFactorResponseParams || {}).reason === reason.expired ? (
                        <Label key={1}>В целях безопасности его необходимо обновить</Label>
                    ) : null,
                ];
            case Routes.OFFER:
                return 'Условия оферты';
            default:
                return '';
        }
    };

    const renderTitle = () => <Heading size={HEADING_SIZE}>{renderHeadingText()}</Heading>;

    const renderHeader = () => <AppHeader />;

    if (showComponent) {
        return currentRoute === Routes.EXPLANATION ||
            currentRoute === Routes.FORGOT_LOGIN ||
            currentRoute === Routes.FORGOT_PASSWORD ? (
            renderExplanation(currentRoute, children)
        ) : (
            <ThemeProvider theme={Themes.defaultTheme}>
                <div className={cn('wrapper')}>
                    <div className={isWebview ? cn('webview') : cn()}>
                        <AppBackground />
                        <Page header={!isWebview && renderHeader()}>
                            {!isWebview && renderTitle()}
                            <Content theme={Themes.defaultTheme}>{children}</Content>
                            {!isWebview && renderFooter(currentRoute)}
                        </Page>
                    </div>
                    {isShowAlfaIdBanner && <AlfaIdBannerDesktop />}
                </div>
            </ThemeProvider>
        );
    }

    return null;
};

export default App;
