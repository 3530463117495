import React, { FC, useEffect } from 'react';
import { createCn } from 'bem-react-classname';

import { useConfirmation } from '@alfalab/core-components/confirmation';
import { ConfirmationDesktop } from '@alfalab/core-components/confirmation/desktop';

import BackButton from '#/src/shared/components/ui/back-button';
import { useAppDispatch, useAppSelector } from '#/src/shared/hooks';
import { trackUserEvent } from '#/src/shared/lib/analitycs';
import { SMS_INPUT_MAX_LENGTH } from '#/src/shared/lib/form-controls-const';
import { maskPhone } from '#/src/shared/lib/mask-phone';
import { FormStatus } from '#/src/shared/models';
import {
    useRequestOIDSmsVerificationMutation,
    useRequestReferenceBySmsMutation,
} from '#/src/shared/store/api/sms-verification-api';
import {
    formatMaskedPhoneNumber,
    getQueryRedirectParams,
} from '#/src/shared/store/redux/app/selectors';
import { getRegistrationPhone } from '#/src/shared/store/redux/registration/selectors';
import {
    selectSmsCode,
    selectSmsFormStatus,
    selectSmsRequestStatus,
    selectSmsServerError,
    selectSmsServerErrorMsg,
} from '#/src/shared/store/redux/sms-verification/selectors';
import {
    smsServerErrorNotificationClosed,
    smsVerificationFormUpdated,
    smsVerificationServerErrorsReset,
    smsVerificationStatusRequested,
    smsVerificationSubmit,
} from '#/src/shared/store/redux/sms-verification/slice';

import SmsHint from '../sms-hint';

import './sms-v2.css';

const cn = createCn('sms-v2');

const SmsV2: FC = () => {
    const dispatch = useAppDispatch();
    const [requestOIDSmsVerification] = useRequestOIDSmsVerificationMutation();
    const [requestReferenceBySms] = useRequestReferenceBySmsMutation();

    const formStatus = useAppSelector(selectSmsFormStatus);
    const requestStatus = useAppSelector(selectSmsRequestStatus);
    const code = useAppSelector(selectSmsCode);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const serverError = useAppSelector(selectSmsServerError);
    const serverErrorMessage = useAppSelector(selectSmsServerErrorMsg);
    const formatedMaskedPhoneNumber = useAppSelector(formatMaskedPhoneNumber);
    const phone = useAppSelector(getRegistrationPhone);
    const { confirmationState, confirmationScreen, setConfirmationState, setConfirmationScreen } =
        useConfirmation();

    const phoneNumber = formatedMaskedPhoneNumber
        ? `${formatedMaskedPhoneNumber.replace(/000 000/gi, '••• •••')}`
        : maskPhone(phone);

    useEffect(() => {
        requestReferenceBySms(code);

        trackUserEvent('Auth Page', 'Impression', 'Viewing Page', queryRedirectParams.client_id);

        return () => {
            dispatch(smsVerificationStatusRequested(true));
            dispatch(smsServerErrorNotificationClosed());
            dispatch(smsVerificationFormUpdated({ code: '' }));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (serverError) setConfirmationState('CODE_ERROR');
        else setConfirmationState('INITIAL');
    }, [setConfirmationState, serverError]);

    useEffect(() => {
        if (formStatus === FormStatus.ValidationSuccess && code.length === 4) {
            setConfirmationState('CODE_CHECKING');
            requestOIDSmsVerification({
                code,
                options: {
                    mobile: true,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code, formStatus]);

    useEffect(() => {
        if (serverError) {
            trackUserEvent(
                'Auth Page',
                'Click',
                'SMS OTP Send',
                queryRedirectParams.client_id,
                `Error ${serverErrorMessage}`,
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serverError]);

    const handleInputFinished = (value: string) => {
        if (value.length === SMS_INPUT_MAX_LENGTH && requestStatus) {
            trackUserEvent(
                'Auth Page',
                'Click',
                'SMS OTP Send',
                queryRedirectParams.client_id,
                'Send',
            );

            if (serverError || serverErrorMessage) {
                dispatch(smsVerificationServerErrorsReset());
            }

            dispatch(smsVerificationFormUpdated({ code: value }));
            dispatch(
                smsVerificationSubmit({
                    code: value,
                }),
            );
        } else {
            setConfirmationState('CODE_ERROR');
        }
    };

    const handleRepeatSms = () => {
        if (serverError) {
            dispatch(smsVerificationServerErrorsReset());
        }
        if (!requestStatus) {
            dispatch(smsVerificationStatusRequested(true));
        }
        if (code) {
            dispatch(smsVerificationFormUpdated({ code: '' }));
        }
        trackUserEvent('Auth Page', 'Click', 'Resend OTP', queryRedirectParams.client_id);
        requestReferenceBySms(code);

        // Чтобы компонента блокировала повторную отправку смс после его нажатия
        setTimeout(() => {
            setConfirmationState('INITIAL');
        }, 1000);
    };

    const handleHintButtonClick = () => {
        setConfirmationScreen('INITIAL');
        setConfirmationState('INITIAL');
    };

    return (
        <React.Fragment>
            <div className={cn('back-button')}>
                <BackButton />
            </div>
            <ConfirmationDesktop
                className={cn('confirmation')}
                alignContent='left'
                phone={phoneNumber}
                screen={confirmationScreen}
                state={confirmationState}
                onChangeState={setConfirmationState}
                onChangeScreen={setConfirmationScreen}
                onInputFinished={handleInputFinished}
                onSmsRetryClick={handleRepeatSms}
                countdownDuration={60_000}
                clearCodeOnError={false}
                requiredCharAmount={SMS_INPUT_MAX_LENGTH}
                texts={{
                    title: 'Введите код из сообщения',
                    codeError: serverErrorMessage,
                }}
                getScreensMap={(screensMap) => ({
                    ...screensMap,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    HINT: () => <SmsHint mobile={false} onButtonClick={handleHintButtonClick} />,
                })}
            />
        </React.Fragment>
    );
};

export default SmsV2;
