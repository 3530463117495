import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createCn } from 'bem-react-classname';

import Button from 'arui-feather/button';
import { GenericConfirmationModal } from 'arui-private/core/generic-confirmation-modal/generic-confirmation-modal';

import { useAppDispatch, useAppSelector } from '#/src/shared/hooks';
import { Routes } from '#/src/shared/models';
import { useRequestOIDAuthByLoginMutation } from '#/src/shared/store/api/authorization-api';
import { getQueryRedirectParams } from '#/src/shared/store/redux/app/selectors';
import { passwordSet } from '#/src/shared/store/redux/app/slice';
import { getAuthorizationLogin } from '#/src/shared/store/redux/authorization/selectors';
import { selectSmsCode } from '#/src/shared/store/redux/sms-verification/selectors';
import { smsVerificationFormUpdated } from '#/src/shared/store/redux/sms-verification/slice';

import './temp-password-by-sms.css';

const cn = createCn('passport-recovery-sms');

const TempPasswordBySms: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { search } = useLocation();

    const [requestOIDAuthByLogin] = useRequestOIDAuthByLoginMutation();
    const code = useAppSelector(selectSmsCode);
    const login = useAppSelector(getAuthorizationLogin);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);

    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => {
        dispatch(passwordSet(code));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleInputChange = (value?: string) => {
        dispatch(smsVerificationFormUpdated({ code: value }));
    };

    const handleClick = (event?: React.MouseEvent) => {
        if (event) {
            event.stopPropagation();
        } else {
            navigate({
                pathname: Routes.FORGOT_PASSWORD,
                search,
            });
        }
    };

    const handleInputFinished = () => {
        setIsProcessing(true);
        requestOIDAuthByLogin({
            username: login,
            password: code,
            queryRedirectParams,
        });
    };

    const handleBtnClick = () => {
        requestOIDAuthByLogin({
            username: login,
            password: code,
            queryRedirectParams,
        });
    };

    const isProcessingHandler = () => {
        setIsProcessing(true);
    };

    return (
        <GenericConfirmationModal
            isProcessing={isProcessing}
            isVisible={true}
            onClose={handleClick}
            onInputFinished={handleInputFinished}
            onInputChange={handleInputChange}
            requiredCharAmount={32}
            onSmsRetryClick={isProcessingHandler}
            additionalContent={
                <Button
                    onClick={handleBtnClick}
                    size='l'
                    view='extra'
                    width='available'
                    className={cn('button')}
                >
                    Подтвердить
                </Button>
            }
            signTitle='Введите пароль из sms'
        />
    );
};

export default TempPasswordBySms;
