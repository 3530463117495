import React from 'react';

export const CatMessage = () => (
    <svg width='89' height='84' viewBox='0 0 89 84' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M73.951 21.1802C69.051 13.5492 61.554 8.11273 56.5922 8.8838C51.6322 9.66273 50.6426 18.6104 44.0745 21.5083C43.229 21.8012 40.9403 22.1405 40.0892 22.1401C35.1784 22.6933 29.19 13.4918 24.4921 14.1904C19.5307 14.936 13.8535 22.1695 11.5178 30.9316C8.72716 41.4255 9.89277 51.3303 9.89277 51.3303C9.93183 51.9249 9.99633 52.5202 10.0863 53.1161C12.378 67.7392 30.3829 77.0574 50.3109 73.9311C70.2314 70.8078 84.5269 56.4255 82.2353 41.802C82.178 41.4236 82.1056 41.0498 82.0258 40.6783C82.0258 40.6783 79.8241 30.3076 73.9567 21.17L73.951 21.1802Z'
            fill='url(#paint0_linear_192_222292)'
        />
        <g opacity='0.7'>
            <path
                d='M73.4603 36.4543C74.5213 42.6082 62.6263 49.4306 46.888 51.685C31.1574 53.9371 17.541 50.7724 16.478 44.6108C15.4173 38.4568 27.3728 31.9698 43.1033 29.7177C58.834 27.4656 72.3918 30.3028 73.4551 36.4644L73.4603 36.4543Z'
                fill='url(#paint1_radial_192_222292)'
            />
        </g>
        <path
            d='M37.0876 47.5851C35.9841 46.6511 34.5177 46.1823 32.9983 46.4205C31.2053 46.7005 29.7796 47.9042 29.0992 49.4776'
            stroke='black'
            strokeWidth='2.73735'
            strokeLinecap='round'
        />
        <path
            d='M73.9507 21.1815C69.0502 13.5507 61.438 8.14954 56.4761 8.92061C51.5166 9.69938 50.5267 18.6472 43.9586 21.5451C43.1131 21.8379 40.8244 22.1772 39.9733 22.1769C35.3806 22.641 29.2456 13.1811 24.2859 13.9599C19.3262 14.7388 13.7299 22.2086 11.3965 30.9784C8.60592 41.4723 9.77152 51.3771 9.77152 51.3771C9.81057 51.9717 9.87507 52.567 9.96505 53.1629C12.2568 67.786 30.2616 77.104 50.1898 73.9783C70.1103 70.8549 84.4056 56.472 82.1141 41.8488C82.0567 41.4704 81.9844 41.0966 81.9045 40.7251C81.9045 40.7251 79.8182 30.319 73.9507 21.1815Z'
            fill='url(#paint2_radial_192_222292)'
        />
        <path
            d='M56.0791 43.9889C56.8403 42.7615 58.098 41.862 59.6096 41.6261C61.4026 41.3461 63.1258 42.048 64.2564 43.3444'
            stroke='black'
            strokeWidth='2.73735'
            strokeLinecap='round'
        />
        <path
            opacity='0.4'
            d='M56.5401 63.8247C60.4193 62.6356 62.6001 58.5268 61.411 54.6476C60.2219 50.7684 56.1133 48.5876 52.2341 49.7767C48.3549 50.9658 46.1741 55.0744 47.3632 58.9536C48.5522 62.8328 52.6609 65.0137 56.5401 63.8247Z'
            fill='url(#paint3_radial_192_222292)'
        />
        <path
            opacity='0.4'
            d='M45.3646 65.7894C49.2437 64.6004 51.4245 60.4917 50.2355 56.6125C49.0464 52.7333 44.9378 50.5525 41.0586 51.7415C37.1794 52.9306 34.9986 57.0392 36.1877 60.9185C37.3767 64.7977 41.4854 66.9785 45.3646 65.7894Z'
            fill='url(#paint4_radial_192_222292)'
        />
        <path
            d='M51.7041 51.8671C51.8718 53.4586 50.5956 55.5348 48.6059 55.9426C46.5795 56.2857 44.6683 54.6135 44.4468 52.9289C44.1747 51.024 45.479 50.1944 47.4577 49.7228C49.4386 49.2588 51.5009 49.7472 51.7094 51.857L51.7041 51.8671Z'
            fill='black'
        />
        <path
            d='M48.2532 53.911L49.1003 59.3132C49.4305 61.1329 51.1339 62.1274 52.617 62.5406C56.9936 63.7351 58.7001 59.6818 59.065 59.0307'
            stroke='black'
            strokeWidth='2.51784'
            strokeLinecap='round'
        />
        <path
            d='M48.1844 54.2936L48.9266 59.711C49.1343 61.543 47.792 62.9908 46.4852 63.8127C42.6378 66.2136 39.8359 62.8176 39.3059 62.2977'
            stroke='black'
            strokeWidth='2.51784'
            strokeLinecap='round'
        />
        <path
            d='M66.5653 22.2871C63.9422 16.5615 61.2696 12.789 57.4835 12.8626C53.739 12.932 52.8066 20.7753 50.7549 21.421C57.0421 21.5917 62.8669 22.949 67.7228 25.294C67.4004 24.2975 67.0247 23.2921 66.5653 22.2871Z'
            fill='black'
        />
        <path
            d='M24.2688 17.8291C20.642 18.9155 19.2579 23.3334 18.5121 29.5861C18.4122 30.4423 18.3533 31.2945 18.3359 32.1171C22.352 28.6449 27.4791 25.8011 33.3372 23.9128C31.1914 24.0314 27.8856 16.7373 24.2665 17.8214L24.2688 17.8291Z'
            fill='black'
        />
        <defs>
            <linearGradient
                id='paint0_linear_192_222292'
                x1='9.76864'
                y1='52.7669'
                x2='79.3379'
                y2='31.4423'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#FF0000' />
                <stop offset='0.19' stopColor='#FF050A' />
                <stop offset='0.68' stopColor='#FF101F' />
                <stop offset='1' stopColor='#FF1527' />
            </linearGradient>
            <radialGradient
                id='paint1_radial_192_222292'
                cx='0'
                cy='0'
                r='1'
                gradientUnits='userSpaceOnUse'
                gradientTransform='translate(40.699 43.0531) rotate(-8.06148) scale(28.8624 10.9677)'
            >
                <stop stopColor='#FFD9E1' />
                <stop offset='0.09' stopColor='#FFE1E7' stopOpacity='0.91' />
                <stop offset='0.4' stopColor='white' stopOpacity='0.6' />
                <stop offset='0.48' stopColor='#FDF6F6' stopOpacity='0.58' />
                <stop offset='0.58' stopColor='#F7DEDE' stopOpacity='0.52' />
                <stop offset='0.69' stopColor='#EEB7B7' stopOpacity='0.43' />
                <stop offset='0.8' stopColor='#E18080' stopOpacity='0.3' />
                <stop offset='0.92' stopColor='#D03A3A' stopOpacity='0.14' />
                <stop offset='1' stopColor='#C30000' stopOpacity='0' />
            </radialGradient>
            <radialGradient
                id='paint2_radial_192_222292'
                cx='0'
                cy='0'
                r='1'
                gradientUnits='userSpaceOnUse'
                gradientTransform='translate(38.3352 40.07) rotate(-8.07147) scale(38.7865 32.1928)'
            >
                <stop offset='0.24' stopColor='#FF0000' stopOpacity='0' />
                <stop offset='0.27' stopColor='#FA0000' stopOpacity='0.06' />
                <stop offset='0.51' stopColor='#DC0000' stopOpacity='0.46' />
                <stop offset='0.71' stopColor='#C50000' stopOpacity='0.75' />
                <stop offset='0.85' stopColor='#B80000' stopOpacity='0.93' />
                <stop offset='0.94' stopColor='#B30001' />
            </radialGradient>
            <radialGradient
                id='paint3_radial_192_222292'
                cx='0'
                cy='0'
                r='1'
                gradientUnits='userSpaceOnUse'
                gradientTransform='translate(54.3555 56.8116) rotate(-9.08147) scale(7.34647)'
            >
                <stop stopColor='#FFD9E1' />
                <stop offset='0.09' stopColor='#FFE1E7' stopOpacity='0.91' />
                <stop offset='0.4' stopColor='white' stopOpacity='0.6' />
                <stop offset='0.48' stopColor='#FDF6F6' stopOpacity='0.58' />
                <stop offset='0.58' stopColor='#F7DEDE' stopOpacity='0.52' />
                <stop offset='0.69' stopColor='#EEB7B7' stopOpacity='0.43' />
                <stop offset='0.8' stopColor='#E18080' stopOpacity='0.3' />
                <stop offset='0.92' stopColor='#D03A3A' stopOpacity='0.14' />
                <stop offset='1' stopColor='#C30000' stopOpacity='0' />
            </radialGradient>
            <radialGradient
                id='paint4_radial_192_222292'
                cx='0'
                cy='0'
                r='1'
                gradientUnits='userSpaceOnUse'
                gradientTransform='translate(43.1663 58.7683) rotate(-9.08147) scale(7.34647)'
            >
                <stop stopColor='#FFD9E1' />
                <stop offset='0.09' stopColor='#FFE1E7' stopOpacity='0.91' />
                <stop offset='0.4' stopColor='white' stopOpacity='0.6' />
                <stop offset='0.48' stopColor='#FDF6F6' stopOpacity='0.58' />
                <stop offset='0.58' stopColor='#F7DEDE' stopOpacity='0.52' />
                <stop offset='0.69' stopColor='#EEB7B7' stopOpacity='0.43' />
                <stop offset='0.8' stopColor='#E18080' stopOpacity='0.3' />
                <stop offset='0.92' stopColor='#D03A3A' stopOpacity='0.14' />
                <stop offset='1' stopColor='#C30000' stopOpacity='0' />
            </radialGradient>
        </defs>
    </svg>
);
