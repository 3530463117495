import { checkSecretDate } from '#/src/shared/lib/check-browser-secret';

export const checkPincodeParams = (
    browserId?: string,
    browserSecret?: string | null,
    browserSecretDate?: string | null,
    newBrowserSecret?: string,
    clientId?: string,
) => {
    let logMessage = '';

    if (browserId) {
        logMessage = `BrowserID ${browserId}`;
    } else {
        logMessage = 'BrowserID not found';
    }

    if (browserSecret) {
        logMessage = `${logMessage}, secret in localstorage found`;
    } else {
        logMessage = `${logMessage}, secret in localstorage not found`;
    }

    if (browserSecretDate && checkSecretDate(browserSecretDate)) {
        logMessage = `${logMessage}, secret_date valid`;
    } else if (browserSecretDate && !checkSecretDate(browserSecretDate)) {
        logMessage = `${logMessage}, secret_date outdated`;
    } else {
        logMessage = `${logMessage}, secret_date not found`;
    }

    if (newBrowserSecret) {
        logMessage = `${logMessage}, new secret in redux found`;
    } else {
        logMessage = `${logMessage}, new secret in redux not found`;
    }

    if (clientId) {
        logMessage = `${logMessage}, clientId: ${clientId}`;
    } else {
        logMessage = `${logMessage}, clientId: not found`;
    }

    return logMessage;
};
