import { BaseQueryApi } from '@reduxjs/toolkit/query';

import { AppDispatch } from '#/src/shared/hooks';
import { clientErrorLog } from '#/src/shared/lib/client-logger';
import { isClickDesign, selectIsFingerPrintEnabled } from '#/src/shared/store/redux/app/selectors';
import { fingerprintSet } from '#/src/shared/store/redux/fingerprint/slice';
import { FingerprintState } from '#/src/shared/store/redux/fingerprint/types';
import { ApplicationState } from '#/src/shared/store/types';
import { FHP_SCRIPT_ID } from '#/src/shared/utils';

export const syncWithFpScript = async (setFP: boolean, dispatch: AppDispatch) => {
    let fingerprint: FingerprintState | undefined;

    await new Promise<void>((resolve) => {
        const timeoutId = setTimeout(() => {
            console.error('FHP script is loading too long');
            resolve();
        }, 3_000);

        const handleFinishLoading = () => {
            clearTimeout(timeoutId);
            resolve();
        };

        if (window?.gib?.getFP) {
            handleFinishLoading();

            return;
        }

        const script = document.getElementById(FHP_SCRIPT_ID) as HTMLScriptElement | null;

        if (script) {
            script.onload = handleFinishLoading;
            script.onerror = () => {
                handleFinishLoading();
                // eslint-disable-next-line no-console
                console.error('FHP script error');
            };
        }
    });

    if (setFP) {
        try {
            fingerprint = await window?.gib?.getFP();
        } catch (err) {
            const error = new Error(`FHP script initialize error: ${err}`);

            await clientErrorLog(error);
        }

        if (fingerprint) {
            dispatch(fingerprintSet(fingerprint));
        }
    }
};

export const initFP = async (api: BaseQueryApi) => {
    const dispatch = api.dispatch as AppDispatch;
    const state = api.getState() as ApplicationState;

    const isFingerPrintEnabled = selectIsFingerPrintEnabled(state);

    /** Проверка доступности фичи и clientId */
    if (isFingerPrintEnabled && isClickDesign(state)) {
        await syncWithFpScript(true, dispatch);
    }
};
