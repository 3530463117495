import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createCn } from 'bem-react-classname';

import Button from 'arui-feather/button';
import FormField from 'arui-feather/form-field';
import Heading from 'arui-feather/heading';
import Input from 'arui-feather/input';
import Link from 'arui-feather/link';
import Paragraph from 'arui-feather/paragraph';

import { useAppDispatch, useAppSelector } from '#/src/shared/hooks';
import { validateLogin } from '#/src/shared/lib/client-validation/authorization';
import {
    CORP_LOGIN_INPUT_MAX_LENGTH,
    CORP_LOGIN_INPUT_MIN_LENGTH,
    FORM_BUTTON_SIZE,
    FORM_FIELD_SIZE,
    FORM_INPUT_SIZE,
    FORM_LINK_SIZE_M,
    HEADING_SIZE,
    LOGIN_INPUT_MAX_LENGTH,
    LOGIN_INPUT_MIN_LENGTH,
} from '#/src/shared/lib/form-controls-const';
import { PasswordTypes, Routes, ValidationType } from '#/src/shared/models';
import { useRequestGetTempPasswordAlboMutation } from '#/src/shared/store/api/authorization-api';
import { selectIsCorporateClientId } from '#/src/shared/store/redux/app/selectors';
import {
    getAuthorizationFormError,
    getAuthorizationLogin,
    getAuthorizationMaskedLogin,
    getAuthorizationPasswordType,
} from '#/src/shared/store/redux/authorization/selectors';
import {
    authorizationErrorUpdated,
    authorizationFormUpdated,
    authorizationValidated,
} from '#/src/shared/store/redux/authorization/slice';

import './forgot-password.css';

const cn = createCn('forgot-password');

const ForgotPassword: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { search } = useLocation();
    const [requestGetTempPasswordAlbo] = useRequestGetTempPasswordAlboMutation();
    const isCorporateClientId = useAppSelector(selectIsCorporateClientId);
    const login = useAppSelector(getAuthorizationLogin);
    const maskedLogin = useAppSelector(getAuthorizationMaskedLogin);
    const error = useAppSelector(getAuthorizationFormError);
    const passwordType = useAppSelector(getAuthorizationPasswordType);

    const minLoginLength = isCorporateClientId
        ? CORP_LOGIN_INPUT_MIN_LENGTH
        : LOGIN_INPUT_MIN_LENGTH;

    const maxLoginLength = isCorporateClientId
        ? CORP_LOGIN_INPUT_MAX_LENGTH
        : LOGIN_INPUT_MAX_LENGTH;

    const getRussiaPhone = () => (isCorporateClientId ? '8 (800) 100-77-33' : '8 (800) 200-00-00');
    const getMoscowPhone = () =>
        isCorporateClientId ? '+7 (495) 755-58-58' : '+7 (495) 78-888-78';

    const [showTempPassword, setShowTempPassword] = useState(false);

    useEffect(() => {
        dispatch(
            authorizationErrorUpdated({
                id: null,
                message: null,
                status: null,
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleValidateLogin = (loginValue: string) => {
        const validationStatus = validateLogin(loginValue, isCorporateClientId);

        if (validationStatus !== null) {
            dispatch(
                authorizationValidated({
                    validationStatus,
                    validationType: ValidationType.Login,
                }),
            );
        }
    };

    const handleChangeLogin = (value = '') => {
        let loginValue = value;

        if (maskedLogin && maskedLogin !== loginValue) {
            if (loginValue.length === maskedLogin.length + 1) {
                // typing
                dispatch(
                    authorizationFormUpdated({
                        maskedLogin: '',
                    }),
                );
                loginValue = login.slice(-1);
            } else if (loginValue.length > maskedLogin.length + 5) {
                // pasting
                dispatch(
                    authorizationFormUpdated({
                        maskedLogin: '',
                    }),
                );
                loginValue = loginValue.slice(maskedLogin.length);
            } else {
                dispatch(
                    authorizationFormUpdated({
                        maskedLogin: '',
                    }),
                );
                loginValue = '';
            }
        }
        if (error.login) {
            dispatch(
                authorizationErrorUpdated({
                    login: null,
                }),
            );
        }

        if (value.length >= minLoginLength) {
            handleValidateLogin(value);
        }

        dispatch(
            authorizationFormUpdated({
                login: value,
            }),
        );
    };

    const handleGetTempPassword = (event?: React.MouseEvent<any, MouseEvent>) => {
        event?.preventDefault();
        requestGetTempPasswordAlbo({ login });
    };

    const handleShowButton = () => {
        setShowTempPassword(!showTempPassword);
    };

    const renderFormGetButton = () => (
        <div className={cn('get-temp')}>
            <Input
                id='login-input'
                className={cn('login')}
                name='username'
                value={maskedLogin || login}
                maxLength={maxLoginLength}
                label='Логин'
                size={FORM_INPUT_SIZE}
                error={error.message}
                resetError={false}
                onChange={handleChangeLogin}
            />
            <Button
                view='extra'
                size={FORM_BUTTON_SIZE}
                className={cn('btn')}
                onClick={handleGetTempPassword}
                text='Получить новый пароль'
            />
        </div>
    );

    const handleBtnClick = (event?: React.MouseEvent<any, MouseEvent>) => {
        event?.preventDefault();
        navigate({
            pathname: Routes.PASSWORD,
            search,
        });
    };

    return (
        <div className={cn()}>
            <Heading size={HEADING_SIZE}>Восстановление пароля</Heading>
            {passwordType === PasswordTypes.passWithConfirmLogin ? (
                <Paragraph className={cn('text')}>
                    Для восстановления пароля, выданного вам в отделении
                    <br />
                    проверьте свой логин и нажмите «Получить новый пароль».
                    <br />
                    <br />
                    Вы можете восстановить пароль, позвонив в службу поддержки.
                </Paragraph>
            ) : (
                <Paragraph className={cn('text')}>
                    Чтобы восстановить пароль, позвоните в службу поддержки:
                    <br />
                    <Link
                        size={FORM_LINK_SIZE_M}
                        url={`tel:${getRussiaPhone()}`}
                        className={cn('phone')}
                        text={getRussiaPhone()}
                    />{' '}
                    для звонков по России,
                    <br />
                    <Link
                        size={FORM_LINK_SIZE_M}
                        url={`tel:${getRussiaPhone()}`}
                        className={cn('phone')}
                        text={getMoscowPhone()}
                    />{' '}
                    для Москвы и заграницы.
                    <br />
                    Для его восстановления потребуется кодовое слово.
                    <br />
                    <br />
                    Если ранее вы уже получали временный пароль по СМС,
                    <br />
                    то можете{' '}
                    <Link
                        size={FORM_LINK_SIZE_M}
                        pseudo={true}
                        className={cn('phone')}
                        text='получить новый временный пароль'
                        onClick={handleShowButton}
                    />
                    .
                </Paragraph>
            )}
            <FormField size={FORM_FIELD_SIZE} className={cn('form-field')}>
                {showTempPassword ? renderFormGetButton() : null}
                <Button
                    size={FORM_BUTTON_SIZE}
                    className={cn('btn')}
                    onClick={handleBtnClick}
                    text='Вернуться назад'
                />
            </FormField>
        </div>
    );
};

export default ForgotPassword;
