import { createSlice } from '@reduxjs/toolkit';

import { SettingsInitialState } from '#/src/shared/store/redux/setting/constants';

export const SettingsSlice = createSlice({
    name: 'settings',
    initialState: SettingsInitialState,
    reducers: {},
});

export default SettingsSlice.reducer;
