import React from 'react';
import { useNavigate } from 'react-router';
import { createCn } from 'bem-react-classname';
import noop from 'lodash/noop';

import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowBackMIcon } from '@alfalab/icons-glyph/ArrowBackMIcon';

import { useAppSelector } from '#/src/shared/hooks';
import { sendMetrics } from '#/src/shared/lib/analitycs';
import { getMetricsData } from '#/src/shared/lib/get-metrics-data';
import { ButtonNames } from '#/src/shared/models';
import {
    getQueryRedirectParams,
    selectIsMobile,
    selectMetricsDebugEnabled,
} from '#/src/shared/store/redux/app/selectors';
import { BaseComponent } from '#/src/shared/types/interfaces';

import './back-button.css';

interface Props {
    onClick?(): void;
    backUrl?: string;
}

const Icon: React.FC<BaseComponent> = ({ className }) => (
    <span className={className}>
        <ArrowBackMIcon />
    </span>
);

const cn = createCn('back-button');

const BackButton: React.FC<Props> = ({ onClick = noop, backUrl }) => {
    const navigate = useNavigate();
    const isMobile = useAppSelector(selectIsMobile);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const metricsData = getMetricsData(queryRedirectParams, isMobile);
    const isMetricsDebugEnabled = useAppSelector(selectMetricsDebugEnabled);

    const onClickHandler = () => {
        sendMetrics('Button back', 'Click', metricsData, isMetricsDebugEnabled);

        if (backUrl) {
            window.location.replace(backUrl);
        } else {
            onClick();
            navigate(-1);
        }
    };

    return (
        <button type='button' data-test-id='back-button' className={cn()} onClick={onClickHandler}>
            <Icon className={cn('icon')} />
            <Gap size='xs' direction='horizontal' />
            <Typography.Text view='primary-medium' weight='medium'>
                {ButtonNames.back}
            </Typography.Text>
        </button>
    );
};

export default BackButton;
