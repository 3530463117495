import { isBase64url, parseBase64url, toBase64url } from '#/src/shared/lib/base64-utils';

import { CookiesName } from '../models';

import getCookie from './get-cookie';

export function webauthnParseRegChallenge(params: any) {
    const parsedParams = JSON.parse(JSON.stringify(params));

    if (!isBase64url(params.challenge))
        throw new Error('Provided challenge is not properly encoded in Base64url');
    parsedParams.challenge = parseBase64url(params.challenge);
    parsedParams.timeout = +params.timeout;
    parsedParams.user.id = parseBase64url(params.user.id);
    parsedParams.extensions.appidExclude =
        params.extensions.appidExclude !== null ? params.extensions.appidExclude : undefined;
    parsedParams.excludeCredentials = [];

    return parsedParams;
}


export function webauthnParseAuthChallenge(params: any) {
    const jsonDecode = JSON.parse(JSON.stringify(params));
    const parsedParams = jsonDecode.publicKey;

    if (!isBase64url(params.publicKey.challenge))
        throw new Error('Provided challenge is not properly encoded in Base64url');
    parsedParams.challenge =  parseBase64url(params.publicKey.challenge);
    parsedParams.timeout = +params.publicKey.timeout;
    if (parsedParams.allowCredentials) {
        parsedParams.allowCredentials = params.publicKey.allowCredentials.map((key: any) => ({
            ...key,
            id: parseBase64url(key.id),
        }));
    }

    return parsedParams;
}

export function webAuthnParseReg(params: any) {
    const rawId = toBase64url(params.rawId);
    const attestationObject = toBase64url(params.response.attestationObject);
    const clientDataJSON = toBase64url(params.response.clientDataJSON);

    return {
        authenticatorAttachment: params.authenticatorAttachment,
        credential: {
            id: params.id,
            rawId,
            response: {
                attestationObject,
                clientDataJSON,
            },
            type: params.type,
            clientExtensionResults: {},
        },
    };
}

export interface WebAuthnParseAuthParams {
    authenticatorAttachment: string;
    id: string;
    rawId: ArrayBuffer;
    response: any;
    type: string;
}

export function webAuthnParseAuth(params: WebAuthnParseAuthParams) {
    const rawId = toBase64url(params.rawId);
    const clientDataJSON = toBase64url(params.response.clientDataJSON);
    const authenticatorData = toBase64url(params.response.authenticatorData);
    const signature = toBase64url(params.response.signature);
    const userHandle = toBase64url(params.response.userHandle);
    const browserId = getCookie(CookiesName.browserId);

    return {
        authenticatorAttachment: params.authenticatorAttachment,
        id: params.id,
        rawId,
        authenticatorData,
        clientDataJSON,
        signature,
        userHandle,
        type: params.type,
        clientExtensionResults: {},
        ...(browserId && {browser_id: browserId}),
    };
}
