import React from 'react';
import { Route, Routes } from 'react-router-dom';

import AKeyAuth from '#/src/mobile/pages/akey-auth';
import Biometry from '#/src/mobile/pages/biometry';
import FastRegistration from '#/src/mobile/pages/fast-registration';
import PincodeMobile from '#/src/mobile/pages/pincode-mobile';
import WebAuthn from '#/src/mobile/pages/webauthn';
import WebAuthnReg from '#/src/mobile/pages/webauthn-reg';
import App from '#/src/shared/pages/app';
import CardAccount from '#/src/shared/pages/card-account';
import CardAccountNotClient from '#/src/shared/pages/card-account-not-client/card-account-not-client';
import DemoUserSignin from '#/src/shared/pages/demouser-signin/demouser-signin';
import Error from '#/src/shared/pages/error';
import Explanation from '#/src/shared/pages/explanation';
import FinishSignIn from '#/src/shared/pages/finish-signin';
import ForgotLogin from '#/src/shared/pages/forgot-login';
import ForgotPassword from '#/src/shared/pages/forgot-password';
import FullNameNotClient from '#/src/shared/pages/full-name-not-client';
import FullNameRegistration from '#/src/shared/pages/full-name-registration';
import Investments from '#/src/shared/pages/investments';
import JWTAuth from '#/src/shared/pages/jwt-auth';
import Login from '#/src/shared/pages/login';
import Offer from '#/src/shared/pages/offer/offer';
import Passcode from '#/src/shared/pages/passcode';
import Password from '#/src/shared/pages/password';
import PhoneLogin from '#/src/shared/pages/phone';
import PhoneAuth from '#/src/shared/pages/phone-auth';
import Recovery from '#/src/shared/pages/recovery';
import Sms from '#/src/shared/pages/sms';
import TempPassword from '#/src/shared/pages/temp-password-auth';
import TempPasswordBySms from '#/src/shared/pages/temp-password-by-sms';

import { Routes as ROUTES } from '#/src//shared/models';

// TODO: <Route path='*' element={<Error />} />
// TODO: add Code-Splitting - PASSPORT-7738
// TODO: удалить неактуальные страницы PASSPORT-7974
const MobileRoutes = () => (
    <Routes>
        <Route path='/' element={<App />}>
            <Route path={ROUTES.AKEY} element={<AKeyAuth />} />
            <Route path={ROUTES.WEBAUTHN} element={<WebAuthn />} />
            {/* Секретный код АО */}
            <Route path={ROUTES.PINCODE} element={<PincodeMobile />} />
            <Route path={ROUTES.TEMP_PASSWORD} element={<TempPassword />} />
            <Route path={ROUTES.PHONE_AUTH} element={<PhoneAuth />} />
            <Route path={ROUTES.LOGIN} element={<Login />} />
            <Route path={ROUTES.CARD_ACCOUNT} element={<CardAccount />} />
            <Route path={ROUTES.SMS} element={<Sms />} />

            {/* Секретный код АМ */}
            <Route path={ROUTES.PASSCODE} element={<Passcode />} />
            {/* Лицевая биометрия АМ */}
            <Route path={ROUTES.BIOMETRY} element={<Biometry />} />
            <Route path={ROUTES.FAST_REGISTRATION} element={<FastRegistration />} />

            {/* Авторизация по токену jwt для неклиентов */}
            <Route path={ROUTES.JWT_AUTH} element={<JWTAuth />} />
            {/* Авторизация по фио + номер телефона из анкеты */}
            <Route path={ROUTES.FULLNAME_REGISTRATION} element={<FullNameRegistration />} />
            {/* Для восстановления доступа */}
            <Route path={ROUTES.RESTORE} element={<CardAccount />} />

            {/* Вход в Альфа-Инвестиции / Альфа Директ */}
            <Route path={ROUTES.AD_USERNAME_PASSWORD} element={<Investments />} />
            {/* Вход для демо пользователей Альфа-Инвестиции / Альфа Директ */}
            <Route path={ROUTES.DIRECT_DEMO_USER} element={<DemoUserSignin />} />

            <Route path={ROUTES.AZON} element={<Login />} />
            <Route path={ROUTES.EMPLOYEES} element={<Login />} />

            {/* Страница для корректной обработки внешнего редиректа */}
            <Route path={ROUTES.FINISH_SIGNIN} element={<FinishSignIn />} />
            {/* Для регистрации WebAuthn на тесте */}
            <Route path={ROUTES.WEBAUTHN_REG} element={<WebAuthnReg />} />

            <Route path={ROUTES.ERROR} element={<Error />} />

            {/* TODO: выпилить с уходом корпов PASSPORT-7981 */}
            <Route path={ROUTES.CORP_USERNAME} element={<Login />} />
            <Route path={ROUTES.CORP_SMS} element={<Sms />} />
            <Route path={ROUTES.PHONE_LOGIN} element={<PhoneLogin />} />
            <Route path={ROUTES.PASSWORD} element={<Password />} />
            <Route path={ROUTES.TEMP_PASSWORD_BY_SMS} element={<TempPasswordBySms />} />
            <Route path={ROUTES.OFFER} element={<Offer />} />
            <Route path={ROUTES.EXPLANATION} element={<Explanation />} />
            <Route path={ROUTES.FULL_NAME_NOT_CLIENT} element={<FullNameNotClient />} />
            <Route path={ROUTES.CARD_ACCOUNT_NOT_CLIENT} element={<CardAccountNotClient />} />
            <Route path={ROUTES.UPDATE} element={<Recovery />} />
            <Route path={ROUTES.FORGOT_LOGIN} element={<ForgotLogin />} />
            <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
        </Route>
    </Routes>
);

export default MobileRoutes;
