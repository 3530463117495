import { FingerprintState } from '#/src/shared/store/redux/fingerprint/types';

export const FingerprintInitialState: FingerprintState = {
    audioContextFingerprint: '',
    canvasFingerprintV1: '',
    canvasFingerprintV2: '',
    cpuCore: 0,
    isJavaEnabled: false,
    language: '',
    memory: 0,
    screenColorDepth: 0,
    screenHeight: 0,
    screenWidth: 0,
    timeZone: 0,
    userAgent: '',
    webglFingerprintV1: '',
    webglFingerprintV2: '',
    webglFingerprintVRenderer: '',
    webglRenderer: '',
    webglVendor: '',
    webglVersion: {
        sharingLangVersion: '',
        vendor: '',
        version: '',
    },
};
