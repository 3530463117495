import { createSelector } from 'reselect';

import getCookie from '#/src/shared/lib/get-cookie';
import { CookiesName } from '#/src/shared/models';
import { selectIsMobile, selectIsWebAuthnEnabled } from '#/src/shared/store/redux/app/selectors';
import { ApplicationState } from '#/src/shared/store/types';

export const getWebAuthnState = (state: ApplicationState) => state.WebAuthnAuthorization;

export const selectWebAuthnError = createSelector([getWebAuthnState], (webAuthn) => webAuthn.error);

export const getWebAuthnFlag = (): boolean => {
    // Скрываем на время SSR
    if (typeof document === 'undefined') return false;

    const webAuthnFlag = getCookie(CookiesName.webAuthnFlag);
    const webAuthnUserId = getCookie(CookiesName.webAuthnUserId);

    return Boolean(webAuthnUserId && webAuthnFlag === 'true');
};

export const selectIsWebauthnAvailable = createSelector(
    [selectIsMobile, selectIsWebAuthnEnabled],
    (isMobile, isWebAuthnEnabled) => {
        const webAuthnFlag = getWebAuthnFlag();

        return Boolean(isMobile && webAuthnFlag && isWebAuthnEnabled);
    },
);
