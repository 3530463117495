import React, { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import DesignCheck from '#/src/shared/components/ui/design-check';
import { useAppSelector } from '#/src/shared/hooks';
import { Routes } from '#/src/shared/models';
import {
    getRegistrationAccount,
    getRegistrationCard,
} from '#/src/shared/store/redux/registration/selectors';

import Phone from '../../components/phone/phone';
import NewPhone from '../../components/phone/phone-new';

const IndexPhone: FC = () => {
    const navigate = useNavigate();
    const { search } = useLocation();
    const card = useAppSelector(getRegistrationCard);
    const account = useAppSelector(getRegistrationAccount);

    useEffect(() => {
        if (!card && !account) {
            navigate({
                pathname: Routes.CARD_ACCOUNT,
                search,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <DesignCheck
            defaultComponent={<NewPhone isBrowser={true} />}
            defaultMobileComponent={<NewPhone isBrowser={true} />}
            mobileComponent={<NewPhone />}
            corpComponent={<Phone />}
        />
    );
};

export default IndexPhone;
