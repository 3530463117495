import { configureStore } from '@reduxjs/toolkit';

import { updateCookieMiddleware } from '#/src/shared/lib/update-cookie';
import crashReporter from '#/src/shared/middlewares/crash-reporter';
import delayedActionMiddleware from '#/src/shared/middlewares/delayed-action-middleware';
import { akeyApi } from '#/src/shared/store/api/akey-api';
import { authorizationApi } from '#/src/shared/store/api/authorization-api';
import { biometryApi } from '#/src/shared/store/api/biometry-api';
import { jwtApi } from '#/src/shared/store/api/jwt-api';
import { passcodeApi } from '#/src/shared/store/api/passcode-api';
import { qrAuthorizationApi } from '#/src/shared/store/api/qr-authorization-api';
import { registrationApi } from '#/src/shared/store/api/registration-api';
import { smsVerificationApi } from '#/src/shared/store/api/sms-verification-api';
import { tempPasswordApi } from '#/src/shared/store/api/temp-password-api';
import { webAuthnApi } from '#/src/shared/store/api/webauthn-api';
import { ApplicationInitialState } from '#/src/shared/store/constants';
import { pincodeApi } from '#/src/shared/store/pincode/api';

import { rootReducer } from './redux/reducer';

const middlewaresConfig = {
    options: {},
    middlewares: [
        crashReporter,
        updateCookieMiddleware,
        delayedActionMiddleware,
        akeyApi.middleware,
        authorizationApi.middleware,
        jwtApi.middleware,
        passcodeApi.middleware,
        pincodeApi.middleware,
        qrAuthorizationApi.middleware,
        registrationApi.middleware,
        smsVerificationApi.middleware,
        tempPasswordApi.middleware,
        webAuthnApi.middleware,
        biometryApi.middleware,
    ],
};

export function createStore(initState = ApplicationInitialState) {
    return configureStore({
        preloadedState: initState,
        reducer: rootReducer,
        devTools: process.env.NODE_ENV !== 'production',
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware(middlewaresConfig.options).concat(middlewaresConfig.middlewares),
    });
}

export type StoreType = ReturnType<typeof createStore>;
