import { createAsyncThunk } from '@reduxjs/toolkit';

import { history } from '#/src/shared/history';
import { scanFace as scanFaceNative } from '#/src/shared/lib/passport-mobile-bridge';
import { redirectTo } from '#/src/shared/lib/redirect';
import { FormStatus, Routes } from '#/src/shared/models';
import { endpointBiometryAuthorization } from '#/src/shared/store/api/biometry-api';
import { selectClientId, selectScope } from '#/src/shared/store/redux/app/selectors';
import { selectClearPhoneNumber } from '#/src/shared/store/redux/passcode';
import { ApplicationState } from '#/src/shared/store/types';

import { selectBiometryPhoto } from './selectors';
import {
    biometryAttemptsLeftSet,
    biometryErrorTextSet,
    biometryFormStatusSet,
    biometryPhotoSet,
    biometryServerErrorIdSet,
} from './slice';
import { BiometryApiError } from './types';

const getAuthorizationCredentials = (state: ApplicationState) => {
    const phone = selectClearPhoneNumber(state);
    const clientId = selectClientId(state);
    const scope = selectScope(state);
    const photo = selectBiometryPhoto(state);

    return {
        phone,
        photo,
        clientId,
        scope,
    };
};

export const scanFaceModule = createAsyncThunk('biometry/scan', async (_, thunkApi) => {
    const { dispatch } = thunkApi;

    scanFaceNative().then((photo) => {
        dispatch(biometryFormStatusSet(FormStatus.SubmitProcess));

        if (history.location.pathname !== Routes.BIOMETRY) {
            redirectTo(Routes.BIOMETRY);
        }
        dispatch(biometryPhotoSet(photo));
    });
});

export const fetchAuhorizeBiometry = createAsyncThunk<any, void>(
    'biometry/authorize',
    async (_, thunkApi) => {
        const { getState, dispatch } = thunkApi;

        try {
            dispatch(biometryFormStatusSet(FormStatus.SubmitProcess));
            const requestData = getAuthorizationCredentials(getState() as ApplicationState);

            const data = await dispatch(endpointBiometryAuthorization(requestData)).unwrap();

            dispatch(biometryFormStatusSet(FormStatus.SubmitSuccess));

            return data;
        } catch (err: any) {
            const error = err as BiometryApiError;
            const errorResult = error.data;
            const { id, data } = errorResult.errors[0];

            dispatch(biometryServerErrorIdSet(id));
            dispatch(biometryFormStatusSet(FormStatus.SubmitError));

            if (data) {
                const { attemptsLeft, title, subtitle } = data;

                dispatch(biometryErrorTextSet({ title, subtitle }));
                dispatch(biometryAttemptsLeftSet(attemptsLeft));
            }

            return error;
        }
    },
);
