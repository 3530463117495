import React, { FC, useEffect, useState } from 'react';

import { TextProps, Typography } from '@alfalab/core-components/typography';

import initializeTraceId from '#/src/shared/lib/initialize-trace-id';
import { isBrowserEnv } from '#/src/shared/lib/is-browser-env';
import { HEADERS } from '#/src/shared/utils';

interface Props extends Pick<TextProps, 'color'> {
    className?: string;
    shortTrace?: boolean;
    text?: string;
}

export const ErrorCode: FC<Props> = ({ className, color, shortTrace, text }) => {
    const [traceId, setTraceId] = useState({});

    const codeText = text || 'Код ошибки:';

    useEffect(() => {
        if (isBrowserEnv) {
            const headers = initializeTraceId();

            setTraceId(
                shortTrace ? headers[HEADERS.TRACE_ID].split('-')[0] : headers[HEADERS.TRACE_ID],
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (traceId) {
        return (
            <Typography.Text view="primary-small" className={className} color={color}>
                {`${codeText} ${traceId}`}
            </Typography.Text>
        );
    }

    return null;
};
