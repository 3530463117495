import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { InputProps } from '@alfalab/core-components/input';
import { Typography } from '@alfalab/core-components/typography';
import { QuestionMIcon } from '@alfalab/icons-glyph/QuestionMIcon';
import Form from 'arui-feather/form';

import BackButton from '#/src/shared/components/ui/back-button';
import InputPassportBrowser from '#/src/shared/components/ui/input-password/input-password-browser';
import ServerErrorNotificationsNew from '#/src/shared/components/ui/server-errors-notification/server-errors-notification-new';
import { useAppDispatch, useAppSelector } from '#/src/shared/hooks';
import { trackUserEvent } from '#/src/shared/lib/analitycs';
import { validatePassword } from '#/src/shared/lib/client-validation/authorization';
import { PASSWORD_INPUT_MAX_LENGTH } from '#/src/shared/lib/form-controls-const';
import getPathnameEndpoint from '#/src/shared/lib/get-pathname-endpoint';
import { ButtonNames, FormStatus, Routes, ValidationType } from '#/src/shared/models';
import { useRequestOIDAuthByLoginMutation } from '#/src/shared/store/api/authorization-api';
import {
    getQueryRedirectParams,
    selectFirstVisitedPassword,
} from '#/src/shared/store/redux/app/selectors';
import {
    keyboardLayoutChecked,
    pageFirstVisited,
    passwordSet,
} from '#/src/shared/store/redux/app/slice';
import {
    getAuthorizationFormError,
    getAuthorizationFormStatus,
    getAuthorizationLogin,
    getAuthorizationPassword,
    getAuthorizationServerErrors,
} from '#/src/shared/store/redux/authorization/selectors';
import {
    authorizationErrorUpdated,
    authorizationFormUpdated,
    authorizationPasswordReset,
    authorizationServerErrorReset,
    authorizationValidated,
    authServerErrorNotificationClosed,
} from '#/src/shared/store/redux/authorization/slice';
import { isSubmitButtonDisabled } from '#/src/shared/store/redux/password/selectors';
import { selectRegistrationErrorUpdate } from '#/src/shared/store/redux/registration/selectors';
import { errorUpdateMessageDeleted } from '#/src/shared/store/redux/registration/slice';

import './password-browser.css';

const cn = createCn('password-page');

const PasswordBrowser: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const [requestOIDAuthByLogin] = useRequestOIDAuthByLoginMutation();

    const formStatus = useAppSelector(getAuthorizationFormStatus);
    const login = useAppSelector(getAuthorizationLogin);
    const password = useAppSelector(getAuthorizationPassword);
    const error = useAppSelector(getAuthorizationFormError);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const currentRoute = getPathnameEndpoint(pathname);
    const firstVisitedStatus = useAppSelector(selectFirstVisitedPassword);
    const errorUpdate = useAppSelector(selectRegistrationErrorUpdate);
    const serverErrors = useAppSelector(getAuthorizationServerErrors);
    const submitButtonDisabled = useAppSelector(isSubmitButtonDisabled);

    const [firstKeyPush, setFirstKeyPush] = useState(false);

    const handleValidatePassword = (password: string) => {
        const validationStatus = validatePassword(password, false);

        if (validationStatus !== null) {
            dispatch(
                authorizationValidated({
                    validationStatus,
                    validationType: ValidationType.Password,
                }),
            );
        }
    };

    useEffect(() => {
        if (!login) {
            navigate({
                pathname: Routes.LOGIN,
                search,
            });
        } else if (password) {
            handleValidatePassword(password);
        }
        if (!firstVisitedStatus) {
            dispatch(pageFirstVisited('password'));
            trackUserEvent(
                'Auth Page',
                'Impression',
                'Viewing Page',
                queryRedirectParams.client_id,
            );
        }

        return () => {
            dispatch(authorizationPasswordReset());
            dispatch(authorizationServerErrorReset());
            dispatch(passwordSet(password));
            if (errorUpdate) {
                dispatch(errorUpdateMessageDeleted());
            }
            if (error.password) {
                dispatch(
                    authorizationErrorUpdated({
                        password: null,
                    }),
                );
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangePassword: InputProps['onChange'] = (e, { value: password }) => {
        if (!firstKeyPush) {
            trackUserEvent(
                'Auth Page',
                'Field Change',
                'Fill Password',
                queryRedirectParams.client_id,
            );
            setFirstKeyPush(true);
        }
        if (error.password) {
            dispatch(
                authorizationErrorUpdated({
                    password: null,
                }),
            );
        }

        handleValidatePassword(password);

        dispatch(
            authorizationFormUpdated({
                password,
            }),
        );
        dispatch(keyboardLayoutChecked({ character: password.slice(-1), input: 'password' }));
    };

    const authorizationByLogin = () => {
        requestOIDAuthByLogin({
            username: login,
            password,
            queryRedirectParams,
            currentRoute,
        });
    };

    const handleOnClick = () => {
        if (!submitButtonDisabled) {
            const validationStatus = validatePassword(password, false);

            if (validationStatus !== null) {
                trackUserEvent(
                    'Auth Page',
                    'Click',
                    'Password Send',
                    queryRedirectParams.client_id,
                    'Error Password Validation',
                );
            }
            if (validationStatus === null) {
                trackUserEvent(
                    'Auth Page',
                    'Click',
                    'Password Send',
                    queryRedirectParams.client_id,
                    'Send',
                );
                authorizationByLogin();
            }
        }
    };

    const handleOnRestore = () => {
        navigate({
            pathname: Routes.RESTORE,
            search,
        });
    };

    return (
        <React.Fragment>
            <BackButton />
            <Form className={cn()} noValidate={true} onSubmit={handleOnClick}>
                <ServerErrorNotificationsNew
                    errorMessage={serverErrors[0]?.message}
                    onClose={() => {
                        dispatch(authServerErrorNotificationClosed());
                    }}
                />
                <Typography.Title view='small' font='styrene' tag='div'>
                    Введите пароль
                </Typography.Title>
                <InputPassportBrowser
                    error={error.password}
                    onInputChange={handleChangePassword}
                    maxLength={PASSWORD_INPUT_MAX_LENGTH}
                />
                <Button
                    disabled={submitButtonDisabled}
                    block={true}
                    view='primary'
                    loading={formStatus === FormStatus.SubmitProcess}
                    onClick={handleOnClick}
                >
                    Войти
                </Button>
            </Form>
            <Button
                className={cn('restore')}
                view='text'
                leftAddons={<QuestionMIcon />}
                onClick={handleOnRestore}
            >
                {ButtonNames.restorePassword}
            </Button>
        </React.Fragment>
    );
};

export default PasswordBrowser;
