import { MetricsRequestParams, PartialMetric } from '#/src/shared/models/prometheus';
import { HttpMethod } from '#/src/shared/utils';

import factoryFetch from '../fetch-factory';

const metricsEndpoint = '/api/client-timings';

interface SendMetricsProps {
    metricsQueue: Set<PartialMetric>;
    contextRoot: string;
}

export function sendMetrics({ metricsQueue, contextRoot }: SendMetricsProps) {
    if (metricsQueue.size > 0) {
        // оставляем только те поля, которые нам реально нужны, чтобы не гонять по сети лишнее
        const serializedMetrics: PartialMetric[] = [];

        metricsQueue.forEach((metric) => {
            serializedMetrics.push({
                name: metric.name,
                value: metric.value,
                rating: metric.rating,
            });
        });

        const valueToSubmit: MetricsRequestParams = {
            metrics: serializedMetrics,
            // https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation/effectiveType - отсутствует в типах, но есть в реальности
            effectiveConnectionType:
                (navigator as NavigatorNetworkInformation)?.connection?.effectiveType ??
                'unknown',
        };

        const body = JSON.stringify(valueToSubmit);

        const metricsFullUrl = `${contextRoot}${metricsEndpoint}`;

        // По возможности используем sendBeacon, потому что он нормально работает при закрытии вкладки
        if (navigator.sendBeacon) {
            navigator.sendBeacon(metricsFullUrl, body);
        } else {
            factoryFetch(metricsFullUrl, HttpMethod.POST)(body);
        }
    }
}
