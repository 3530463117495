import React, { ChangeEvent, useEffect, useState } from 'react';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { Input } from '@alfalab/core-components/input';
import { PasswordInput } from '@alfalab/core-components/password-input';
import Form from 'arui-feather/form';

import ErrorDictionary from '#/src/shared/error-dictionary';
import { useAppDispatch, useAppSelector } from '#/src/shared/hooks';
import {
    investmentsValidateLogin,
    investmentsValidatePassword,
} from '#/src/shared/lib/client-validation/authorization';
import {
    LOGIN_INPUT_MAX_LENGTH,
    PASSWORD_INPUT_MAX_LENGTH,
} from '#/src/shared/lib/form-controls-const';
import { submitNativeEvent } from '#/src/shared/lib/submit-native-event';
import { ButtonNames, nativeEventStatus } from '#/src/shared/models';
import { useRequestOIDAuthByLoginMutation } from '#/src/shared/store/api/authorization-api';
import {
    getQueryRedirectParams,
    selectInvestmentsUrl,
} from '#/src/shared/store/redux/app/selectors';
import {
    getAuthorizationFormError,
    getAuthorizationLogin,
    getAuthorizationPassword,
    getAuthorizationServerErrors,
} from '#/src/shared/store/redux/authorization/selectors';
import {
    authorizationErrorUpdated,
    authorizationFormReset,
    authorizationFormUpdated,
    authorizationPasswordReset,
    authorizationServerErrorReset,
} from '#/src/shared/store/redux/authorization/slice';
import { QueryRedirectParams } from '#/src/shared/types/interfaces';

const cn = createCn('investments');

interface Props {
    hideRecover?: boolean;
}

const InvestmentsLoginPassword: React.FC<Props> = ({ hideRecover }) => {
    const dispatch = useAppDispatch();
    const [requestOIDAuthByLogin] = useRequestOIDAuthByLoginMutation();

    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const login = useAppSelector(getAuthorizationLogin);
    const authError = useAppSelector(getAuthorizationFormError);
    const password = useAppSelector(getAuthorizationPassword);
    const authServerErrors = useAppSelector(getAuthorizationServerErrors);
    const investmentsUrl = useAppSelector(selectInvestmentsUrl);

    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        if (login && password) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [login, password]);

    useEffect(
        () => () => {
            dispatch(authorizationFormReset());
            dispatch(authorizationPasswordReset());
        },
        [dispatch],
    );

    useEffect(() => {
        if (authError.login || authError.password || authServerErrors[0]) {
            submitNativeEvent({ status: nativeEventStatus.loginFailed });
        }
    }, [authError.login, authError.password, authServerErrors]);

    const authResponseError = (error: string) => {
        if (error === ErrorDictionary.DEFAULT) {
            if (hideRecover) {
                return ErrorDictionary.GO_INVEST_AUTH_ERROR;
            }

            return ErrorDictionary.INVEST_AUTH_ERROR;
        }

        return error;
    };

    const clearInput = () => {
        if (authServerErrors.length) {
            dispatch(authorizationServerErrorReset());
        }
        if (authError.login || authError.password) {
            dispatch(
                authorizationErrorUpdated({
                    login: '',
                    password: '',
                }),
            );
        }
        dispatch(
            authorizationFormUpdated({
                login: '',
                password: '',
            }),
        );
    };

    const handleChangeLogin = (newValue: string) => {
        if (authError.login) {
            dispatch(
                authorizationErrorUpdated({
                    login: '',
                }),
            );
        }
        dispatch(
            authorizationFormUpdated({
                login: newValue,
            }),
        );
    };

    const handleChangePassword = (newPassword: string) => {
        if (authServerErrors.length) {
            dispatch(authorizationServerErrorReset());
        }
        if (authError.password) {
            dispatch(
                authorizationErrorUpdated({
                    password: '',
                }),
            );
        }
        dispatch(
            authorizationFormUpdated({
                password: newPassword,
            }),
        );
    };

    const handleValidate = (
        loginValue: string,
        passwordValue: string,
        queryParams: QueryRedirectParams,
    ) => {
        const loginValidation = investmentsValidateLogin(loginValue);
        const passwordValidation = investmentsValidatePassword(passwordValue);

        if (loginValidation === null && passwordValidation === null) {
            requestOIDAuthByLogin({
                username: loginValue,
                password: passwordValue,
                queryRedirectParams: queryParams,
            });
        }

        if (loginValidation) {
            dispatch(authorizationErrorUpdated({ login: loginValidation }));
        }

        if (passwordValidation) {
            dispatch(authorizationErrorUpdated({ password: passwordValidation }));
        }
    };

    const handleLoginSubmit = () => {
        submitNativeEvent({ status: nativeEventStatus.buttonPressed });
        handleValidate(login, password, queryRedirectParams);
    };

    return (
        <Form onSubmit={handleLoginSubmit} className={cn('form')} noValidate={true}>
            <div className={cn('row')}>
                <Input
                    value={login}
                    onChange={(e) => handleChangeLogin(e.target.value)}
                    onClear={clearInput}
                    autoFocus={true}
                    maxLength={LOGIN_INPUT_MAX_LENGTH}
                    label='Логин'
                    clear={true}
                    size='m'
                    block={true}
                    className={cn('login')}
                />
                {authError?.login && (
                    <div className={cn('error-text-wrapper')}>
                        <div className={cn('error-text')}>{authError?.login}</div>
                    </div>
                )}
                <PasswordInput
                    value={password}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleChangePassword(e.target.value)
                    }
                    maxLength={PASSWORD_INPUT_MAX_LENGTH}
                    label='Пароль'
                    size='m'
                    block={true}
                    className={cn('mt_2xl')}
                    autoCapitalize='none'
                />
                {(authError?.password || authServerErrors[0]) && (
                    <div className={cn('error-text-wrapper')}>
                        <div className={cn('error-text')}>
                            {authError.password
                                ? authResponseError(authError.password)
                                : authServerErrors[0].message}
                        </div>
                    </div>
                )}
            </div>
            <div className={cn('button-wrapper')}>
                <Button
                    block={true}
                    type='submit'
                    view='primary'
                    className={cn('button')}
                    disabled={isDisabled}
                >
                    {ButtonNames.enter}
                </Button>
                {!hideRecover && (
                    <Button
                        block={true}
                        view='transparent'
                        className={cn('link')}
                        href={investmentsUrl}
                    >
                        {ButtonNames.getLoginPass}
                    </Button>
                )}
            </div>
        </Form>
    );
};

export default InvestmentsLoginPassword;
