import React, { FC, useEffect, useRef } from 'react';
import { createCn } from 'bem-react-classname';

import { LoaderForeground } from '#/src/shared/components/ui/loader/loader-foreground';
import { LoaderHeart } from '#/src/shared/components/ui/loader-heart';

import './logo-animated.css';

interface Props {
    /**
     * Флаг отражающий состояние состояние видимости.
     * Все анимации запускаются как только иконка становится видимой.
     */
    isVisible: boolean;
}

const cn = createCn('logo-animated');

const LogoAnimated: FC<Props> = ({
  isVisible = false,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const bgRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const rect = ref.current?.getBoundingClientRect();
    const preloaderY = (window.innerHeight / 2) - 50;
    const logoY = rect?.top || preloaderY;

    const diff = preloaderY - logoY;

    if (ref?.current) {
      const root = document.documentElement;

      root.style.setProperty('--top-logo', `${diff}px`);
    }
  }, [isVisible]);

  return (
    <div ref={ref} className={cn({ hidden: !isVisible })}>
      <div ref={bgRef} className={cn('background')} />

      <LoaderForeground className={cn('logo')} />

      <LoaderHeart className={cn('heart')} width={25} height={25} />
    </div>
  );
};

export default LogoAnimated;
