import React, { FC, useEffect, useState } from 'react';
import { createCn } from 'bem-react-classname';

import { BankCard } from '@alfalab/core-components/bank-card';
import { Button } from '@alfalab/core-components/button';
import { Typography } from '@alfalab/core-components/typography';
import { X5CardLIcon } from '@alfalab/icons-logotype/X5CardLIcon';
import Form from 'arui-feather/form';
import AttentionMark from 'arui-feather/icon/ui/attention-mark';

import AlternativeLoginMobile from '#/src/shared/components/alternative-login/alternative-login-mobile';
import { ButtonInform } from '#/src/shared/components/alternative-login/alternative-login-mobile/components/button-inform';
import AssistantDialog from '#/src/shared/components/ui/assistant-dialog';
import { useAppDispatch, useAppSelector } from '#/src/shared/hooks';
import { trackUserEvent } from '#/src/shared/lib/analitycs';
import checkRegistrationValueType from '#/src/shared/lib/check-registration-value-type';
import {
    validateAccountNumber,
    validateCardNumber,
} from '#/src/shared/lib/client-validation/registration';
import {
    ACCOUNT_INPUT_MAX_LENGTH,
    CARD_INPUT_MAX_LENGTH,
} from '#/src/shared/lib/form-controls-const';
import { ButtonNames, ClientIds, FormStatus, RegistrationType } from '#/src/shared/models';
import {
    useRequestRegistrationMutation,
    useScanCardMutation,
} from '#/src/shared/store/api/registration-api';
import {
    getQueryRedirectParams,
    isAppVersionNeedUpdate,
    selectGetDeviceOSName,
    selectIsAccountAuthEnabled,
    selectIsMobileNewVersion,
    selectIsMobileOnboardingDesignVariant,
    selectIsUpdateBannerEnabled,
} from '#/src/shared/store/redux/app/selectors';
import {
    getRegistrationAccount,
    getRegistrationCard,
    getRegistrationFormError,
    getRegistrationFormStatus,
    getRegistrationServerErrors,
    getRegistrationType,
    selectIsSubmitButtonDisabled,
} from '#/src/shared/store/redux/registration/selectors';
import {
    registrationErrorUpdated,
    registrationFormUpdated,
    registrationServerErrorsCleared,
    registrationSubmit,
    registrationTypeChanged,
    registrationTypeUpdated,
} from '#/src/shared/store/redux/registration/slice';

import { UpdateAppBunner } from './components/update-app-bunner';

import '#/src/shared/components/app/form-basic.css';

const cn = createCn('form-basic');

const NewCardAccount: FC = () => {
    const dispatch = useAppDispatch();
    const [requestRegistration] = useRequestRegistrationMutation();
    const [scanCard] = useScanCardMutation();
    const type = useAppSelector(getRegistrationType);
    const account = useAppSelector(getRegistrationAccount);
    const card = useAppSelector(getRegistrationCard);
    const formStatus = useAppSelector(getRegistrationFormStatus);
    const formError = useAppSelector(getRegistrationFormError);
    const serverErrors = useAppSelector(getRegistrationServerErrors);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const isSubmitButtonDisabled = useAppSelector(selectIsSubmitButtonDisabled);
    const isMobileOnboardingDesignVariant = useAppSelector(selectIsMobileOnboardingDesignVariant);
    const deviceOS = useAppSelector(selectGetDeviceOSName);
    const isMobileNewVersion = useAppSelector(selectIsMobileNewVersion);
    const isAccountAuthEnabled = useAppSelector(selectIsAccountAuthEnabled);
    const isAppNeedUpdate = useAppSelector(isAppVersionNeedUpdate);
    const isUpdateBannerEnabled = useAppSelector(selectIsUpdateBannerEnabled);
    const [firstKeyPush, setFirstKeyPush] = useState(false);

    useEffect(() => {
        dispatch(registrationTypeUpdated(type));
        trackUserEvent('Auth Page', 'Impression', 'Viewing Page', queryRedirectParams.client_id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (formStatus === FormStatus.ValidationSuccess) {
            requestRegistration();
        }
    }, [formStatus, requestRegistration]);

    useEffect(() => {
        if (formError.card) {
            trackUserEvent(
                'Auth Page',
                'Click',
                'Card Account Send',
                queryRedirectParams.client_id,
                `Card error: ${formError.card}`,
            );
        }
    }, [formError.card, queryRedirectParams.client_id]);

    useEffect(() => {
        if (formError.account) {
            trackUserEvent(
                'Auth Page',
                'Click',
                'Card Account Send',
                queryRedirectParams.client_id,
                `Account error: ${formError.account}`,
            );
        }
    }, [formError.account, queryRedirectParams.client_id]);

    useEffect(() => {
        if (serverErrors.length) {
            serverErrors.forEach((error) => {
                trackUserEvent(
                    'Auth Page',
                    'Click',
                    'Card Account Send',
                    queryRedirectParams.client_id,
                    `Server error: ${error.message}`,
                );
            });
        }
    }, [queryRedirectParams.client_id, serverErrors]);

    useEffect(() => {
        const isCard = type === RegistrationType.Card;
        const clientNumber = isCard ? card : account;
        const trackMessage = isCard ? 'Card number filled' : 'Account filled';
        const maxClientNumberLength = isCard ? CARD_INPUT_MAX_LENGTH : ACCOUNT_INPUT_MAX_LENGTH;
        const validation = isCard ? validateCardNumber : validateAccountNumber;
        const validationError: Record<string, string | null> = {};

        if (clientNumber.length === maxClientNumberLength) {
            const validationResult: string | null = validation(clientNumber);

            if (validationResult === null) {
                trackUserEvent(
                    'Auth Page',
                    'Field Change',
                    'Fill Card Account',
                    queryRedirectParams.client_id,
                    trackMessage,
                );
            } else {
                validationError[isCard ? 'card' : 'account'] = validationResult;
                dispatch(registrationErrorUpdated(validationError));
            }
        }

        return () => {
            dispatch(registrationServerErrorsCleared());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRegistrationTypeChange = (regType: RegistrationType) => {
        trackUserEvent(
            'Auth Page',
            'Switch',
            'Authentication Type',
            queryRedirectParams.client_id,
            type === RegistrationType.Card ? 'From Card To Account' : 'From Account To Card',
        );
        dispatch(registrationTypeChanged(regType));
    };

    const handleCardScanner = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        dispatch(registrationServerErrorsCleared());
        dispatch(registrationFormUpdated({ account: '' }));
        handleRegistrationTypeChange(RegistrationType.Card);
        scanCard();
    };
    const handleCardValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!firstKeyPush) {
            trackUserEvent(
                'Auth Page',
                'Field Change',
                'Enter Card Account',
                queryRedirectParams.client_id,
            );
            setFirstKeyPush(true);
        }
        if (formError.card) {
            dispatch(registrationErrorUpdated({ card: '' }));
        }

        if (formError.account) {
            dispatch(registrationErrorUpdated({ account: '' }));
        }

        if (serverErrors.length) {
            dispatch(registrationServerErrorsCleared());
        }

        const value = event.target.value.trim();

        if (
            checkRegistrationValueType(value, isAccountAuthEnabled) === RegistrationType.Card &&
            card !== value
        ) {
            dispatch(
                registrationFormUpdated({
                    card: value,
                    account: '',
                }),
            );
            if (type !== RegistrationType.Card) {
                handleRegistrationTypeChange(RegistrationType.Card);
            }
        } else if (
            checkRegistrationValueType(value, isAccountAuthEnabled) === RegistrationType.Account &&
            account !== value
        ) {
            dispatch(
                registrationFormUpdated({
                    card: '',
                    account: value,
                }),
            );
            if (type !== RegistrationType.Account) {
                handleRegistrationTypeChange(RegistrationType.Account);
            }
        }
    };

    const handleSubmit = (event?: React.FormEvent<any>) => {
        event?.preventDefault();

        trackUserEvent(
            'Auth Page',
            'Click',
            'Card Account Send',
            queryRedirectParams.client_id,
            'Send',
        );
        dispatch(
            registrationSubmit({
                type,
                account,
                card,
            }),
        );
    };

    const isX5Client =
        queryRedirectParams.client_id === ClientIds.X5 ||
        queryRedirectParams.client_id === ClientIds.lkx5Web;

    const bankCardProps = () => {
        if (isX5Client) {
            return {
                backgroundColor: '#90C74A',
                bankLogo: <X5CardLIcon color='#ffffff' />,
            };
        }
        if (isMobileOnboardingDesignVariant && deviceOS === 'iOS' && isMobileNewVersion) {
            return {
                bankLogo: null,
            };
        }

        return {};
    };

    const assistantDialogParams = () => {
        const text = isAccountAuthEnabled
            ? 'Ещё номер карты или счёта — проверим, что это правда вы'
            : 'Ещё номер карты — проверю, что это правда вы';

        if (deviceOS === 'iOS' && isMobileNewVersion) {
            return {
                text,
                hideIcon: true,
            };
        }

        return {
            text: `${text}\u00A0👀`,
        };
    };

    if (
        isAppNeedUpdate &&
        queryRedirectParams.client_id === ClientIds.mobileApp &&
        isUpdateBannerEnabled
    ) {
        return <UpdateAppBunner />;
    }

    return (
        <Form
            onSubmit={handleSubmit}
            noValidate={true}
            className={cn({
                flex: 'space-between',
                card: true,
            })}
        >
            <div className={cn('row')}>
                {isMobileOnboardingDesignVariant && (
                    <AssistantDialog {...assistantDialogParams()} />
                )}
                <BankCard
                    className={cn('bank-card')}
                    value={card || account}
                    onChange={handleCardValueChange}
                    onUsePhoto={handleCardScanner}
                    maskType={isAccountAuthEnabled ? 'default' : 'card'}
                    {...bankCardProps()}
                />
                {isX5Client && (
                    <Typography.Text
                        className={cn('x5-hint')}
                        view='primary-small'
                        color='secondary'
                        tag='div'
                    >
                        На пластиковой карте X5 Банка номер
                        <br />
                        отображен на обратной стороне. Также номер можно посмотреть в Альфа Мобайл
                    </Typography.Text>
                )}
                <ButtonInform />
            </div>

            <div className={cn('row')}>
                <div className={cn('button-wrapper')}>
                    {type === RegistrationType.Card &&
                        formError.card &&
                        (!serverErrors || serverErrors.length === 0) && (
                            <div className={cn('error-text-wrapper')}>
                                <AttentionMark className={cn('error-icon')} colored={true} />
                                <div className={cn('error-text')}>
                                    Введите корректный номер карты
                                </div>
                            </div>
                        )}
                    {serverErrors && serverErrors[0] && (
                        <div className={cn('error-text-wrapper')}>
                            <AttentionMark className={cn('error-icon')} colored={true} />
                            <div className={cn('error-text')}>{serverErrors[0].message}</div>
                        </div>
                    )}

                    <Button
                        block={true}
                        type='submit'
                        view='primary'
                        disabled={isSubmitButtonDisabled}
                        onClick={handleSubmit}
                        loading={formStatus === FormStatus.SubmitProcess}
                    >
                        {isMobileOnboardingDesignVariant
                            ? ButtonNames.forward
                            : ButtonNames.continue}
                    </Button>

                    <AlternativeLoginMobile />
                </div>
            </div>
        </Form>
    );
};

export default NewCardAccount;
