import React, { FC, useEffect, useState } from 'react';
import { createCn } from 'bem-react-classname';

import { useAppSelector } from '#/src/shared/hooks';
import { selectIsCorporateClientId, selectIsMobile } from '#/src/shared/store/redux/app/selectors';

import './app-background.css';

function getWindowDimensions() {
    return {
        screenWidth: document.documentElement.clientWidth,
        screenHeight: document.documentElement.clientHeight,
    };
}

const cn = createCn('app-background');

const AppBackground: FC = () => {
    const isMobile = useAppSelector(selectIsMobile);
    const isCorporateClientId = useAppSelector(selectIsCorporateClientId);

    const [backgroundHeight, setBackgroundHeight] = useState('100%');

    const drawBackground = (screenHeight: number) => {
        setBackgroundHeight(`${screenHeight}px`);
    }

    useEffect(() => {
        const { screenHeight } = getWindowDimensions();

        if (isMobile) {
            drawBackground(screenHeight);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            style={{ height: backgroundHeight }}
            className={`${cn()} ${cn(isCorporateClientId ? 'boat' : 'mount')}`}
        />
    );
}

export default AppBackground;
