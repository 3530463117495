import React, { FC, ReactNode } from 'react';

import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';

import { useAppSelector } from '#/src/shared/hooks';
import { selectIsMobile } from '#/src/shared/store/redux/app/selectors';

interface PropsComponent {
    title: string;
    children: ReactNode;
}
const InformationBlock: FC<PropsComponent> = ({ title, children }) => {
    const isMobile = useAppSelector(selectIsMobile);
    return (
        <React.Fragment>
            <Gap size={isMobile ? 'xl' : 'm'} direction='vertical' />
            <Typography.TitleMobile view='small' tag='h3' dataTestId='inform-title'>
                {title}
            </Typography.TitleMobile>
            <Gap size='m' direction='vertical' />
            <Typography.Text view='primary-medium' tag='p' dataTestId='inform-description'>
                {children}
            </Typography.Text>
        </React.Fragment>
    );
};

export default InformationBlock;
