import React from 'react';

import DesignCheck from '#/src/shared/components/ui/design-check';

import RecoveryBrowser from '../../components/recovery/recovery-browser';
import Recovery from '../../components/recovery/recovery-old';

export default (props: any) => (
    <DesignCheck
        defaultComponent={<RecoveryBrowser {...props} />}
        defaultMobileComponent={<RecoveryBrowser {...props} />}
        mobileComponent={<Recovery {...props} />}
        corpComponent={<Recovery {...props} />}
    />
);
