import uuid from 'uuid';

import { encryptBrowserSecret } from '#/src/shared/lib/encrypt-browser-secret';
import { isBrowserEnv } from '#/src/shared/lib/is-browser-env';
import { LocalStorageName } from '#/src/shared/models';

export const checkSecretDate = (date: string | null) => {
    if (date) {
        const dateNow = Date.now();
        const timeDiff = Math.abs(dateNow - +date);
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        return diffDays > 0 && diffDays < 7;
    }

    return false;
};

export const checkBrowserSecret = async (publicKey: string) => {
    if (isBrowserEnv) {
        const browserSecret = window.localStorage.getItem(LocalStorageName.browserSecret);
        const browserSecretDate = window.localStorage.getItem(LocalStorageName.browserSecretDate);

        if (!browserSecret || !checkSecretDate(browserSecretDate)) {
            const newBrowserSecret = `${uuid.v4()}${uuid.v4()}`;
            const newBrowserSecretEnc = await encryptBrowserSecret(
                `${newBrowserSecret}#${uuid.v4()}`,
                publicKey,
            );

            if (newBrowserSecretEnc) {
                return {
                    newBrowserSecret,
                    newBrowserSecretEnc,
                };
            }
        }
    }

    return {};
};
