import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FormStatus } from '#/src/shared/models';
import { biometryApi } from '#/src/shared/store/api/biometry-api';

import { BiometryAuthorizationInitialState } from './constants';
import { BiometryApiError } from './types';

export const biometryAuthorizationSlice = createSlice({
    name: 'biometryAuthorization',
    initialState: BiometryAuthorizationInitialState,
    reducers: {
        biometryServerErrorIdSet(state, action: PayloadAction<string>) {
            state.serverErrorId = action.payload;
        },
        biometryAttemptsLeftSet(state, action: PayloadAction<number>) {
            state.attemptsLeft = action.payload;
        },
        biometryFormStatusSet(state, action: PayloadAction<FormStatus>) {
            state.formStatus = action.payload;
        },
        biometryPhotoSet(state, action: PayloadAction<string | undefined>) {
            state.photo = action.payload;
        },
        biometryErrorTextSet(state, action: PayloadAction<{ title: string; subtitle: string }>) {
            state.errorTitle = action.payload.title;
            state.errorSubtitle = action.payload.subtitle;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(biometryApi.endpoints.initializeBiometry.matchFulfilled, (state) => {
                state.isBiometryAuth = true;
                state.formStatus = FormStatus.SubmitSuccess;
            })
            .addMatcher(biometryApi.endpoints.initializeBiometry.matchRejected, (state, action) => {
                state.isBiometryAuth = false;
                state.formStatus = FormStatus.SubmitError;
                const error = action.payload as BiometryApiError;
                const { id } = error.data.errors[0];

                state.serverErrorId = id;
            })
            .addMatcher(biometryApi.endpoints.initializeBiometry.matchPending, (state) => {
                state.formStatus = FormStatus.SubmitProcess;
            });
    },
});

export const {
    biometryServerErrorIdSet,
    biometryAttemptsLeftSet,
    biometryFormStatusSet,
    biometryPhotoSet,
    biometryErrorTextSet,
} = biometryAuthorizationSlice.actions;

export default biometryAuthorizationSlice.reducer;
