import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { createApi } from '@reduxjs/toolkit/query/react';

import { trackUserEvent } from '#/src/shared/lib/analitycs';
import { declination } from '#/src/shared/lib/declination';
import { FormStatus } from '#/src/shared/models';
import { customFetchBaseQuery } from '#/src/shared/store/base-query';
import { selectClientId, selectScope } from '#/src/shared/store/redux/app/selectors';
import {
    selectFormattedPhoneOrFromUrl,
    selectTempPassword,
} from '#/src/shared/store/redux/temp-password';
import {
    tempPasswordErrorSet,
    tempPasswordUpdated,
} from '#/src/shared/store/redux/temp-password/slice';
import { ApplicationState } from '#/src/shared/store/types';
import { TempPasswordCredentials } from '#/src/shared/types/interfaces';
import { Endpoint, HttpMethod } from '#/src/shared/utils';

const getAuthorizationCredentials = (state: ApplicationState): TempPasswordCredentials => {
    const phoneNumber = selectFormattedPhoneOrFromUrl(state);
    const tempPassword = selectTempPassword(state);
    const clientId = selectClientId(state);
    const scope = selectScope(state);

    return {
        phone: phoneNumber,
        password: tempPassword,
        clientId,
        scope,
    };
};

export const tempPasswordApi = createApi({
    reducerPath: 'tempPasswordApi',
    baseQuery: customFetchBaseQuery(),
    endpoints: (build) => ({
        requestTempPasswordAuthorization: build.mutation<any, void>({
            queryFn: async (_payload, queryApi, _extraOptions, fetchWithBQ) => {
                const state = queryApi.getState() as ApplicationState;
                const { dispatch } = queryApi;

                const clientId = selectClientId(state);
                const body = getAuthorizationCredentials(state);

                dispatch(tempPasswordUpdated({ formStatus: FormStatus.SubmitProcess }));
                const result = await fetchWithBQ({
                    url: Endpoint.TEMP_PASSWORD,
                    method: HttpMethod.POST,
                    body,
                });

                if (result.error) {
                    const errorResult = result.error.data as any;
                    const { id, data } = errorResult.errors[0];
                    const attemptsLeft = data?.attemptsLeft;

                    trackUserEvent(
                        'Auth Page',
                        'Click',
                        'TempPassword Send',
                        clientId,
                        `Error: ${id}`,
                    );

                    dispatch(
                        tempPasswordUpdated({
                            formStatus: FormStatus.SubmitError,
                            serverErrorId: id,
                        }),
                    );

                    if (attemptsLeft) {
                        dispatch(tempPasswordUpdated({ attemptsLeft }));
                        const left = declination(attemptsLeft, ['Осталась', 'Осталось']);
                        const attempt = declination(attemptsLeft, ['попытка', 'попытки']);

                        dispatch(
                            tempPasswordErrorSet(
                                `Пароль не совпадает. ${left} ${attemptsLeft} ${attempt}`,
                            ),
                        );
                    } else {
                        dispatch(tempPasswordUpdated({ attemptsLeft: 0 }));
                    }

                    return { error: result.error as FetchBaseQueryError };
                }

                dispatch(tempPasswordUpdated({ formStatus: FormStatus.SubmitSuccess }));

                return { data: result.data };
            },
        }),
    }),
});

export const { useRequestTempPasswordAuthorizationMutation } = tempPasswordApi;
