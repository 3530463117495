import uuid from 'uuid';

import { HEADERS } from '#/src/shared/utils/constants';

const initializeTraceId = () => {
    const TRACE_ID_DEFAULT = uuid.v4();

    return () => ({
        [HEADERS.TRACE_ID]: TRACE_ID_DEFAULT,
    });
};

export default initializeTraceId();
