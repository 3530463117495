import React, { FC } from 'react';
import { createCn } from 'bem-react-classname';

import { BottomSheet, BottomSheetProps } from '@alfalab/core-components/bottom-sheet';
import { Gap } from '@alfalab/core-components/gap';

import { useAppSelector } from '#/src/shared/hooks';
import {
    isAppVersionHasNativeCallBank,
    isNewUi,
    selectGetDeviceOSName,
} from '#/src/shared/store/redux/app/selectors';

import NativeContactCell from './native-contact-cell';
import PhoneNumberCell, { PhoneNumberCellProps } from './phone-number-cell';

import './bank-contacts.css';

export const cn = createCn('bank-contacts');

const bankContacts: PhoneNumberCellProps[] = [
    {
        id: 1,
        phoneNumber: '+7 495 788 88 78',
        hrefPhone: 'tel:+74957888878',
        text: 'В Москве и за границей',
    },
    {
        id: 2,
        phoneNumber: '8 800 200 00 00',
        hrefPhone: 'tel:+78002000000',
        text: 'Для звонков по России',
    },
];

const BsBankContacts: FC<BottomSheetProps> = ({ open, onClose, title }) => {
    const isNativeCall = useAppSelector(isAppVersionHasNativeCallBank);
    const osName = useAppSelector(selectGetDeviceOSName);
    const newUi = useAppSelector(isNewUi);

    return (
        <BottomSheet
            title={title}
            open={open}
            onClose={onClose}
            hasCloser={true}
            className={cn('bottomsheet')}
            dataTestId='bank-contacts-bs'
        >
            {osName === 'Android' && isNativeCall && <NativeContactCell />}
            {bankContacts.map(({ ...props }) => (
                <PhoneNumberCell key={props.id} {...props} />
            ))}
            {osName === 'iOS' && newUi && <Gap size={20} direction='vertical' />}
        </BottomSheet>
    );
};

export default BsBankContacts;
