import React from 'react';
import { useLocation, useNavigate } from 'react-router';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { GenericWrapper } from '@alfalab/core-components/generic-wrapper';
import { DoorOpenMIcon } from '@alfalab/icons-glyph/DoorOpenMIcon';

import { useAppDispatch, useAppSelector } from '#/src/shared/hooks';
import { sendMetrics } from '#/src/shared/lib/analitycs';
import { clientInfoLog } from '#/src/shared/lib/client-logger';
import { getMetricsData } from '#/src/shared/lib/get-metrics-data';
import { removeCookie } from '#/src/shared/lib/update-cookie';
import { CookiesName, LocalStorageName, Routes } from '#/src/shared/models';
import { pincodeVisitedSet } from '#/src/shared/store/pincode/slice';
import {
    getQueryRedirectParams,
    selectIsMobile,
    selectMetricsDebugEnabled,
} from '#/src/shared/store/redux/app/selectors';
import { isAkeyVisitedSet, webAuthnStatusSet } from '#/src/shared/store/redux/app/slice';
import { SUB_ALFABANK_DOMAIN } from '#/src/shared/utils';

export const ChangeUserButton = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const isMobile = useAppSelector(selectIsMobile);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const metricsData = getMetricsData(queryRedirectParams, isMobile);
    const isMetricsDebugEnabled = useAppSelector(selectMetricsDebugEnabled);

    const handleUserChange = () => {
        window.localStorage.removeItem(LocalStorageName.browserSecret);
        window.localStorage.removeItem(LocalStorageName.browserSecretDate);
        window.localStorage.removeItem(LocalStorageName.xUserInfo);
        clientInfoLog('ForgottenPasscode - remove browserSecret and browserSecretDate');

        removeCookie(CookiesName.webAuthnFlag, { domain: SUB_ALFABANK_DOMAIN, path: '/' });
        removeCookie(CookiesName.webAuthnUserId, { domain: SUB_ALFABANK_DOMAIN, path: '/' });
        removeCookie(CookiesName.pcUserID, { domain: SUB_ALFABANK_DOMAIN, path: '/' });
        removeCookie(CookiesName.authType, { domain: SUB_ALFABANK_DOMAIN, path: '/' });
        removeCookie(CookiesName.authTypePincode, { domain: SUB_ALFABANK_DOMAIN, path: '/' });

        sendMetrics('Button logout', 'Click', metricsData, isMetricsDebugEnabled);

        dispatch(pincodeVisitedSet(true));
        dispatch(webAuthnStatusSet(false));
        dispatch(isAkeyVisitedSet(true));
        navigate({
            pathname: Routes.PHONE_AUTH,
            search: location.search,
        });
    };

    return (
        <Button view='text' onClick={handleUserChange} dataTestId='change-user-button'>
            <GenericWrapper alignItems='center'>
                <DoorOpenMIcon />
                {!isMobile && (
                    <React.Fragment>
                        <Gap size='xs' direction='horizontal' /> Сменить пользователя
                    </React.Fragment>
                )}
            </GenericWrapper>
        </Button>
    );
};
