import { createSlice } from '@reduxjs/toolkit';

import { AppInitialState } from '#/src/shared/store/redux/app/constants';

export const appSlice = createSlice({
    name: 'app',
    initialState: AppInitialState,
    reducers: {
        acrValuesSet(state, action) {
            state.queryRedirectParams.acr_values = action.payload;
        },
        akeyStatusSet(state, action) {
            state.isAKeyEnabled = action.payload;
        },
        isAkeyVisitedSet(state, action) {
            state.isAKeyVisited = action.payload;
        },
        keyboardLayoutChecked(state, action) {
            const { character, input } = action.payload;

            if (/[а-яА-ЯёЁ]/.test(character)) {
                state.warnIfRussianLayout[input as 'login' | 'password'] = true;
            } else {
                state.warnIfRussianLayout[input as 'login' | 'password'] = false;
            }
        },
        setMultifactorResponse(state, action) {
            const { url, params } = action.payload;

            state.multiFactorResponseRedirectUrl = url;
            state.multiFactorResponseParams = {
                ...state.multiFactorResponseParams,
                ...params,
            };
        },
        notificationClosed(state) {
            state.notificationsData = null;
        },
        pageFirstVisited(state, action) {
            const { page }: { page: 'login' | 'password' } = action.payload;

            state.firstVisitedMetrics[page] = true;
        },
        passwordSet(state, action) {
            state.password = action.payload;
        },
        preloaderHidden(state) {
            state.preloaderShown = false;
        },
        preloaderShown(state) {
            state.preloaderShown = true;
        },
        queryParamCleared(state, action) {
            state.queryRedirectParams = {
                ...state.queryRedirectParams,
                [action.payload.name]: '',
            };
        },
        queryParamsUpdated(state, action) {
            state.queryRedirectParams = {
                ...state.queryRedirectParams,
                ...action.payload.params,
            };
        },
        registrationBySmsRequested(state, action) {
            state.multiFactorResponseParams = {
                ...state.multiFactorResponseParams,
                ...action.payload,
            };
        },
        webAuthnStatusSet(state, action) {
            state.isWebAuthnEnabled = action.payload;
        },
    },
});

export const {
    acrValuesSet,
    akeyStatusSet,
    isAkeyVisitedSet,
    keyboardLayoutChecked,
    setMultifactorResponse,
    pageFirstVisited,
    passwordSet,
    preloaderHidden,
    preloaderShown,
    queryParamCleared,
    queryParamsUpdated,
    registrationBySmsRequested,
    webAuthnStatusSet,
    notificationClosed,
} = appSlice.actions;

export default appSlice.reducer;
