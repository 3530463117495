import React, { FC, useCallback, useEffect } from 'react';
import { createCn } from 'bem-react-classname';

import { Typography } from '@alfalab/core-components/typography';

import QR from '#/src/shared/components/ui/qr';
import { useAppSelector } from '#/src/shared/hooks';
import { QRStatus } from '#/src/shared/models';
import { useQRCodeRequestMutation } from '#/src/shared/store/api/qr-authorization-api';
import { selectCustomDesignAuthorizationUrl } from '#/src/shared/store/redux/custom-design/selectors';
import {
    selectQrCode,
    selectQrIntervalRequest,
    selectQrIsEnabled,
    selectQrWebSocketConnectionStatus,
} from '#/src/shared/store/redux/qr-authorization/selectors';

import './qr-login.css';

const cn = createCn('qr-login');

interface Props {
    className?: string;
}

const QRText = (APayQRCode: string) => {
    if (APayQRCode) {
        return (
            <div>
                <Typography.Title className={cn('title')} view='small' tag='div'>
                    Быстрая оплата в&nbsp;приложении
                </Typography.Title>
                <Typography.Text view='primary-medium'>
                    Отсканируйте QR-код камерой телефона и&nbsp;перейдите по&nbsp;ссылке
                </Typography.Text>
            </div>
        );
    }

    return (
        <div>
            <Typography.Title className={cn('title')} view='small' tag='div'>
                Быстрый вход
            </Typography.Title>
            <Typography.Text view='primary-medium'>
                В приложении Альфа-Банка перейдите в <b>Профиль</b> →{' '}
                <b>Войти в&nbsp;интернет банк</b> и отсканируйте QR&#8209;код
            </Typography.Text>
        </div>
    );
};

// PASSPORT-8295: если AlfaPay так и не вернется к QR, то можно будет выпилить кастомизацию
const QRLogin: FC<Props> = ({ className }) => {
    const [QRCodeRequest] = useQRCodeRequestMutation();
    const isQREnabled = useAppSelector(selectQrIsEnabled);
    const QRCode = useAppSelector(selectQrCode);
    const QRConnectionStatus = useAppSelector(selectQrWebSocketConnectionStatus);
    const QRIntervalRequest = useAppSelector(selectQrIntervalRequest);
    const APayQRCode = useAppSelector(selectCustomDesignAuthorizationUrl);

    const connectQRService = useCallback(() => {
        if (isQREnabled) {
            if (QRConnectionStatus === QRStatus.FirstConnection) {
                QRCodeRequest();
                setInterval(() => {
                    if (QRConnectionStatus === QRStatus.NotConnected) {
                        QRCodeRequest();
                    }
                }, QRIntervalRequest);
            }
        }
    }, [isQREnabled, QRCodeRequest, QRConnectionStatus, QRIntervalRequest]);

    useEffect(() => {
        if (isQREnabled) {
            let body: HTMLBodyElement;

            connectQRService();

            if (document && QRConnectionStatus === QRStatus.Timeout) {
                [body] = document.getElementsByTagName('body');
                const listener = () => {
                    QRCodeRequest();

                    setInterval(() => {
                        if (QRConnectionStatus === QRStatus.NotConnected) {
                            QRCodeRequest();
                        }
                    }, QRIntervalRequest);
                    body.removeEventListener('mousemove', listener);
                };

                body.addEventListener('mousemove', listener);
            }
        }
    }, [isQREnabled, QRIntervalRequest, QRCodeRequest, QRConnectionStatus, connectQRService]);

    const code = APayQRCode || QRCode;

    if (isQREnabled && code) {
        return (
            <div
                data-test-id='qr-container'
                className={`${cn()} ${className || ''} ${APayQRCode && cn('apay')}`}
            >
                <div className={cn('image')}>
                    <QR code={code} isPayDesign={Boolean(APayQRCode)} />
                </div>
                {QRText(APayQRCode)}
            </div>
        );
    }

    return null;
};

export default QRLogin;
