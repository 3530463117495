import React, { useEffect } from 'react';

import AKey from '#/src/mobile/components/akey';
import { useAppSelector } from '#/src/shared/hooks';
import { trackUserEvent } from '#/src/shared/lib/analitycs';
import getCookie from '#/src/shared/lib/get-cookie';
import { CookiesName } from '#/src/shared/models';
import { useAkeyAuthorizationMutation } from '#/src/shared/store/api/akey-api';
import { getQueryRedirectParams } from '#/src/shared/store/redux/app/selectors';

const AKeyAuth: React.FC = () => {
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const [akeyAuthorization] = useAkeyAuthorizationMutation();

    useEffect(() => {
        trackUserEvent('AKey Page', 'Impression', 'Viewing Page', queryRedirectParams.client_id);

        if (queryRedirectParams.pc_session_id) {
            const browserId = getCookie(CookiesName.browserId);

            trackUserEvent(
                'AKey Page',
                'Impression',
                'AKey authorization',
                queryRedirectParams.client_id,
            );
            akeyAuthorization({
                client_id: queryRedirectParams.client_id,
                scope: queryRedirectParams.scope,
                pc_session_id: queryRedirectParams.pc_session_id,
                browser_id: browserId,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return queryRedirectParams.pc_session_id ? null : <AKey />;
};

export default AKeyAuth;
