import { createSlice } from '@reduxjs/toolkit';

import validationDictionary from '#/src/shared/lib/validation-dictionary';
import { getServerError, validators } from '#/src/shared/lib/validators';
import { FormStatus, RegistrationType } from '#/src/shared/models';
import { RegistrationInitialState } from '#/src/shared/store/redux/registration/constants';

export const registrationSlice = createSlice({
    name: 'registration',
    initialState: RegistrationInitialState,
    reducers: {
        registrationFormUpdated(state, action) {
            return { ...state, ...action.payload };
        },
        registrationTypeChanged(state, action) {
            state.type = action.payload;
        },
        registrationTypeUpdated(state, action) {
            state.registrationType = action.payload;
        },
        registrationSubmit(state, action) {
            const formError = validators.validateRegistration.validateForm(action.payload);

            if (formError) {
                state.formStatus = FormStatus.SubmitError;
                state.formError = formError;
            } else {
                state.formStatus = FormStatus.ValidationSuccess;
                state.formError = {};
            }
        },
        phoneRegistrationSubmit(state, action) {
            const formError = validators.validateRegistration.validatePhoneForm(action.payload);

            if (formError) {
                state.formStatus = FormStatus.SubmitError;
                state.formError = formError;
            } else {
                state.formStatus = FormStatus.ValidationSuccess;
                state.formError = {};
            }
        },
        fullnameFormSubmit(state, action) {
            const formError = validators.validateRegistration.validateFullNameForm(action.payload);

            if (formError) {
                state.formStatus = FormStatus.SubmitError;
                state.formError = formError;
            } else {
                state.formStatus = FormStatus.ValidationSuccess;
                state.formError = {};
            }
        },
        registrationRequested(state) {
            state.formStatus = FormStatus.SubmitProcess;
        },
        registrationRequestResolved(state) {
            state.formStatus = FormStatus.SubmitSuccess;
            state.formError = {};
            state.serverErrors = [];
        },
        registrationRequestRejected(state, action) {
            return {
                ...state,
                formStatus: FormStatus.SubmitError,
                serverErrors:
                    action.payload && action.payload.length
                        ? action.payload.map((error: any) => getServerError(error))
                        : [],
            };
        },
        registrationErrorUpdated(state, action) {
            state.formError = {
                ...state.formError,
                ...action.payload,
            };
        },
        serverErrorNotificationClosed(state, action) {
            if (
                state.serverErrors &&
                action.payload !== undefined &&
                state.serverErrors[action.payload]
            ) {
                const newServerErrors = state.serverErrors.slice(0);

                newServerErrors[action.payload].isOpen = false;

                state.serverErrors = newServerErrors;
            }
        },
        registrationFormReset(state) {
            const clearedForm = {
                type: RegistrationType.Card,
                account: '',
                card: '',
                phone: '',
                isPhoneRequired: true,
                newLogin: '',
                newPassword: '',
                repeatedNewPassword: '',
                formError: {},
                formStatus: '',
            };

            return {
                ...state,
                ...clearedForm,
            };
        },
        passwordUpdateRejected(state, action) {
            state.error_update = action.payload;
        },
        errorUpdateMessageDeleted(state) {
            state.error_update = null;
        },
        alternativeLoginSet(state, action) {
            state.newLogin = action.payload;
        },
        alternativeLoginIsFree(state, action) {
            if (Object.keys(action.payload).length !== 0) {
                state.formError.newLogin = validationDictionary.UNIQUE_LOGIN_MESSAGE;
            }
        },
        scanCardRejected(state, action) {
            state.formStatus = FormStatus.SubmitError;
            state.serverErrors = [getServerError(action.payload)];
        },
        scanCardAction(state) {
            return state;
        },
        scanCardResolve(state, action) {
            state.card = action.payload.card;
            state.type = action.payload.type;
        },
        registrationServerErrorsCleared(state) {
            state.serverErrors = [];
        },
    },
});

export const {
    registrationFormUpdated,
    registrationTypeChanged,
    registrationTypeUpdated,
    registrationSubmit,
    phoneRegistrationSubmit,
    fullnameFormSubmit,
    registrationRequested,
    registrationRequestResolved,
    registrationRequestRejected,
    registrationErrorUpdated,
    serverErrorNotificationClosed,
    registrationFormReset,
    passwordUpdateRejected,
    errorUpdateMessageDeleted,
    alternativeLoginSet,
    alternativeLoginIsFree,
    scanCardAction,
    scanCardRejected,
    scanCardResolve,
    registrationServerErrorsCleared,
} = registrationSlice.actions;

export default registrationSlice.reducer;
