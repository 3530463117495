import { createSelector } from 'reselect';

import {
    ACCOUNT_INPUT_MAX_LENGTH,
    CARD_INPUT_MAX_LENGTH,
} from '#/src/shared/lib/form-controls-const';
import { FormStatus, RegistrationType } from '#/src/shared/models';
import { ApplicationState } from '#/src/shared/store/types';

export const getRegistrationState = (state: ApplicationState) => state?.Registration;

export const getRegistrationCard = createSelector(
    [getRegistrationState],
    (registration) => registration.card,
);
export const getRegistrationAccount = createSelector(
    [getRegistrationState],
    (registration) => registration.account,
);
export const getRegistrationBirthDate = createSelector(
    [getRegistrationState],
    (registration) => registration.birthDate,
);
export const getRegistrationFormError = createSelector(
    [getRegistrationState],
    (registration) => registration.formError,
);
export const getRegistrationFormStatus = createSelector(
    [getRegistrationState],
    (registration) => registration.formStatus,
);
export const getRegistrationFullName = createSelector(
    [getRegistrationState],
    (registration) => registration.fullName,
);
export const getRegistrationPhone = createSelector(
    [getRegistrationState],
    (registration) => registration.phone,
);
export const getRegistrationType = createSelector(
    [getRegistrationState],
    (registration) => registration?.type,
);
export const getRegistrationRegType = createSelector(
    [getRegistrationState],
    (registration) => registration.registrationType,
);
export const getRegistrationServerErrors = createSelector(
    [getRegistrationState],
    (registration) => registration.serverErrors,
);
export const selectRegistrationErrorUpdate = createSelector(
    [getRegistrationState],
    (registration) => registration.error_update,
);
export const selectRegistrationNewLogin = createSelector(
    [getRegistrationState],
    (registration) => registration.newLogin,
);
export const selectRegistrationNewPassword = createSelector(
    [getRegistrationState],
    (registration) => registration.newPassword,
);
export const selectRegistrationRepeatedNewPassword = createSelector(
    [getRegistrationState],
    (registration) => registration.repeatedNewPassword,
);

export const selectIsSubmitButtonDisabled = createSelector(
    [
        getRegistrationCard,
        getRegistrationAccount,
        getRegistrationFormError,
        getRegistrationFormStatus,
        getRegistrationType,
        getRegistrationServerErrors,
    ],
    (card, account, formError, formStatus, type, serverErrors) => {
        let isDisabled = false;

        if (type === RegistrationType.Card) {
            isDisabled =
                card.length < CARD_INPUT_MAX_LENGTH ||
                !!formError.card ||
                serverErrors.length !== 0 ||
                formStatus === FormStatus.SubmitProcess;
        } else if (type === RegistrationType.Account) {
            isDisabled =
                account.length < ACCOUNT_INPUT_MAX_LENGTH ||
                !!formError.account ||
                serverErrors.length !== 0 ||
                formStatus === FormStatus.SubmitProcess;
        }

        return isDisabled;
    },
);

export const phoneAuthErrorMessage = createSelector(
    [getRegistrationServerErrors, getRegistrationFormError],
    (serverErrors, formError) =>
        Array.isArray(serverErrors) && serverErrors.length
            ? serverErrors[0].message
            : formError.phone,
);
